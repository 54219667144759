import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { AppState } from '../../../rootReducer';
import { CircularProgress } from '@mui/material';

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  const userToken = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  React.useEffect(() => {
    if (userToken?.length > 0) {
      setIsAuthenticated(true);
      setLoading(false);
    } else {
      setIsAuthenticated(false);
      setLoading(false);
      if (restOfProps?.location?.pathname) {
        localStorage.setItem('redirect', restOfProps.location.pathname)
      }
    }
  }, [userToken]);

  if (loading) {
    return (
      <CircularProgress size={14} style={{ color: '#FFFFFF', marginLeft: 5 }} />
    );
  }

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoute;
