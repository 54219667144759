import {
  STATUS,
  BookingsAndRefundsTableProps,
} from './BookingsAndRefundsTable';

const BOOKING_AND_REFUNDS: BookingsAndRefundsTableProps = {
  data: [
    {
      id: '#3329C1',
      orderedBy: 'Jushawn McDowell',
      booked: 'Iruka Akuchi (Gingr)',
      dateAndTime: '11 May 2021  11:15',
      status: STATUS.SUCCESS,
      orderedByImg: 'https://i.pravatar.cc/150?img=3',
      bookedByImg: 'https://i.pravatar.cc/150?img=4',
      amount: {
        ggc: '11,000',
        current: '120',
      },
    },
    {
      id: '#3329C1',
      orderedBy: 'Jushawn McDowell',
      booked: 'Iruka Akuchi (Gingr)',
      dateAndTime: '11 May 2021  11:15',
      status: STATUS.CANCELED,
      orderedByImg: 'https://i.pravatar.cc/150?img=5',
      bookedByImg: 'https://i.pravatar.cc/150?img=6',
      amount: {
        ggc: '11,000',
        current: '120',
      },
    },
    {
      id: '#3329C1',
      orderedBy: 'Jushawn McDowell',
      booked: 'Iruka Akuchi (Gingr)',
      dateAndTime: '11 May 2021  11:15',
      status: STATUS.PENDING,
      orderedByImg: 'https://i.pravatar.cc/150?img=7',
      bookedByImg: 'https://i.pravatar.cc/150?img=8',
      amount: {
        ggc: '11,000',
        current: '120',
      },
    },
    {
      id: '#3329C1',
      orderedBy: 'Jushawn McDowell',
      booked: 'Iruka Akuchi (Gingr)',
      dateAndTime: '11 May 2021  11:15',
      status: STATUS.PENDING,
      orderedByImg: 'https://i.pravatar.cc/150?img=9',
      bookedByImg: 'https://i.pravatar.cc/150?img=10',
      amount: {
        ggc: '11,000',
        current: '120',
      },
    },
    {
      id: '#3329C1',
      orderedBy: 'Jushawn McDowell',
      booked: 'Iruka Akuchi (Gingr)',
      dateAndTime: '11 May 2021  11:15',
      status: STATUS.CANCELED,
      orderedByImg: 'https://i.pravatar.cc/150?img=3',
      bookedByImg: 'https://i.pravatar.cc/150?img=4',
      amount: {
        ggc: '11,000',
        current: '120',
      },
    },
    {
      id: '#3329C1',
      orderedBy: 'Jushawn McDowell',
      booked: 'Iruka Akuchi (Gingr)',
      dateAndTime: '11 May 2021  11:15',
      status: STATUS.SUCCESS,
      orderedByImg: 'https://i.pravatar.cc/150?img=5',
      bookedByImg: 'https://i.pravatar.cc/150?img=6',
      amount: {
        ggc: '11,000',
        current: '120',
      },
    },
    {
      id: '#3329C1',
      orderedBy: 'Jushawn McDowell',
      booked: 'Iruka Akuchi (Gingr)',
      dateAndTime: '11 May 2021  11:15',
      status: STATUS.SUCCESS,
      orderedByImg: 'https://i.pravatar.cc/150?img=5',
      bookedByImg: 'https://i.pravatar.cc/150?img=6',
      amount: {
        ggc: '11,000',
        current: '120',
      },
    },
    {
      id: '#3329C1',
      orderedBy: 'Jushawn McDowell',
      booked: 'Iruka Akuchi (Gingr)',
      dateAndTime: '11 May 2021  11:15',
      status: STATUS.SUCCESS,
      orderedByImg: 'https://i.pravatar.cc/150?img=7',
      bookedByImg: 'https://i.pravatar.cc/150?img=8',
      amount: {
        ggc: '11,000',
        current: '120',
      },
    },
  ],
};

export { BOOKING_AND_REFUNDS };
