import React, { useEffect, useState } from 'react';
import { Header } from '../../../Elements/Header/Header';
import './Clients.scss';
import {
  DashboardItemStats,
  DashboardItemStatsType,
} from '../../Dashboard/DashboardItemStats/DashboardItemStats';
import SearchIcon from '@material-ui/icons/Search';
import { Avatar, TextField } from '@material-ui/core';
import CreateNew from '../../../../assets/img/icon/+ plus.svg';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '../../../../assets/img/icon/delete.svg';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralUsersState } from './clients.reducer';
import { AppState } from '../../../../rootReducer';
import { Filter, FilterType } from '../../../Elements/ClientsFilter/Filter';
import { useConfirm } from 'material-ui-confirm';
import { UserStatusUtil } from '../../../../utils/Utils';
import { GingrService } from '../Gingrs/GingrService';
import { useDebounce } from 'use-debounce';
import { PaginatedResultModel } from '../../../../shared/paginated-result.model';
import { UserModel } from '../user.model';
import TableV2, {
  TableV2Column,
} from '../../../GeneralComponents/Table/TableV2';
import { AdminUserService } from '../../../../service/AdminUserService';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },

  paginationColor: {
    '& .MuiPaginationItem-root': {
      color: '#f39fff',
      marginTop: 20,
      backgroundColorActive: 'red',
    },
    '& .MuiPagination-ul': {
      backgroundColor: 'transparent',
    },
  },
});

export const Clients: React.FC = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const usersData = useSelector((state: AppState) => state.data.clients);
  const [isUsersFilterOpened, openUsersFilter] = React.useState(false);
  const [filterConditions, setFilterConditions] = useState({
    gender: '',
    sexuality: '',
    birthYear: '',
    profileStatus: '',
  });
  const [page, setPage] = useState(1);
  const [filterQuery, setFilterQuery] = useState('');
  const [debouncedFilterQuery] = useDebounce(filterQuery, 350);

  const pageSize = 10;

  // @ts-ignore
  const [users, setUsers] = React.useState<GeneralUsersState[]>(
    usersData.users.users
  );
  // @ts-ignore
  const [filteredUsers, setFilteredUsers] =
    React.useState<GeneralUsersState[]>(users);

  React.useEffect(() => {
    if (usersData && usersData.users !== 'INCOMPLETE') {
      // @ts-ignore
      setUsers(usersData.users.users);
      // @ts-ignore
      setFilteredUsers(usersData.users.users);
    }
  }, [usersData]);
  const history = useHistory();
  const navigateToUsersDetails = (id: number) => {
    history.push(`/users/clients/${id}/personal-details`);
  };

  const [usersList, setUsersList] = useState<PaginatedResultModel<UserModel>>({
    results: [],
    resultCount: 0,
  });

  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const handleDelete = (id: number) => {
    confirm({
      title: 'Are you sure you want to confirm?',
      description: `This will permanently delete the user.`,
    })
      .then(() => {
        deleteUser(id);
        setTimeout(async () => {
          await getUsersList();
        }, 300);
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  const deleteUser = async (id: number) => {
    await AdminUserService.deleteUser(token, id).then();
  };

  const columns: TableV2Column[] = [
    {
      columnName: 'displayName',
      columnLabel: 'Display name',
      textAlign: 'left',
      dataFormat: (value: string, user: UserModel) => {
        return (
          <Link
            className="avatarContainer"
            to={`/users/clients/${user.id}/personal-details`}
          >
            <Avatar className="avatarPosition" src={user.avatarThumb} />
            <p style={{ margin: 0, padding: 0 }}>{user.displayName}</p>
          </Link>
        );
      },
    },
    {
      columnName: 'id',
      columnLabel: 'Id',
      textAlign: 'center',
    },
    {
      columnName: 'profileApproved',
      columnLabel: 'Review',
      textAlign: 'center',
      dataFormat: (value: any) => UserStatusUtil[value].name,
    },
    {
      columnName: 'gender',
      columnLabel: 'Gender',
      textAlign: 'center',
      dataFormat: (value: any) =>
        value === 0
          ? 'Female'
          : value === 1
          ? 'Male'
          : value === 2
          ? 'Trans'
          : '',
    },
    {
      columnName: 'sexuality',
      columnLabel: 'Sexuality',
      textAlign: 'center',
      dataFormat: (value: any) =>
        value === 0
          ? 'Heterosexual'
          : value === 1
          ? 'Homosexual'
          : value === 2
          ? 'Bisexual'
          : '',
    },
    {
      columnName: 'createdAt',
      columnLabel: 'Registered',
      textAlign: 'center',
    },
    {
      columnName: 'id',
      columnLabel: '',
      textAlign: 'center',
      dataFormat: (value: any) => (
        <DeleteIcon
          onClick={() => handleDelete(value)}
          className="deleteIcon"
        />
      ),
    },
  ];

  const getUsersList = async () => {
    const data = await GingrService.getUsers(token, {
      skip: (page - 1) * pageSize,
      take: pageSize,
      roles: [3, 4],
      gender: filterConditions.gender,
      sexuality: filterConditions.sexuality,
      profileStatus: filterConditions.profileStatus,
      query: debouncedFilterQuery,
      birthYear: filterConditions.birthYear,
    });
    setUsersList(data);
  };

  const filteredData = (data: any) => {
    setFilterConditions({
      gender: data.gender.id,
      sexuality: data.sexuality.id,
      profileStatus: data.profileStatus.id,
      birthYear: data.birthYear.name,
    });
  };

  const requestSearch = (searchedVal: string) => {
    // @ts-ignore
    let filteredRows = JSON.parse(JSON.stringify(users));
    filteredRows = filteredRows.filter((row) => {
      if (row.displayname === null) {
        row.displayname = '';
      }
      return row.displayname.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setFilteredUsers(filteredRows);
  };

  useEffect(() => {
    (async () => {
      await getUsersList();
    })();
  }, [page, debouncedFilterQuery, filterConditions]);

  const classes = useStyles();
  // @ts-ignore
  return (
    <div>
      <Header title={'Clients'} />
      <div className="activityLogContainer">
        <div className="statsContainer">
          <DashboardItemStats number="442" text="Bookings" />
          <DashboardItemStats
            number="444"
            text="Settings Changes"
            negativeNumber={23}
            type={DashboardItemStatsType.PERCENTAGE}
          />
          <DashboardItemStats
            number="33"
            text="Reports"
            positiveNumber={23}
            type={DashboardItemStatsType.PERCENTAGE}
          />
          <DashboardItemStats
            number="2,394"
            text="Searches"
            type={DashboardItemStatsType.PERCENTAGE}
            negativeNumber={23}
          />
          <DashboardItemStats
            number="444"
            type={DashboardItemStatsType.PERCENTAGE}
            text="Comments of reviews"
            negativeNumber={23}
          />
        </div>
        <div className="searchContainer">
          <div className="search">
            <SearchIcon className="searchIcon" />
            <TextField
              InputProps={{
                style: {
                  width: 500,
                  color: '#8e818d',
                  fontSize: 15,
                  marginLeft: 16,
                },
                classes,
              }}
              id="standard-basic"
              placeholder="Search by 'Display name'"
              autoComplete={'off'}
              onChange={(searchVal) => {
                setFilterQuery(searchVal.target.value);
                setPage(1);
              }}
            />
          </div>
          <div className="filter">
            <Filter
              type={FilterType.CLIENT}
              isOpened={isUsersFilterOpened}
              updateData={filteredData}
            />
            <p className="filterText">Filter</p>
            <CreateNew className="filterIcon" />
            <p className="filterText">Create new</p>
          </div>
        </div>

        <div className="logsContainer">
          <TableV2
            columns={columns}
            data={usersList}
            noDataFoundText="No Data Found!"
            currentPage={page}
            pageSize={pageSize}
            setActivePage={setPage}
          />
        </div>
      </div>
    </div>
  );
};
