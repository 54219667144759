import React from 'react';
import { Route } from 'react-router-dom';
import { Login } from '../../Login/Login';
import './loginLayout.scss';

export const LoginLayout: React.FC = () => {
  return (
    <div className="page page-login">
      <Route exact path={'/login'} component={Login} />
    </div>
  );
};
