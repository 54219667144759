import React from 'react';
import './ActivityItem.scss';
import { Avatar, createStyles, makeStyles, Theme } from '@material-ui/core';

interface ActivityItemProps {
  status: string;
  firstUser: string;
  secondUser: string;
  minAgo: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  })
);
export const ActivityItem: React.FC<ActivityItemProps> = ({
  status,
  firstUser,
  secondUser,
  minAgo,
}) => {
  const classes = useStyles();
  return (
    <div className="activityItemContainer">
      <div className="divider">
        <Avatar alt="Remy Sharp" src="" className={classes.small} />
        <p className="user">{firstUser}</p>
        <p className="status">{status}</p>
        <p className="user">{secondUser}</p>
      </div>
      <p className="minAgo">{minAgo} min ago</p>
    </div>
  );
};
