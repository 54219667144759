// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!../../../../assets/_typography.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controllersSettingsHeader{font-family:Asap-Regular;color:#fff}.controllersSettingsHeader .primaryText{font-size:20px;padding:0;letter-spacing:normal}.controllersSettingsHeader .subText{font-size:15px;line-height:1.43;letter-spacing:normal}", "",{"version":3,"sources":["webpack://./src/components/Pages/Settings/SettingsHeader/SettingsHeader.scss"],"names":[],"mappings":"AAA6C,2BAA2B,wBAAwB,CAAC,UAAU,CAAC,wCAAwC,cAAc,CAAC,SAAS,CAAC,qBAAqB,CAAC,oCAAoC,cAAc,CAAC,gBAAgB,CAAC,qBAAqB","sourcesContent":["@import\"../../../../assets/_typography.scss\";.controllersSettingsHeader{font-family:Asap-Regular;color:#fff}.controllersSettingsHeader .primaryText{font-size:20px;padding:0;letter-spacing:normal}.controllersSettingsHeader .subText{font-size:15px;line-height:1.43;letter-spacing:normal}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
