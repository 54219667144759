import React from 'react';
import './Dashboard.scss';

interface ChartButtonProps {
  onClick?: () => void;
  text: string;
  fullWidth?: boolean;
  isSelected?: boolean;
}

export const ChartButton: React.FC<ChartButtonProps> = ({
  text,
  isSelected,
  onClick,
}) => {
  return (
    <div className="char-button-parent-container">
      <div
        role="button"
        onKeyDown={onClick}
        onClick={onClick}
        tabIndex={0}
        className={
          isSelected
            ? 'chart-button-container-selected'
            : 'chart-button-container'
        }
      >
        <p className="chart-button-text">{text}</p>
      </div>
    </div>
  );
};
