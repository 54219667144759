import React from 'react';
import { Header } from '../../Elements/Header/Header';
import { PaymentsTable } from './PaymentTable';
import { PAYMENT } from './DummyData';

export const Payments: React.FC = () => {
  return (
    <div>
      <Header title={'Payments'} />
      <PaymentsTable data={PAYMENT.data} />
    </div>
  );
};
