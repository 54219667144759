import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Avatar } from '@material-ui/core';
import { ChartButton } from '../Dashboard/ChartButton';
import './SubscriptionTable.scss';
import { UserSubscriptionStatus } from '../Users/UsersTable';
import { SubscriptionLogButton } from './SubscriptionLogButton';
import {
  Box,
  Container,
  Divider,
  Grid,
  Modal,
  Typography,
} from '@mui/material';
import { MainButton } from '../Login/MainButton';
import { SimpleActionButton } from '../../GeneralComponents/SimpleActionButton';

export enum UserType {
  GINGRS = 'Gingrs',
  AGENCY = 'Agencies',
  CLIENT = 'Clients',
  ESTABLISHMENT = 'Establishments',
}

export enum UserSubscriptionPlan {
  ANNUALLY = 'Annually',
  MONTHLY = 'Monthly',
}

export interface SubscriptionTableProps {
  data: SubscriptionTableRow[];
}
export interface SubscriptionTableRow {
  name: string;
  img: string;
  type: UserType;
  subscribedDate: string;
  plan: UserSubscriptionPlan;
  subscription: UserSubscriptionStatus;
  amount: string;
  totalPaid: string;
  subscriptionActivity: string;
}

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#2c1229',
      color: '#8e818d',
      borderBottom: 'none',
    },
    body: {
      fontSize: 14,
      backgroundColor: '#1e041b',
      padding: 8,
      color: '#ffffff',
      borderBottom: 'none',
    },
    someModal: {
      margin: 200,
    },
  })
)(TableCell);

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 665,
  bgcolor: '#2c1229',
  paddingTop: 38,
  paddingBottom: 11,
  borderRadius: 7,
  border: '1px solid #3a1b37',
  boxShadow: 24,
  p: 4,
};

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderBottom: '1px solid #2c1229',
  },
  divider: {
    backgroundColor: '#3a1b37',
    width: '100%',
  },
});

const initStateItems: { monthly: boolean; annually: boolean } = {
  monthly: true,
  annually: false,
};

export const SubscriptionTable: React.FC<SubscriptionTableProps> = ({
  data,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedSubscription, setSelectedSubscription] =
    React.useState(initStateItems);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOnChangeSubscription = () => {
    handleOpen();
  };

  return (
    <div className="usersRowContainer">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Display name</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align="center">Subscribed</StyledTableCell>
              <StyledTableCell align="center">Plan</StyledTableCell>
              <StyledTableCell align="center">Subscription</StyledTableCell>
              <StyledTableCell align="center">Amount</StyledTableCell>
              <StyledTableCell align="center">Total Paid</StyledTableCell>
              <StyledTableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell align="left">
                  <div className="avatarContainer">
                    <Avatar className="avatarPosition" src={row.img} />
                    <p>{row.name}f</p>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <p className="text">{row.type}</p>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <p>{row.subscribedDate}</p>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <p className="text">{row.plan}</p>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <ChartButton text={row.subscription} isSelected={true} />
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <p className="text">$ {row.amount}</p>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <p className="text">$ {row.totalPaid}</p>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <SubscriptionLogButton
                    onClick={() => handleOnChangeSubscription()}
                    text="Change subscription"
                    isSelected={true}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color="#fff"
            >
              Change subscription
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              color="#8e818d"
            >
              Change subscription plan for this user by selecting the plan, it
              will notify the user via mail and notification.
            </Typography>
            <div id="subscriptionModalHeader">
              <ChartButton
                text="Monthly"
                onClick={() =>
                  setSelectedSubscription((prevState) => {
                    return {
                      monthly: true,
                      annually: false,
                    };
                  })
                }
                isSelected={selectedSubscription.monthly}
              />
              <ChartButton
                text="Annually"
                onClick={() =>
                  setSelectedSubscription((prevState) => {
                    return {
                      monthly: false,
                      annually: true,
                    };
                  })
                }
                isSelected={selectedSubscription.annually}
              />
            </div>
            <Divider className={classes.divider} />
            <div id="subscriptionModalBody">
              <div className="subscriptionCard">
                <div className="cardContent">
                  <span className="primaryText">
                    Free
                    <span className="subText">
                      Benefit of this subcription plan Benefit of this
                      subcription plan Benefit of this subcription plan Benefit
                      of this subcription plan
                    </span>
                  </span>
                </div>
                <div className="cardPrice">
                  <Divider className="divider" />
                  <span className="subscriptionPrice">15 CHF</span>
                </div>
              </div>
              <div className="subscriptionCard">
                <div className="cardContent">
                  <span className="primaryText">
                    Premium
                    <span className="subText">
                      Benefit of this subcription plan Benefit of this
                      subcription plan Benefit of this subcription plan Benefit
                      of this subcription plan
                    </span>
                  </span>
                </div>
                <div className="cardPrice">
                  <Divider className="divider" />
                  <span className="subscriptionPrice">15 CHF</span>
                </div>
              </div>
            </div>
            <Grid
              container
              mt={5}
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={4}>
                <SimpleActionButton
                  text="Cancel"
                  fullWidth={true}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item xs={5}>
                <MainButton
                  text="Save"
                  fullWidth={true}
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </TableContainer>
    </div>
  );
};
