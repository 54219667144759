import React from 'react';
import Gallery from 'react-grid-gallery';
import Checkbox from '@mui/material/Checkbox';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ArrowForwardIos } from '@material-ui/icons';
import DeleteIcon from '../../../../../assets/img/icon/delete.svg';
import {connect} from 'react-redux';
import { AppState } from '../../../../../rootReducer';
import { SimpleSelect } from '../../../../Elements/ClientsFilter/SimpleSelect/SimpleSelect';
import { APPROVAL_STATUS } from '../GingrDetails';
import {AdminUserService} from "../../../../../service/AdminUserService";

type UserVideosState = {
  images: any;
  videos: any;
  selfies: number;
  currentImage: number;
  currentVideo: number;
  selectAllChecked: boolean;
  isLightBoxOpened: boolean;
  isSelected: boolean;
  selectedImages: Array<number>;
  reviewClicked: boolean;
  selfiesToReviewState: SelfiesToReviewState;
  token: string;
  status: string;
  selectedOptionValue: {
    id: number;
    name: string;
  };
  loading: {
    defaultLoading: boolean;
    reject: boolean;
    approval: boolean;
  };
};

enum SelfiesToReviewState {
  SELFIES_TO_REVIEW,
  FINALIZE_REVIEW,
}

type UserVideosProps = {
  userId: string | number;
  callbackStatus: () => void;
  updateVideosToBeApproved: () => void;
  profileStatus: number;
  videosToReview: number;
};
const items = ['Pending to be approved', 'Approved Images'];

class UserVerification extends React.Component<
  UserVideosProps,
  UserVideosState
> {
  inProgress = false;

  constructor(props) {
    super(props);
    this.state = {
      images: [],
      videos: {
        url: '',
        type: 'video',
        altTag: 'some image',
      },
      selfies: 0,
      status: 'PENDING',
      currentVideo: 0,
      selectAllChecked: false,
      currentImage: 0,
      isSelected: true,
      selectedImages: [],
      isLightBoxOpened: false,
      reviewClicked: false,
      selfiesToReviewState: SelfiesToReviewState.SELFIES_TO_REVIEW,
      token: props.token,
      selectedOptionValue: {
        id: 0,
        name: 'Pending to be approved',
      },
      loading: {
        defaultLoading: true,
        reject: false,
        approval: false,
      },
    };
    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  async componentDidMount() {
    if (this.props.userId === 0) {
      this.setState({
        ...this.state,
        loading: {
          ...this.state.loading,
          defaultLoading: true,
        },
      });
    } else {
      await this.handleOnFilterChange(APPROVAL_STATUS.TO_BE_APPROVE);
    }
  }

  onCurrentImageChange(index) {
    this.setState({ currentImage: index });
  }

  deleteImage() {
    const images = this.state.images.slice();
    images.splice(this.state.currentImage, 1);
    this.setState({
      ...this.state,
      images: images,
    });
  }

  allImagesSelected = (images) => {
    const f = images.filter(function (img) {
      return img?.isSelected == true;
    });
    return f.length == images.length;
  };

  onSelectImage = (index) => {
    const images = this.state.images.slice();
    const img = images[index];
    if (img.hasOwnProperty('isSelected')) img.isSelected = !img.isSelected;
    else img.isSelected = true;

    this.setState({
      ...this.state,
      images: images,
    });

    if (this.allImagesSelected(images)) {
      this.setState({
        ...this.state,
        selectAllChecked: true,
      });
    } else {
      this.setState({
        ...this.state,
        selectAllChecked: false,
      });
    }
    this.getSelectedImages();
  };

  getSelectedImages = () => {
    this.setState({
      ...this.state,
      selectedImages: [],
    });
    const selected: Array<number> = [];
    for (let i = 0; i < this.state.images.length; i++)
      if (this.state.images[i].isSelected == true) {
        this.setState((prevState) => ({
          ...this.state,
          selectedImages: [
            ...prevState.selectedImages,
            this.state.images[i].id,
          ],
        }));
      }
    return selected;
  };

  onClickSelectAll = () => {
    const selectAllChecked = !this.state.selectAllChecked;
    this.setState({
      ...this.state,
      selectAllChecked: selectAllChecked,
    });

    const images = this.state.images.slice();
    if (selectAllChecked) {
      for (let i = 0; i < this.state.images.length; i++) {
        images[i].isSelected = true;
      }
      this.setState({
        ...this.state,
        images: images,
        selectedImages: this.state.images.map((i) => i.id),
        selectAllChecked: true,
      });
    } else {
      for (let i = 0; i < this.state.images.length; i++) {
        images[i].isSelected = false;
      }
      this.setState({
        ...this.state,
        images: images,
        selectedImages: [],
        selectAllChecked: false,
      });
    }
  };

  handleApprove = async () => {
    if (this.inProgress) {
      return;
    }
    this.inProgress = true
    await AdminUserService.changeVerificationStatus(
      +this.props.userId, this.state.token, true
    ).then(async () => {
      this.handleOnFilterChange(APPROVAL_STATUS.TO_BE_APPROVE).finally(() => {
        this.setState({
          ...this.state,
          loading: {
            ...this.state.loading,
            approval: false,
            reject: false,
          },
        });
      });
      this.props.callbackStatus();
      this.inProgress = false
    });
  };

  handleReject = async () => {
    if (this.inProgress) {
      return;
    }
    this.inProgress = true
    this.setState({
      ...this.state,
      loading: {
        ...this.state.loading,
        reject: true,
        approval: false,
      },
    });
    await AdminUserService.changeVerificationStatus(
        +this.props.userId, this.state.token, false
    ).then(async () => {
      this.handleOnFilterChange(this.state.selectedOptionValue.id);
      this.props.callbackStatus();
      this.setState({
        ...this.state,
        loading: {
          ...this.state.loading,
          reject: false,
          approval: false,
        },
      });
      this.inProgress = false
    });
  };

  handleOnFilterChange = async (id: number) => {
    this.setState({
      ...this.state,
      selectedOptionValue: {
        id,
        name: items[id],
      },
    });
    if (this.props.userId !== 0) {
      await AdminUserService.getVerificationStatus(
        +this.props.userId, this.state.token)
          .then((result) => {
        if (result) {
          const transformedData = {
            src: result.filename,
            thumbnail: result.filename,
            videos: result.filename,
            thumbnailWidth: 320,
            thumbnailHeight: 174,
            isSelected: false,
            status: result.status,
            id,
            approved: result.approved ? 1 : 0,
            userId: result.userId,
            url: result.filename,
            type: 'selfie',
            altTag: 'some image',
          }
          this.setState({
            ...this.state,
            images: [transformedData],
            selectAllChecked: false,
            videos: [transformedData],
            loading: {
              ...this.state.loading,
              defaultLoading: false,
            },
            status: transformedData.status,
          });
        }
      });
    }
  };

  calcPhotosToReview = () => {
    return this.state.images.filter((value) => value.approved === 0).length;
  };

  renderApproveButton = () => {
    switch (this.state.selfiesToReviewState) {
      case SelfiesToReviewState.SELFIES_TO_REVIEW:
        return (
          <div
            className="controls"
            onClick={() =>
              this.setState({
                ...this.state,
                selfiesToReviewState: SelfiesToReviewState.FINALIZE_REVIEW,
                selectedOptionValue: {
                  id: 0,
                  name: 'Pending to be approved',
                },
              })
            }
          >
            <span className="photosToReviewSpan">
              <>
                {(this.state.loading.defaultLoading && (
                  <CircularProgress
                    size={14}
                    style={{ color: '#FFFFFF', maxWidth: 90 }}
                  />
                )) ||
                  `(${
                    this.state.status === 'COMPLETE'
                      ? 0
                      : this.state.images[0].src
                      ? 1
                      : 0
                  })`}{' '}
                Selfies to review{' '}
              </>
            </span>
            <ArrowForwardIos fontSize={'small'} style={{ cursor: 'pointer' }} />
          </div>
        );
      case SelfiesToReviewState.FINALIZE_REVIEW:
        return (
          <div className="controls">
            <div
              className="backIconContainer"
              onClick={() =>
                this.setState({
                  ...this.state,
                  selfiesToReviewState: SelfiesToReviewState.SELFIES_TO_REVIEW,
                })
              }
            >
              <ArrowBackIosIcon />
              <span>Back</span>
            </div>
            {this.state.images.length > 0 && (
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      color: '#f39fff',
                    }}
                    size="small"
                    color="secondary"
                    checked={this.state.selectAllChecked}
                  />
                }
                label={
                  <Typography
                    variant="h6"
                    style={{ color: '#f39fff', fontSize: 15 }}
                  >
                    Select all
                  </Typography>
                }
                onClick={this.onClickSelectAll}
              />
            )}
          </div>
        );
      default:
    }
  };

  render() {
    return (
      <div className="userPhotoGalleryContainer">
        {this.renderApproveButton()}
        <div className="simple-select-container">
          {this.state.selfiesToReviewState ===
            SelfiesToReviewState.FINALIZE_REVIEW && (
            <SimpleSelect
              label={
                this.state.selectedOptionValue &&
                this.state.selectedOptionValue.name
                  ? this.state.selectedOptionValue.name
                  : 'Select images'
              }
              onChange={(event) => this.handleOnFilterChange(event.id)}
              options={[
                { id: 0, name: 'Pending to be approved' },
                { id: 1, name: 'Approved files' },
              ]}
            />
          )}
        </div>
        {this.state.selfiesToReviewState ===
          SelfiesToReviewState.FINALIZE_REVIEW &&
          this.state.status !== 'COMPLETE' &&
          this.state.selectedOptionValue.id === 0 && (
            <>
              {this.state.images[0].src ? (
                <Gallery
                  images={this.state.images}
                  customControls={[
                    <div>
                      <Button
                        variant="contained"
                        onClick={() => this.deleteImage()}
                        color="error"
                      >
                        Reject
                      </Button>
                      <Button
                        variant="contained"
                        style={{ marginLeft: 5 }}
                        onClick={() =>
                          this.onSelectImage(this.state.currentImage)
                        }
                        color="success"
                      >
                        {this.state.images[this.state.currentImage]?.isSelected
                          ? 'Approved'
                          : 'Approve'}
                      </Button>
                    </div>,
                  ]}
                  backdropClosesModal={true}
                  preloadNextImage
                  onSelectImage={this.onSelectImage}
                  showLightboxThumbnails={true}
                  enableLightbox={true}
                  enableImageSelection={this.state.selfiesToReviewState}
                  currentImageWillChange={this.onCurrentImageChange}
                />
              ) : (
                <></>
              )}
            </>
          )}

        {this.state.selfiesToReviewState ===
          SelfiesToReviewState.FINALIZE_REVIEW &&
        this.state.selectedOptionValue.id === 1 &&
        this.state.status === 'COMPLETE' ? (
          <>
            <Gallery
              images={this.state.images}
              backdropClosesModal={true}
              preloadNextImage
              onSelectImage={this.onSelectImage}
              showLightboxThumbnails={true}
              enableLightbox={true}
              enableImageSelection={this.state.selfiesToReviewState}
              currentImageWillChange={this.onCurrentImageChange}
            />
          </>
        ) : (
          <></>
        )}

        {this.state.selfiesToReviewState ===
          SelfiesToReviewState.FINALIZE_REVIEW && (
          <>
            {(this.state.images[0].src &&
              this.state.images.length > 0 &&
              this.state.status !== 'COMPLETE' &&
              this.state.selectedOptionValue.id === 0 && (
                <div className="userPhotoConfirmButtonsContainer">
                  <div
                    className="deleteButtonContainer"
                    onClick={this.handleReject}
                  >
                    <DeleteIcon className="deleteIcon" />
                    <p className="deleteText">Reject</p>
                    {this.state.loading.reject && (
                      <CircularProgress
                        size={14}
                        style={{ color: '#FFFFFF', marginLeft: 5 }}
                      />
                    )}
                  </div>
                  <div
                    className={
                      this.state.selectedImages.length > 0
                        ? 'saveButtonContainer'
                        : 'saveButtonContainer--disabled'
                    }
                    onClick={this.handleApprove}
                  >
                    <p>Approve</p>
                    {this.state.loading.approval && (
                      <CircularProgress
                        size={14}
                        style={{ color: '#FFFFFF', marginLeft: 5 }}
                      />
                    )}
                  </div>
                </div>
              )) || (
              <>
                {' '}
                {this.state.images.length > 0 &&
                this.state.selectedOptionValue.id === 1 ? (
                  <></>
                ) : (
                  <span className="imageNotFound">IMAGE NOT FOUND</span>
                )}{' '}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  token: state.data.user.credentials.token,
});

export default connect(mapStateToProps)(UserVerification);
