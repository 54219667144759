import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from '../Link/Link';
import './LoginHeader.scss';

interface LoginHeaderProps {
  title: string;
  goBack?: () => void;
}
export const LoginHeader: React.FC<LoginHeaderProps> = ({ title, goBack }) => {
  return (
    <div className="header-container">
      <div className="back-icon-container">
        {goBack && (
          <>
            <ArrowBackIosIcon onClick={goBack} fontSize="inherit" />
            <Link
              arialLabel={'forgot-password-link'}
              text={'Back'}
              fontSize={14}
              onClick={goBack}
            />
          </>
        )}
      </div>
      <h2 className="login-text">{title}</h2>
      <div className="empty" />
    </div>
  );
};
