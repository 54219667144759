import { Option } from '../components/Elements/ClientsFilter/SimpleSelect/SimpleSelect';

const DateFormatter = (value: string | Date) => {
  const date = value instanceof Date ? value : new Date(value);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  // @ts-ignore
  return new Intl.DateTimeFormat('en-DE', options).format(date);
};
const DateTimeFormatter = (value: string | Date) => {
  const date = value instanceof Date ? value : new Date(value);
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  // @ts-ignore
  return new Intl.DateTimeFormat('en-DE', options).format(date);
};
const UserStatusUtil = [
  {
    name: 'Not Submitted',
    buttonColor: '#8a8a86',
  },
  {
    name: 'Pending Approval',
    buttonColor: '#8a8a86',
  },
  {
    name: 'Approved',
    buttonColor: '#2e7d32',
  },
  {
    name: 'Edited',
    buttonColor: '#8a8a86',
  },
];
const capitalizeFirstLetter = (value: string) => {
  return value[0].toUpperCase() + value.slice(1);
};

const calculateAge = () => {
  const year = new Date().getFullYear();
  let initYear = year - 18;
  const ages: Option[] = [];
  for (let i = 0; i < 65; i++) {
    ages.push({
      id: i.toString(),
      name: (initYear--).toString(),
    });
  }
  return ages;
};

export {
  DateFormatter,
  DateTimeFormatter,
  UserStatusUtil,
  capitalizeFirstLetter,
  calculateAge,
};
