import React from 'react';
import './SubscriptionTable.scss';

interface ChartButtonProps {
  onClick?: () => void;
  text: string;
  fullWidth?: boolean;
  isSelected?: boolean;
}

export const SubscriptionLogButton: React.FC<ChartButtonProps> = ({
  text,
  isSelected,
  onClick,
}) => {
  return (
    <div
      role="button"
      onKeyDown={onClick}
      onClick={onClick}
      tabIndex={0}
      className={
        isSelected
          ? 'subscription-button-container-selected'
          : 'subscription-button-container'
      }
    >
      <p className="chart-button-text">{text}</p>
    </div>
  );
};
