import React from 'react';
import { Grid } from '@material-ui/core';
import './UserPersonalDetails.scss';
import { GeneralGingrProfileDetailsState } from '../../gingrProfileDetails.reducer';
import {GingrProfileDetails} from "../../gingrProfileDetails";

interface GingrPersonalDetailsProps {
  data?: GingrProfileDetails | any;
}

export const UserPersonalDetails = ({ data }: GingrPersonalDetailsProps) => {
  return (
    <div className="userPersonalDetailsContainer">
      <div className="description">
        <p>{data.aboutMe}</p>
        <span>Description</span>
      </div>
      <div className="personalDetailsContent">
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <p>
              {data.gender === 0
                ? 'Female'
                : data.gender === 1
                ? 'Male'
                : data.gender === 2
                ? 'Trans'
                : ''}
            </p>
            <span>Gender</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {data.sexuality === 0
                ? 'Heterosexual'
                : data.sexuality === 1
                ? 'Homosexual'
                : data.sexuality === 2
                ? 'Bisexual'
                : ''}
            </p>
            <span>Sexuality</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.birthYear}</p>
            <span>Birth Year</span>
          </Grid>

          <Grid item xs={4}>
            <p>
              {data.location?.address || ''}
            </p>
            <span>Location</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {data &&
                data.languages &&
                data.languages.map((language, index) => {
                  if (index < data.languages.length - 1) {
                    return language.name + ', ';
                  } else {
                    return language.name;
                  }
                })}
            </p>
            <span>Languages</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.bookingNotes}</p>
            <span>Booking notes</span>
          </Grid>

          <Grid item xs={4}>
            <p>{data.occupation}</p>
            <span>Occupation</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.preferences?.food}</p>
            <span>Food</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.preferences?.interests}</p>
            <span>Interests</span>
          </Grid>

          <Grid item xs={4}>
            <p>{data.preferences?.drinks}</p>
            <span>Drinks</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.preferences?.smoke === 0 ? 'No' : data.preferences?.smoke === 1 ? 'Yes' : ''}</p>
            <span>Do you smoke?</span>
          </Grid>
          <Grid item xs={4}>
            <p>{data.preferences?.alcohol === 0 ? 'No' : data.preferences?.alcohol === 1 ? 'Yes' : ''}</p>
            <span>Do you drink?</span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
