import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import './Filter.scss';
import FilterIcon from '../../../assets/img/icon/filters.svg';
import XIcon from '../../../assets/img/icon/x.svg';
import { Box, Grid } from '@mui/material';
import { SimpleSelect } from './SimpleSelect/SimpleSelect';
import { SimpleActionButton } from '../../GeneralComponents/SimpleActionButton';
import { MainButton } from '../../Pages/Login/MainButton';
import { calculateAge } from '../../../utils/Utils';

export enum FilterType {
  CLIENT = 'client',
  GINGR = 'gingr',
  ESTABLISHMENT = 'establishment',
  AGENCIES = 'agencies',
}

interface Filter {
  type: FilterType;
  openDrawer?: () => void;
  isOpened: boolean;
  updateData?: (data: any) => void;
}

export const Filter: React.FC<Filter> = ({ isOpened, type, updateData }) => {
  const [state, setState] = React.useState<boolean>(isOpened);
  const [filter, setFilter] = React.useState<{
    gender: string;
    proUser: string;
    sexuality: string;
    birthYear: string;
    onlyOnlineUsers: boolean;
    bookingsNumber: string;
    profileStatus: string;
    verificationStatus: string;
    country: string;
    type: string;
    connectedGingrs: string;
  }>({
    gender: '',
    proUser: '',
    onlyOnlineUsers: false,
    sexuality: '',
    birthYear: '',
    bookingsNumber: '',
    profileStatus: '',
    verificationStatus: '',
    country: '',
    type: '',
    connectedGingrs: '',
  });
  const genders = [
    { id: '0', name: 'Female' },
    { id: '1', name: 'Male' },
    { id: '2', name: 'Transsexual' },
  ];

  const connectedGingrs = [
    { id: '0', name: 'Yes' },
    { id: '1', name: 'No' },
  ];

  const establishmentAgenciesType = [
    { id: 'company', name: 'Company' },
    { id: 'private', name: 'Private Person' },
  ];

  const plans = [
    { id: '0', name: 'Free only' },
    { id: '1', name: 'Paid' },
  ];

  const sexuality = [
    { id: '0', name: 'Heterosexual' },
    { id: '1', name: 'Homosexual' },
    { id: '2', name: 'Bisexual' },
  ];

  const countryList = [
    { id: 'AF', name: 'Afghanistan' },
    { id: 'AX', name: '\u00c5land Islands' },
    { id: 'AL', name: 'Albania' },
    { id: 'DZ', name: 'Algeria' },
    { id: 'AS', name: 'American Samoa' },
    { id: 'AD', name: 'Andorra' },
    { id: 'AO', name: 'Angola' },
    { id: 'AI', name: 'Anguilla' },
    { id: 'AQ', name: 'Antarctica' },
    { id: 'AG', name: 'Antigua and Barbuda' },
    { id: 'AR', name: 'Argentina' },
    { id: 'AM', name: 'Armenia' },
    { id: 'AW', name: 'Aruba' },
    { id: 'AU', name: 'Australia' },
    { id: 'AT', name: 'Austria' },
    { id: 'AZ', name: 'Azerbaijan' },
    { id: 'BS', name: 'Bahamas' },
    { id: 'BH', name: 'Bahrain' },
    { id: 'BD', name: 'Bangladesh' },
    { id: 'BB', name: 'Barbados' },
    { id: 'BY', name: 'Belarus' },
    { id: 'BE', name: 'Belgium' },
    { id: 'BZ', name: 'Belize' },
    { id: 'BJ', name: 'Benin' },
    { id: 'BM', name: 'Bermuda' },
    { id: 'BT', name: 'Bhutan' },
    { id: 'BO', name: 'Bolivia, Plurinational State of' },
    { id: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
    { id: 'BA', name: 'Bosnia and Herzegovina' },
    { id: 'BW', name: 'Botswana' },
    { id: 'BV', name: 'Bouvet Island' },
    { id: 'BR', name: 'Brazil' },
    { id: 'IO', name: 'British Indian Ocean Territory' },
    { id: 'BN', name: 'Brunei Darussalam' },
    { id: 'BG', name: 'Bulgaria' },
    { id: 'BF', name: 'Burkina Faso' },
    { id: 'BI', name: 'Burundi' },
    { id: 'KH', name: 'Cambodia' },
    { id: 'CM', name: 'Cameroon' },
    { id: 'CA', name: 'Canada' },
    { id: 'CV', name: 'Cape Verde' },
    { id: 'KY', name: 'Cayman Islands' },
    { id: 'CF', name: 'Central African Republic' },
    { id: 'TD', name: 'Chad' },
    { id: 'CL', name: 'Chile' },
    { id: 'CN', name: 'China' },
    { id: 'CX', name: 'Christmas Island' },
    { id: 'CC', name: 'Cocos (Keeling) Islands' },
    { id: 'CO', name: 'Colombia' },
    { id: 'KM', name: 'Comoros' },
    { id: 'CG', name: 'Congo' },
    { id: 'CD', name: 'Congo, the Democratic Republic of the' },
    { id: 'CK', name: 'Cook Islands' },
    { id: 'CR', name: 'Costa Rica' },
    { id: 'CI', name: "C\u00f4te d'Ivoire" },
    { id: 'HR', name: 'Croatia' },
    { id: 'CU', name: 'Cuba' },
    { id: 'CW', name: 'Cura\u00e7ao' },
    { id: 'CY', name: 'Cyprus' },
    { id: 'CZ', name: 'Czech Republic' },
    { id: 'DK', name: 'Denmark' },
    { id: 'DJ', name: 'Djibouti' },
    { id: 'DM', name: 'Dominica' },
    { id: 'DO', name: 'Dominican Republic' },
    { id: 'EC', name: 'Ecuador' },
    { id: 'EG', name: 'Egypt' },
    { id: 'SV', name: 'El Salvador' },
    { id: 'GQ', name: 'Equatorial Guinea' },
    { id: 'ER', name: 'Eritrea' },
    { id: 'EE', name: 'Estonia' },
    { id: 'ET', name: 'Ethiopia' },
    { id: 'FK', name: 'Falkland Islands (Malvinas)' },
    { id: 'FO', name: 'Faroe Islands' },
    { id: 'FJ', name: 'Fiji' },
    { id: 'FI', name: 'Finland' },
    { id: 'FR', name: 'France' },
    { id: 'GF', name: 'French Guiana' },
    { id: 'PF', name: 'French Polynesia' },
    { id: 'TF', name: 'French Southern Territories' },
    { id: 'GA', name: 'Gabon' },
    { id: 'GM', name: 'Gambia' },
    { id: 'GE', name: 'Georgia' },
    { id: 'DE', name: 'Germany' },
    { id: 'GH', name: 'Ghana' },
    { id: 'GI', name: 'Gibraltar' },
    { id: 'GR', name: 'Greece' },
    { id: 'GL', name: 'Greenland' },
    { id: 'GD', name: 'Grenada' },
    { id: 'GP', name: 'Guadeloupe' },
    { id: 'GU', name: 'Guam' },
    { id: 'GT', name: 'Guatemala' },
    { id: 'GG', name: 'Guernsey' },
    { id: 'GN', name: 'Guinea' },
    { id: 'GW', name: 'Guinea-Bissau' },
    { id: 'GY', name: 'Guyana' },
    { id: 'HT', name: 'Haiti' },
    { id: 'HM', name: 'Heard Island and McDonald Islands' },
    { id: 'VA', name: 'Holy See (Vatican City State)' },
    { id: 'HN', name: 'Honduras' },
    { id: 'HK', name: 'Hong Kong' },
    { id: 'HU', name: 'Hungary' },
    { id: 'IS', name: 'Iceland' },
    { id: 'IN', name: 'India' },
    { id: 'ID', name: 'Indonesia' },
    { id: 'IR', name: 'Iran, Islamic Republic of' },
    { id: 'IQ', name: 'Iraq' },
    { id: 'IE', name: 'Ireland' },
    { id: 'IM', name: 'Isle of Man' },
    { id: 'IL', name: 'Israel' },
    { id: 'IT', name: 'Italy' },
    { id: 'JM', name: 'Jamaica' },
    { id: 'JP', name: 'Japan' },
    { id: 'JE', name: 'Jersey' },
    { id: 'JO', name: 'Jordan' },
    { id: 'KZ', name: 'Kazakhstan' },
    { id: 'KE', name: 'Kenya' },
    { id: 'KI', name: 'Kiribati' },
    { id: 'KP', name: "Korea, Democratic People's Republic of" },
    { id: 'KR', name: 'Korea, Republic of' },
    { id: 'KW', name: 'Kuwait' },
    { id: 'KG', name: 'Kyrgyzstan' },
    { id: 'LA', name: "Lao People's Democratic Republic" },
    { id: 'LV', name: 'Latvia' },
    { id: 'LB', name: 'Lebanon' },
    { id: 'LS', name: 'Lesotho' },
    { id: 'LR', name: 'Liberia' },
    { id: 'LY', name: 'Libya' },
    { id: 'LI', name: 'Liechtenstein' },
    { id: 'LT', name: 'Lithuania' },
    { id: 'LU', name: 'Luxembourg' },
    { id: 'MO', name: 'Macao' },
    { id: 'MK', name: 'Macedonia, the Former Yugoslav Republic of' },
    { id: 'MG', name: 'Madagascar' },
    { id: 'MW', name: 'Malawi' },
    { id: 'MY', name: 'Malaysia' },
    { id: 'MV', name: 'Maldives' },
    { id: 'ML', name: 'Mali' },
    { id: 'MT', name: 'Malta' },
    { id: 'MH', name: 'Marshall Islands' },
    { id: 'MQ', name: 'Martinique' },
    { id: 'MR', name: 'Mauritania' },
    { id: 'MU', name: 'Mauritius' },
    { id: 'YT', name: 'Mayotte' },
    { id: 'MX', name: 'Mexico' },
    { id: 'FM', name: 'Micronesia, Federated States of' },
    { id: 'MD', name: 'Moldova, Republic of' },
    { id: 'MC', name: 'Monaco' },
    { id: 'MN', name: 'Mongolia' },
    { id: 'ME', name: 'Montenegro' },
    { id: 'MS', name: 'Montserrat' },
    { id: 'MA', name: 'Morocco' },
    { id: 'MZ', name: 'Mozambique' },
    { id: 'MM', name: 'Myanmar' },
    { id: 'NA', name: 'Namibia' },
    { id: 'NR', name: 'Nauru' },
    { id: 'NP', name: 'Nepal' },
    { id: 'NL', name: 'Netherlands' },
    { id: 'NC', name: 'New Caledonia' },
    { id: 'NZ', name: 'New Zealand' },
    { id: 'NI', name: 'Nicaragua' },
    { id: 'NE', name: 'Niger' },
    { id: 'NG', name: 'Nigeria' },
    { id: 'NU', name: 'Niue' },
    { id: 'NF', name: 'Norfolk Island' },
    { id: 'MP', name: 'Northern Mariana Islands' },
    { id: 'NO', name: 'Norway' },
    { id: 'OM', name: 'Oman' },
    { id: 'PK', name: 'Pakistan' },
    { id: 'PW', name: 'Palau' },
    { id: 'PS', name: 'Palestine, State of' },
    { id: 'PA', name: 'Panama' },
    { id: 'PG', name: 'Papua New Guinea' },
    { id: 'PY', name: 'Paraguay' },
    { id: 'PE', name: 'Peru' },
    { id: 'PH', name: 'Philippines' },
    { id: 'PN', name: 'Pitcairn' },
    { id: 'PL', name: 'Poland' },
    { id: 'PT', name: 'Portugal' },
    { id: 'PR', name: 'Puerto Rico' },
    { id: 'QA', name: 'Qatar' },
    { id: 'RE', name: 'R\u00e9union' },
    { id: 'RO', name: 'Romania' },
    { id: 'RU', name: 'Russian Federation' },
    { id: 'RW', name: 'Rwanda' },
    { id: 'BL', name: 'Saint Barth\u00e9lemy' },
    { id: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
    { id: 'KN', name: 'Saint Kitts and Nevis' },
    { id: 'LC', name: 'Saint Lucia' },
    { id: 'MF', name: 'Saint Martin (French part)' },
    { id: 'PM', name: 'Saint Pierre and Miquelon' },
    { id: 'VC', name: 'Saint Vincent and the Grenadines' },
    { id: 'WS', name: 'Samoa' },
    { id: 'SM', name: 'San Marino' },
    { id: 'ST', name: 'Sao Tome and Principe' },
    { id: 'SA', name: 'Saudi Arabia' },
    { id: 'SN', name: 'Senegal' },
    { id: 'RS', name: 'Serbia' },
    { id: 'SC', name: 'Seychelles' },
    { id: 'SL', name: 'Sierra Leone' },
    { id: 'SG', name: 'Singapore' },
    { id: 'SX', name: 'Sint Maarten (Dutch part)' },
    { id: 'SK', name: 'Slovakia' },
    { id: 'SI', name: 'Slovenia' },
    { id: 'SB', name: 'Solomon Islands' },
    { id: 'SO', name: 'Somalia' },
    { id: 'ZA', name: 'South Africa' },
    { id: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { id: 'SS', name: 'South Sudan' },
    { id: 'ES', name: 'Spain' },
    { id: 'LK', name: 'Sri Lanka' },
    { id: 'SD', name: 'Sudan' },
    { id: 'SR', name: 'Suriname' },
    { id: 'SJ', name: 'Svalbard and Jan Mayen' },
    { id: 'SZ', name: 'Swaziland' },
    { id: 'SE', name: 'Sweden' },
    { id: 'CH', name: 'Switzerland' },
    { id: 'SY', name: 'Syrian Arab Republic' },
    { id: 'TW', name: 'Taiwan, Province of China' },
    { id: 'TJ', name: 'Tajikistan' },
    { id: 'TZ', name: 'Tanzania, United Republic of' },
    { id: 'TH', name: 'Thailand' },
    { id: 'TL', name: 'Timor-Leste' },
    { id: 'TG', name: 'Togo' },
    { id: 'TK', name: 'Tokelau' },
    { id: 'TO', name: 'Tonga' },
    { id: 'TT', name: 'Trinidad and Tobago' },
    { id: 'TN', name: 'Tunisia' },
    { id: 'TR', name: 'Turkey' },
    { id: 'TM', name: 'Turkmenistan' },
    { id: 'TC', name: 'Turks and Caicos Islands' },
    { id: 'TV', name: 'Tuvalu' },
    { id: 'UG', name: 'Uganda' },
    { id: 'UA', name: 'Ukraine' },
    { id: 'AE', name: 'United Arab Emirates' },
    { id: 'GB', name: 'United Kingdom' },
    { id: 'US', name: 'United States' },
    { id: 'UM', name: 'United States Minor Outlying Islands' },
    { id: 'UY', name: 'Uruguay' },
    { id: 'UZ', name: 'Uzbekistan' },
    { id: 'VU', name: 'Vanuatu' },
    { id: 'VE', name: 'Venezuela, Bolivarian Republic of' },
    { id: 'VN', name: 'Viet Nam' },
    { id: 'VG', name: 'Virgin Islands, British' },
    { id: 'VI', name: 'Virgin Islands, U.S.' },
    { id: 'WF', name: 'Wallis and Futuna' },
    { id: 'EH', name: 'Western Sahara' },
    { id: 'YE', name: 'Yemen' },
    { id: 'ZM', name: 'Zambia' },
    { id: 'ZW', name: 'Zimbabwe' },
  ];
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState(open);
      isOpened = open;
    };

  const handleSelected = (key: string, val: any) => {
    setFilter({
      ...filter,
      [key]: val,
    });
  };

  const handleClearIcon = (key: string) => {
    setFilter({
      ...filter,
      [key]: '',
    });
  };

  const handleResetFieldsClick = () => {
    setFilter({
      gender: '',
      proUser: '',
      onlyOnlineUsers: false,
      sexuality: '',
      birthYear: '',
      bookingsNumber: '',
      profileStatus: '',
      verificationStatus: '',
      country: '',
      type: '',
      connectedGingrs: '',
    });
    if (updateData) {
      updateData({
        gender: '',
        proUser: '',
        onlyOnlineUsers: false,
        sexuality: '',
        birthYear: '',
        bookingsNumber: '',
        profileStatus: '',
        verificationStatus: '',
        country: '',
        type: '',
        connectedGingrs: '',
      });
    }
  };

  return (
    <div>
      <FilterIcon className="filterIcon" onClick={toggleDrawer(true)} />
      <Drawer
        anchor="right"
        PaperProps={{ style: { height: '100%', backgroundColor: '#2c1229' } }}
        open={state}
        onClose={toggleDrawer(false)}
        className="drawerContainer"
      >
        <div className="drawer" role="presentation">
          <div className="filterHeader">
            <h2>Filters</h2>
            <XIcon
              onClick={toggleDrawer(false)}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <Box sx={{ minWidth: 450 }}>
            {type !== FilterType.CLIENT && (
              <div className="filterItem">
                <label>Country</label>
                <SimpleSelect
                  selectedOption={filter.country}
                  options={countryList}
                  label={'Select country'}
                  onChange={(value) => handleSelected('country', value)}
                  clearIcon={() => handleClearIcon('country')}
                />
              </div>
            )}

            {(type === FilterType.ESTABLISHMENT ||
              type === FilterType.AGENCIES) && (
              <div className="filterItem">
                <label>Connected Gingrs</label>
                <SimpleSelect
                  selectedOption={filter.connectedGingrs}
                  options={connectedGingrs}
                  label={'Select'}
                  onChange={(value) => handleSelected('connectedGingrs', value)}
                  clearIcon={() => handleClearIcon('connectedGingrs')}
                />
              </div>
            )}
            {(type === FilterType.CLIENT || type === FilterType.GINGR) && (
              <>
                <div className="filterItem">
                  <label>Gender</label>
                  <SimpleSelect
                    selectedOption={filter.gender}
                    options={genders}
                    label={'Select users gender'}
                    onChange={(value) => handleSelected('gender', value)}
                    clearIcon={() => handleClearIcon('gender')}
                  />
                </div>
                <div className="filterItem">
                  <label>Sexuality</label>
                  <SimpleSelect
                    selectedOption={filter.sexuality}
                    options={sexuality}
                    label={'Select users sexuality'}
                    onChange={(value) => handleSelected('sexuality', value)}
                    clearIcon={() => handleClearIcon('sexuality')}
                  />
                </div>
              </>
            )}
            <div className="filterItem">
              <label>Subscription</label>
              <SimpleSelect
                selectedOption={filter.proUser}
                options={plans}
                label={'Select subscription plan'}
                onChange={(value) => handleSelected('proUser', value)}
                clearIcon={() => handleClearIcon('proUser')}
              />
            </div>
            <div className="switchContainer">
              <label>Show only online users</label>
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={(event) =>
                    handleSelected('onlyOnlineUsers', event.target.checked)
                  }
                />
                <span className="slider round" />
              </label>
            </div>
          </Box>
          <Grid
            container
            spacing={3}
            style={{ justifyContent: 'center', width: 500 }}
          >
            <Grid item xs={6}>
              {((type === FilterType.ESTABLISHMENT ||
                type === FilterType.AGENCIES) && (
                <div className="filterItem">
                  <label>Type</label>
                  <SimpleSelect
                    selectedOption={filter.type}
                    options={establishmentAgenciesType}
                    label={'Select type'}
                    onChange={(value) => handleSelected('type', value)}
                    clearIcon={() => handleClearIcon('type')}
                  />
                </div>
              )) || (
                <div className="filterItem">
                  <label>Birth Year</label>
                  <SimpleSelect
                    selectedOption={filter.birthYear}
                    options={calculateAge()}
                    label={'Select age'}
                    onChange={(value) => handleSelected('birthYear', value)}
                    clearIcon={() => handleClearIcon('birthYear')}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <div className="filterItem">
                <label>Nº Bookings</label>
                <SimpleSelect
                  selectedOption={filter.bookingsNumber}
                  options={[
                    {
                      id: '1',
                      name: '1-5',
                    },
                    {
                      id: '2',
                      name: '5-10',
                    },
                    { id: '3', name: '10-20' },
                    { id: '3', name: '20-30' },
                    { id: '3', name: '30-40' },
                    { id: '3', name: '40-50' },
                    { id: '3', name: '50+' },
                  ]}
                  label={'Select bookings number'}
                  onChange={(value) => handleSelected('bookingsNumber', value)}
                  clearIcon={() => handleClearIcon('bookingsNumber')}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="filterItem">
                <label>Profile Status</label>
                <SimpleSelect
                  selectedOption={filter.profileStatus}
                  options={[
                    {
                      id: '0',
                      name: 'Not Submitted',
                    },
                    {
                      id: '1',
                      name: 'Pending Approval',
                    },
                    {
                      id: '2',
                      name: 'Approved',
                    },
                  ]}
                  label={'Select profile status'}
                  onChange={(value) => handleSelected('profileStatus', value)}
                  clearIcon={() => handleClearIcon('profileStatus')}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="filterItem">
                <label>Verification Status</label>
                <SimpleSelect
                  selectedOption={filter.verificationStatus}
                  options={[
                    {
                      id: '1',
                      name: 'Verified',
                    },
                    {
                      id: '2',
                      name: 'Not Verified',
                    },
                  ]}
                  label={'Select verification status'}
                  onChange={(value) =>
                    handleSelected('verificationStatus', value)
                  }
                  clearIcon={() => handleClearIcon('verificationStatus')}
                />
              </div>
            </Grid>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: 13,
              }}
            >
              <SimpleActionButton
                text="Clear filters"
                fullWidth={true}
                onClick={handleResetFieldsClick}
              />
              <MainButton
                text="Save"
                fullWidth={true}
                onClick={() =>
                  updateData ? updateData(filter) : console.log(filter)
                }
              />
            </div>
          </Grid>
          <Divider />
        </div>
      </Drawer>
    </div>
  );
};
