// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YVnJw\\+LgP\\+TnsRf1gbb-Wg\\=\\={background-color:rgba(0,0,0,0);border:none;color:#8a7c88;cursor:pointer;text-align:center;font-family:\"Asap-Regular\";font-weight:bold;padding:0}.YVnJw\\+LgP\\+TnsRf1gbb-Wg\\=\\=:hover{color:#f39fff}.YVnJw\\+LgP\\+TnsRf1gbb-Wg\\=\\=:hover.QVqOqdM6brA7IxhVm\\+\\+gWQ\\=\\=,.YVnJw\\+LgP\\+TnsRf1gbb-Wg\\=\\=.QVqOqdM6brA7IxhVm\\+\\+gWQ\\=\\={color:#cbcdcf}", "",{"version":3,"sources":["webpack://./src/components/Pages/Login/Link/Link.module.scss"],"names":[],"mappings":"AAAA,8BAAM,8BAA8B,CAAC,WAAW,CAAC,aAAa,CAAC,cAAc,CAAC,iBAAiB,CAAC,0BAA0B,CAAC,gBAAgB,CAAC,SAAS,CAAC,oCAAY,aAAa,CAAC,4HAAoC,aAAa","sourcesContent":[".root{background-color:rgba(0,0,0,0);border:none;color:#8a7c88;cursor:pointer;text-align:center;font-family:\"Asap-Regular\";font-weight:bold;padding:0}.root:hover{color:#f39fff}.root:hover.disabled,.root.disabled{color:#cbcdcf}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "YVnJw+LgP+TnsRf1gbb-Wg==",
	"disabled": "QVqOqdM6brA7IxhVm++gWQ=="
};
export default ___CSS_LOADER_EXPORT___;
