import { UserSubscriptionStatus } from '../Users/UsersTable';
import {
  SubscriptionTableProps,
  UserSubscriptionPlan,
  UserType,
} from './SubscriptionTable';

const SUBSCRIPTIONS: SubscriptionTableProps = {
  data: [
    {
      name: 'Jayce Greene',
      img: 'https://i.pravatar.cc/150?img=6',
      type: UserType.AGENCY,
      plan: UserSubscriptionPlan.ANNUALLY,
      subscription: UserSubscriptionStatus.FREE,
      subscribedDate: '22 May 2021',
      totalPaid: '120',
      amount: '100',
      subscriptionActivity: 'Change subscription',
    },
    {
      name: 'Elianah Ye',
      img: 'https://i.pravatar.cc/150?img=5',
      type: UserType.AGENCY,
      plan: UserSubscriptionPlan.ANNUALLY,
      subscription: UserSubscriptionStatus.FREE,
      subscribedDate: '22 May 2021',
      totalPaid: '120',
      amount: '100',
      subscriptionActivity: 'Change subscription',
    },
    {
      name: 'JRaeya Pablo',
      img: 'https://i.pravatar.cc/150?img=7',
      type: UserType.AGENCY,
      plan: UserSubscriptionPlan.ANNUALLY,
      subscription: UserSubscriptionStatus.FREE,
      subscribedDate: '22 May 2021',
      totalPaid: '120',
      amount: '100',
      subscriptionActivity: 'Change subscription',
    },
    {
      name: 'Camryn Pennington',
      img: 'https://i.pravatar.cc/150?img=8',
      type: UserType.AGENCY,
      plan: UserSubscriptionPlan.MONTHLY,
      subscription: UserSubscriptionStatus.PREMIUM,
      subscribedDate: '22 May 2021',
      totalPaid: '120',
      amount: '100',
      subscriptionActivity: 'Change subscription',
    },
    {
      name: 'Caydence Fay',
      img: 'https://i.pravatar.cc/150?img=9',
      type: UserType.AGENCY,
      plan: UserSubscriptionPlan.ANNUALLY,
      subscription: UserSubscriptionStatus.FREE,
      subscribedDate: '22 May 2021',
      totalPaid: '120',
      amount: '100',
      subscriptionActivity: 'Change subscription',
    },
    {
      name: 'JUche Ogbonna',
      img: 'https://i.pravatar.cc/150?img=10',
      type: UserType.AGENCY,
      plan: UserSubscriptionPlan.MONTHLY,
      subscription: UserSubscriptionStatus.FREE,
      subscribedDate: '22 May 2021',
      totalPaid: '120',
      amount: '100',
      subscriptionActivity: 'Change subscription',
    },
    {
      name: 'Geovanni Ahrens',
      img: 'https://i.pravatar.cc/150?img=11',
      type: UserType.AGENCY,
      plan: UserSubscriptionPlan.ANNUALLY,
      subscription: UserSubscriptionStatus.FREE,
      subscribedDate: '22 May 2021',
      totalPaid: '120',
      amount: '100',
      subscriptionActivity: 'Change subscription',
    },
    {
      name: 'Jayce Greene',
      img: 'https://i.pravatar.cc/150?img=6',
      type: UserType.AGENCY,
      plan: UserSubscriptionPlan.ANNUALLY,
      subscription: UserSubscriptionStatus.FREE,
      subscribedDate: '22 May 2021',
      totalPaid: '120',
      amount: '100',
      subscriptionActivity: 'Change subscription',
    },
    {
      name: 'Elianah Ye',
      img: 'https://i.pravatar.cc/150?img=5',
      type: UserType.AGENCY,
      plan: UserSubscriptionPlan.ANNUALLY,
      subscription: UserSubscriptionStatus.FREE,
      subscribedDate: '22 May 2021',
      totalPaid: '120',
      amount: '100',
      subscriptionActivity: 'Change subscription',
    },
    {
      name: 'JRaeya Pablo',
      img: 'https://i.pravatar.cc/150?img=7',
      type: UserType.AGENCY,
      plan: UserSubscriptionPlan.ANNUALLY,
      subscription: UserSubscriptionStatus.FREE,
      subscribedDate: '22 May 2021',
      totalPaid: '120',
      amount: '100',
      subscriptionActivity: 'Change subscription',
    },
    {
      name: 'Camryn Pennington',
      img: 'https://i.pravatar.cc/150?img=8',
      type: UserType.AGENCY,
      plan: UserSubscriptionPlan.MONTHLY,
      subscription: UserSubscriptionStatus.PREMIUM,
      subscribedDate: '22 May 2021',
      totalPaid: '120',
      amount: '100',
      subscriptionActivity: 'Change subscription',
    },
  ],
};

export { SUBSCRIPTIONS };
