import { DashboardStatistics } from './dashboard.reducer';
import { BaseService } from '../../../service/BaseService';
import { GingrClient3 } from '../../../clients/GingrClient3';

export class DashboardService {
  static async getStatistics(token): Promise<DashboardStatistics> {
    return await GingrClient3.get(
      `admin/statistics`,
      BaseService.getBaseHeaders(token)
    );
  }
}
