import React from 'react';
import { Header } from '../../Elements/Header/Header';
import {
  DashboardItemStats,
  DashboardItemStatsType,
} from '../Dashboard/DashboardItemStats/DashboardItemStats';
import SearchIcon from '@material-ui/icons/Search';
import { TextField } from '@material-ui/core';
import FilterIcon from '../../../assets/img/icon/filters.svg';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import { SubscriptionTable } from './SubscriptionTable';
import { SUBSCRIPTIONS } from './DummyData';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

export const Subscriptions: React.FC = () => {
  const classes = useStyles();

  return (
    <div>
      <Header title={'Subscriptions'} />
      <div className="activityLogContainer">
        <div className="statsContainer">
          <DashboardItemStats number="442" text="Bookings" />
          <DashboardItemStats
            number="1,942"
            text="Total Subscriptions"
            positiveNumber={23}
            type={DashboardItemStatsType.PERCENTAGE}
          />
          <DashboardItemStats
            number="33"
            text="Free Subscriptions"
            positiveNumber={23}
            type={DashboardItemStatsType.PERCENTAGE}
          />
          <DashboardItemStats
            number="2,394"
            text="Premium Subscriptions"
            type={DashboardItemStatsType.PERCENTAGE}
            negativeNumber={23}
          />
        </div>
        <div className="searchContainer">
          <div className="search">
            <SearchIcon className="searchIcon" />
            <TextField
              InputProps={{
                style: {
                  width: 500,
                  color: '#8e818d',
                  fontSize: 15,
                  marginLeft: 16,
                },
                classes,
              }}
              id="standard-basic"
              placeholder="Search users by display name"
            />
          </div>
          <div className="filter">
            <FilterIcon className="filterIcon" />
            <p className="filterText">Filter</p>
          </div>
        </div>
        <div className="logsContainer">
          <SubscriptionTable data={SUBSCRIPTIONS.data} />
        </div>
        <div className="paginationContainer">
          <Pagination count={5} shape="rounded" />
        </div>
      </div>
    </div>
  );
};
