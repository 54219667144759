// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../../assets/_typography.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reportsItemContainer{display:flex;flex-direction:row;justify-content:space-between;font-family:\"Asap-Regular\";color:#8e818d}.reportsItemContainer .avatarContainer{display:flex;flex-direction:row;justify-content:flex-start;align-items:center}.reportsItemContainer .avatarContainer .avatarPosition{margin-right:calc(4px * 3)}.reportsItemContainer .statusContainer{display:flex;flex-direction:row;text-align:left}.reportsItemContainer .eyeIcon{display:inline-flex;justify-content:center;align-self:center;margin-right:calc(4px * 3);width:20px;height:20px}.reportsItemContainer .eyeIcon *{fill:#fff}", "",{"version":3,"sources":["webpack://./src/components/Pages/Reports/ReportsTable.scss"],"names":[],"mappings":"AAA0C,sBAAsB,YAAY,CAAC,kBAAkB,CAAC,6BAA6B,CAAC,0BAA0B,CAAC,aAAa,CAAC,uCAAuC,YAAY,CAAC,kBAAkB,CAAC,0BAA0B,CAAC,kBAAkB,CAAC,uDAAuD,0BAA0B,CAAC,uCAAuC,YAAY,CAAC,kBAAkB,CAAC,eAAe,CAAC,+BAA+B,mBAAmB,CAAC,sBAAsB,CAAC,iBAAiB,CAAC,0BAA0B,CAAC,UAAU,CAAC,WAAW,CAAC,iCAAiC,SAAS","sourcesContent":["@import\"../../../assets/_typography.scss\";.reportsItemContainer{display:flex;flex-direction:row;justify-content:space-between;font-family:\"Asap-Regular\";color:#8e818d}.reportsItemContainer .avatarContainer{display:flex;flex-direction:row;justify-content:flex-start;align-items:center}.reportsItemContainer .avatarContainer .avatarPosition{margin-right:calc(4px * 3)}.reportsItemContainer .statusContainer{display:flex;flex-direction:row;text-align:left}.reportsItemContainer .eyeIcon{display:inline-flex;justify-content:center;align-self:center;margin-right:calc(4px * 3);width:20px;height:20px}.reportsItemContainer .eyeIcon *{fill:#fff}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
