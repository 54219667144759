import { TableColumn } from './Table';
import { TableV2Column } from './TableV2';

export type OrderType = 'asc' | 'desc' | 'none';

export interface OrderBy {
  order: OrderType;
  orderBy: string;
}

export const getTextAlign = (column: TableColumn | TableV2Column): string => {
  let textAlignClass = '-align-left';
  switch (column.textAlign) {
    case 'right':
      textAlignClass = '-align-right';
      break;
    case 'center':
      textAlignClass = '-align-center';
      break;
  }

  return textAlignClass;
};

export const getHeaderAlign = (column: TableColumn | TableV2Column): string => {
  let textAlignClass = '-justify-left';
  switch (column.textAlign) {
    case 'right':
      textAlignClass = '-justify-right';
      break;
    case 'center':
      textAlignClass = '-justify-center';
      break;
  }

  return textAlignClass;
};

export const isNumber = (value: unknown): boolean => {
  return typeof value == 'number' && !isNaN(value);
};

export const isBoolean = (value: unknown): boolean => {
  return value === true || value === false;
};

export const isNil = (value: unknown): boolean => {
  return value === undefined || value === null;
};

export const isDateString = (value: string): boolean => {
  const matchedValue = value.match(/^\d{2}.\d{2}.\d{4}$/);

  if (matchedValue && matchedValue.length > 0) {
    return true;
  }

  return false;
};

export const convertDateString = (value: string): string => {
  return value.substr(6, 4) + value.substr(3, 2) + value.substr(0, 2);
};

const convertType = (value: any): string => {
  if (isNumber(value)) {
    return value.toString();
  } else if (isBoolean(value)) {
    return value ? '1' : '-1';
  } else if (isDateString(value)) {
    return convertDateString(value);
  }

  return value;
};

export const sortRows = (rows: [], sort: OrderBy): any[] => {
  if (!Array.isArray(rows)) {
    return [];
  } else {
    const retVal = [...rows] as any[];
    if (sort && sort.order !== 'none') {
      retVal.sort((a, b) => {
        const { order, orderBy } = sort;
        let first = a[orderBy];
        let second = b[orderBy];

        if (isNil(a[orderBy])) {
          first = 1;
        }
        if (isNil(b[orderBy])) {
          second = -1;
        }

        const aLocale = convertType(first);
        const bLocale = convertType(second);

        if (order === 'asc') {
          return aLocale.localeCompare(bLocale, 'en', {
            numeric: isNumber(second),
          });
        } else {
          return bLocale.localeCompare(aLocale, 'en', {
            numeric: isNumber(first),
          });
        }
      });
    }

    return retVal;
  }
};

export const paginateRows = (
  sortedRows: any[],
  activePage: number,
  rowsPerPage: number
) => {
  return [...sortedRows].slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage
  );
};
