import { Action } from 'redux';
import produce from 'immer';
import {
  AddInformationAction,
  AddLiveUsersCountAction,
  AddUsersCountAction,
} from './dashboard.action';

export interface GeneralUsersCountState {
  total: number;
  client: number;
  gingr: number;
  establishment: number;
  agency: number;
  timeFrames: TimeFrames;
  increasePercentage: IncreasePercentage;
}
export interface TimeFrames {
  today: number;
  thisMonth: number;
  thisYear: number;
}
export interface IncreasePercentage {
  year: number;
  month: number;
  day: number;
}

export interface GeneralLiveUsersCountState {
  total: number;
  client: number;
  gingr: number;
  establishment: number;
  agency: number;
  pendingForApproval: number;
}

export interface DashboardStatistics {
  clientsPendingApproval: number;
  escortsPendingApproval: number;
  totalLiveClients: number;
  totalLiveEscorts: number;
  totalRegisteredClients: number;
  totalRegisteredEscorts: number;
  totalRegisteredToday: number;
  totalRegisteredThisMonth: number;
  totalRegisteredThisYear: number;
  percentageToday: number,
  percentageThisMonth: number,
  percentageThisYear: number,
}

export type UsersCountAction = 'ADD_USERS_COUNT' | 'CLEAR_USERS_COUNT';

export type UsersCountState = 'INCOMPLETE' | GeneralUsersCountState;

export type LiveUsersCountAction =
  | 'ADD_LIVE_USERS_COUNT'
  | 'CLEAR_LIVE_USERS_COUNT';

export type LiveUsersCountState = 'INCOMPLETE' | GeneralLiveUsersCountState;

export type InformationAction =
    | 'ADD_INFORMATION'
    | 'CLEAR_INFORMATION';

export type InformationCountState = 'INCOMPLETE' | DashboardStatistics;

export const dashboardReducer = (
  state: UsersCountState = 'INCOMPLETE',
  action: Action<UsersCountAction>
): UsersCountState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_USERS_COUNT':
        const addUsersCountAction = action as AddUsersCountAction;
        const {
          total,
          client,
          gingr,
          establishment,
          agency,
          timeFrames,
          increasePercentage,
        } = addUsersCountAction;
        return {
          total,
          client,
          gingr,
          establishment,
          agency,
          timeFrames,
          increasePercentage,
        };
      case 'CLEAR_USERS_COUNT':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};

export const dashboardLiveUsersReducer = (
  state: LiveUsersCountState = 'INCOMPLETE',
  action: Action<LiveUsersCountAction>
): LiveUsersCountState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_LIVE_USERS_COUNT':
        const addLiveUsersCountAction = action as AddLiveUsersCountAction;
        const {
          total,
          client,
          gingr,
          establishment,
          agency,
          pendingForApproval,
        } = addLiveUsersCountAction;
        return {
          total,
          client,
          gingr,
          establishment,
          agency,
          pendingForApproval,
        };
      case 'CLEAR_LIVE_USERS_COUNT':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};

export const informationReducer = (
    state: LiveUsersCountState = 'INCOMPLETE',
    action: Action<InformationAction>
): LiveUsersCountState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_INFORMATION':
        const statistics = action as AddInformationAction;
        const {
          clientsPendingApproval,
          escortsPendingApproval,
          totalLiveClients,
          totalLiveEscorts,
          totalRegisteredClients,
          totalRegisteredEscorts,
          totalRegisteredToday,
          totalRegisteredThisMonth,
          totalRegisteredThisYear,
          percentageToday,
          percentageThisMonth,
          percentageThisYear,
        } = statistics;
        return {
          clientsPendingApproval,
          escortsPendingApproval,
          totalLiveClients,
          totalLiveEscorts,
          totalRegisteredClients,
          totalRegisteredEscorts,
          totalRegisteredToday,
          totalRegisteredThisMonth,
          totalRegisteredThisYear,
          percentageToday,
          percentageThisMonth,
          percentageThisYear,
        };
      case 'CLEAR_INFORMATION':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};
