import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { useHistory } from 'react-router-dom';
import { ContentService } from '../../../../service/ContentService';
import { CountryModel } from '../Model/CountryModel';
import Table, { TableColumn } from '../../../GeneralComponents/Table/Table';
import { AddEditCountry} from "./AddEditCountry";

export const CountriesList: React.FC = () => {
  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );
  const history = useHistory();
  const [countries, setCountries] = React.useState<CountryModel[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const countries = await ContentService.getCountries(token);
      setCountries(countries);
    };

    fetchData().catch(console.error);
  }, []);

  const navigateToCountry = (id) => {
    history.push(`/content/countries/${id}`);
  };

  const refreshData = async () => {
    const countries = await ContentService.getCountries(token);
    setCountries(countries);
  };

  const columns: TableColumn[] = [
    {
      columnName: 'id',
      columnLabel: 'Id',
      sortable: true,
      textAlign: 'center',
      dataFormat: (value: any) => (
        <span
          className="cursor-pointer"
          onClick={() => {
            navigateToCountry(value);
          }}
        >
          {value}
        </span>
      ),
    },
    {
      columnName: 'country',
      columnLabel: 'Name',
      sortable: true,
      textAlign: 'center',
    },
    {
      columnName: 'countryCode',
      columnLabel: 'Country code',
      sortable: true,
      textAlign: 'center',
    },
    {
      columnName: 'updatedAt',
      columnLabel: 'Updated at',
      sortable: true,
      textAlign: 'center',
    },
  ];

  return (
    <div>
      <div className="content">
        <h1 className="title">Countries</h1>
        <AddEditCountry successCallback={refreshData}></AddEditCountry>
        <Table
          columns={columns}
          data={countries}
          noDataFoundText="No Data Found!"
          rowsPerPage={100}
          defaultSort={{ orderBy: 'country', order: 'asc' }}
        />
      </div>
    </div>
  );
};
