import { BaseService } from '../../../../service/BaseService';
import { APPROVAL_STATUS } from './GingrDetails';
import { AdminUserService } from '../../../../service/AdminUserService';
import { GingrClient3 } from '../../../../clients/GingrClient3';
import { UserMedia } from './GingrPhotos/user-media';

export class GingrProfileDetailsService {
  static async getGingrImages(
    credentials: {
      token: string;
    },
    userId: number | string,
    approvedStatus: APPROVAL_STATUS
  ): Promise<UserMedia[]> {
    return await GingrClient3.post<UserMedia[]>(
      `admin/media`,
      { userId, approvedStatus, images: true },
      BaseService.getBaseHeaders(credentials.token)
    );
  }
  static async getGingrVideos(
    credentials: {
      token: string;
    },
    userId: number | string,
    approvedStatus: APPROVAL_STATUS
  ): Promise<UserMedia[]> {
    return await GingrClient3.post<UserMedia[]>(
      `admin/media`,
      { userId, approvedStatus, images: false },
      BaseService.getBaseHeaders(credentials.token)
    );
  }

  static async getGingrVerification(
    credentials: {
      token: string;
    },
    id: number | string
  ): Promise<any> {
    if (id) {
      let user = await AdminUserService.getVerificationStatus(
        +id,
        credentials.token
      );
      return [user];
    }
    return null;
  }
}
