import React from 'react';
import { Button, makeStyles, withStyles } from '@material-ui/core';

type MainButtonProps = {
  text: string;
  fullWidth?: boolean;
  onClick?: () => void;
  allCaps?: boolean;
};

const ColorButton = withStyles(() => ({
  root: {
    color: '#ffffff',
    backgroundColor: 'rgba(174,174,174,0.1)',
    '&:hover': {
      backgroundColor: '#ff3a3a',
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export const SimpleActionButton: React.FC<MainButtonProps> = ({
  text,
  fullWidth,
  onClick,
  allCaps,
}) => {
  const classes = useStyles();
  return (
    <ColorButton
      style={{ textTransform: allCaps ? 'uppercase' : 'none' }}
      disableElevation
      className={classes.margin}
      fullWidth={fullWidth}
      onClick={onClick}
    >
      {text}
    </ColorButton>
  );
};
