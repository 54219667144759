// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../assets/_typography.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".userPersonalDetailsContainer{width:100%}.userPersonalDetailsContainer span{color:#8e818d}.userPersonalDetailsContainer .description{padding-left:68px;padding-right:80px;margin-bottom:40px}.userPersonalDetailsContainer .personalDetailsContent{background-color:#220720;display:flex;padding:calc(4px * 6) calc(4px * 6) calc(4px * 6) 64px}", "",{"version":3,"sources":["webpack://./src/components/Pages/Users/GingrDetails/GingrProfileDetails/UserPhysicalDetails/UserPhysicalDetails.scss"],"names":[],"mappings":"AAAmD,8BAA8B,UAAU,CAAC,mCAAmC,aAAa,CAAC,2CAA2C,iBAAiB,CAAC,kBAAkB,CAAC,kBAAkB,CAAC,sDAAsD,wBAAwB,CAAC,YAAY,CAAC,sDAAsD","sourcesContent":["@import\"../../../../../../assets/_typography.scss\";.userPersonalDetailsContainer{width:100%}.userPersonalDetailsContainer span{color:#8e818d}.userPersonalDetailsContainer .description{padding-left:68px;padding-right:80px;margin-bottom:40px}.userPersonalDetailsContainer .personalDetailsContent{background-color:#220720;display:flex;padding:calc(4px * 6) calc(4px * 6) calc(4px * 6) 64px}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
