import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Avatar } from '@material-ui/core';
import { ChartButton } from '../Dashboard/ChartButton';
import './UsersTable.scss';
import { useHistory } from 'react-router-dom';
import EditIcon from '../../../assets/img/icon/edit.svg';
import DeleteIcon from '../../../assets/img/icon/delete.svg';
import { EstablishmentsType } from './Establishments/Establishments';
export enum UserStatus {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

export enum UserReview {
  PENDING = 'Pending',
  REVIEWED = 'Reviewed',
}

export enum UserSubscriptionStatus {
  FREE = 'Free',
  PREMIUM = 'Premium',
}
export interface UsersTableProps {
  data: UsersRow[];
}
export interface UsersRow {
  id: number;
  name: string;
  review: string;
  gender: string;
  sexuality: string;
  bookings: number;
  subscription: UserSubscriptionStatus;
  establishmentType?: EstablishmentsType;
  registered: string;
  status: UserStatus;
  imgSrc?: string;
}

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#2c1229',
      color: '#8e818d',
      borderBottom: 'none',
    },
    body: {
      fontSize: 14,
      backgroundColor: '#1e041b',
      color: '#ffffff',
      padding: 8,
      borderBottom: 'none',
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const UsersTable: React.FC<UsersTableProps> = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();

  const navigateToGingrDetails = (id: number) => {
    history.push(`/users/gingrs/${id}`);
  };

  return (
    <div className="usersRowContainer">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">ID</StyledTableCell>
              <StyledTableCell align="left">Display name</StyledTableCell>
              <StyledTableCell align="center">Review</StyledTableCell>
              <StyledTableCell align="center">Gender</StyledTableCell>
              <StyledTableCell align="center">Sexuality</StyledTableCell>
              <StyledTableCell align="center">Bookings</StyledTableCell>
              <StyledTableCell align="center">Subscription</StyledTableCell>
              <StyledTableCell align="center">Registered</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center" />
              <StyledTableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <StyledTableRow
                key={row.id}
                onClick={() => navigateToGingrDetails(row.id)}
              >
                <StyledTableCell align="center">
                  <p className="text">{row.id}</p>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <div className="avatarContainer">
                    <Avatar className="avatarPosition" src={row.imgSrc} />
                    <p style={{ margin: 0, padding: 0 }}>{row.name}f</p>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <p className="text">{row.review}</p>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <p className="text">{row.gender}</p>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <p>{row.sexuality}</p>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <p className="text">{row.bookings}</p>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <ChartButton text={row.subscription} isSelected={true} />
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <p className="text">{row.registered}</p>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div className="userStatusContainer">
                    <div className="dotContainer">
                      <p
                        className={
                          row.status === UserStatus.ONLINE
                            ? 'onlineDot'
                            : 'offlineDot'
                        }
                      >
                        .
                      </p>
                    </div>
                    <p className="text ml-small">{row.status}</p>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <EditIcon className="editIcon" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <DeleteIcon className="deleteIcon" />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
