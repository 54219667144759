import { BaseService } from './BaseService';
import { GingrClient3 } from '../clients/GingrClient3';
import { SimpleUserModel } from '../components/Pages/Users/simple-user.model';
import { VerifyStatus } from '../components/Pages/Users/GingrDetails/GingrVerification/verfiy-status';
import { UserMedia } from '../components/Pages/Users/GingrDetails/GingrPhotos/user-media';
import { APPROVAL_STATUS } from '../components/Pages/Users/GingrDetails/GingrDetails';

export class AdminUserService {
  static async approveUser(id: number, token: string) {
    return await GingrClient3.post<boolean>(
      `admin/profile/approve/${id}/true`,
      {},
      BaseService.getBaseHeaders(token)
    );
  }

  static async getApproveStatus(id: number, token: string) {
    return await GingrClient3.get<boolean>(
      `admin/profile/approvable/${id}`,
      BaseService.getBaseHeaders(token)
    );
  }

  static async refuseUser(userId: number, token: string) {
    try {
      return await GingrClient3.post<boolean>(
        `admin/profile/approve/${userId}/false`,
        {},
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return null;
    }
  }

  static async getVerificationStatus(id: number, token: string) {
    return await GingrClient3.get<VerifyStatus>(
      `admin/profile/verify/${id}`,
      BaseService.getBaseHeaders(token)
    );
  }

  static async changeVerificationStatus(id: number, token: string, approve) {
    return await GingrClient3.post<boolean>(
      `admin/profile/verify/${id}/${approve}`,
      {},
      BaseService.getBaseHeaders(token)
    );
  }

  static async changeMediaStatus(
    userId: number,
    mediaIds: number[],
    token: string,
    approve = false
  ) {
    return await GingrClient3.post<boolean>(
      `admin/media/${userId}/${approve}`,
      { mediaIds },
      BaseService.getBaseHeaders(token)
    );
  }

  static async getMedias(
    userId: number,
    token: string,
    approvedStatus: APPROVAL_STATUS,
    images: boolean
  ) {
    return await GingrClient3.post<UserMedia[]>(
      `admin/media`,
      { userId, approvedStatus, images },
      BaseService.getBaseHeaders(token)
    );
  }

  static async searchUsers(
    query: string,
    token: string
  ): Promise<SimpleUserModel[]> {
    try {
      return await GingrClient3.post<SimpleUserModel[]>(
        `admin/user/search`,
        {
          query,
        },
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return [];
    }
  }

  static async deleteUser(
    token: string,
    userId: number
  ): Promise<SimpleUserModel[]> {
    try {
      return await GingrClient3.delete(
        `admin/user/${userId}`,
        {},
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return [];
    }
  }
}
