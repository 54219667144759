import { Action } from 'redux';

export type CredentialsActionType = 'SET_CREDENTIALS' | 'REMOVE_CREDENTIALS';

export interface SetCredentialsAction extends Action<CredentialsActionType> {
  token: string;
}

export const setCredentials = (token: string): SetCredentialsAction => ({
  type: 'SET_CREDENTIALS',
  token,
});

export const removeCredentials = (): Action => ({
  type: 'REMOVE_CREDENTIALS',
});
