import React from 'react';
import { Avatar } from '@material-ui/core';
import './ActivityLogTable.scss';
import EyeIcon from '../../../assets/img/icon/eye.svg';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

export interface ActivityLogTableProps {
  data: ActivityLogRow[];
}
export interface ActivityLogRow {
  name: string;
  activity?: string;
  date: string;
  commentedOnUser?: string;
  reportedOnUser?: string;
  bookedOnUser?: string;
  changedNameFrom?: Array<string>;
  searchedFor?: string;
  isVisible?: boolean;
  imageSrc?: string;
}

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#2c1229',
      color: '#8e818d',
      borderBottom: 'none',
    },
    body: {
      fontSize: 14,
      backgroundColor: '#1e041b',
      padding: 9,
      color: '#ffffff',
      borderBottom: 'none',
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const ActivityLogTable: React.FC<ActivityLogTableProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <div className="activityLogItemContainer">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Display name</StyledTableCell>
              <StyledTableCell align="left">Activity</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell align="left">
                  <div className="avatarContainer">
                    <Avatar src={row.imageSrc} />
                    <p className="first">{row.name}</p>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ color: '#8e818d' }}>
                  {row.commentedOnUser && (
                    <>
                      Commented on
                      <p className="highlighted"> {row.commentedOnUser}</p>
                    </>
                  )}
                  {row.reportedOnUser && (
                    <>
                      Reported on
                      <p className="highlighted"> {row.reportedOnUser}</p>
                    </>
                  )}
                  {row.bookedOnUser && (
                    <>
                      Booked
                      <p className="highlighted"> {row.bookedOnUser}</p>
                    </>
                  )}
                  {row.changedNameFrom && row.changedNameFrom.length > 0 && (
                    <p>
                      Changed name from {row.changedNameFrom[0]} to{' '}
                      {row.changedNameFrom[1]}
                    </p>
                  )}
                  <p>{row.activity}</p>
                </StyledTableCell>
                <StyledTableCell align="center">{row.date}</StyledTableCell>
                <StyledTableCell align="right">
                  {(row.isVisible && <EyeIcon className="eyeIcon" />) || (
                    <EyeIcon className="eyeIcon" style={{ opacity: 0.3 }} />
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
