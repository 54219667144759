import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { useFormik } from 'formik';
import { ContentService } from '../../../../service/ContentService';
import { Button } from 'primereact/button';
import {Toast} from "primereact/toast";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from './Editor';
import { SourceEditing } from "@ckeditor/ckeditor5-source-editing";
import {Bold, Italic, Underline} from '@ckeditor/ckeditor5-basic-styles';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import {Heading} from "@ckeditor/ckeditor5-heading";
import { FontBackgroundColor, FontColor, FontSize } from "@ckeditor/ckeditor5-font";
import { List } from "@ckeditor/ckeditor5-list";
import { Link } from "@ckeditor/ckeditor5-link";
import {Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload} from '@ckeditor/ckeditor5-image';
import { LinkImage } from '@ckeditor/ckeditor5-link';

interface CountryContentProps {
    countryId: number;
}

export const CountryContent: React.FC<CountryContentProps> = ({
    countryId,
 }) => {
    const toast = useRef<Toast>(null);
    const token = useSelector(
        (state: AppState) => state.data.user.credentials.token
    );
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [contentId, setContentId] = React.useState<number>(-1);
    const [editor, setEditor] = React.useState<any>(null);

    React.useEffect(() => {
        if (!countryId) {
            return
        }
        const fetchData = async () => {
            const countryContent = await ContentService.getCountryContent(token, countryId);
            await formik.setFieldValue('content', countryContent.content)
            setContentId(countryContent.id)
            setIsEdit(!!countryContent)
        };

        fetchData().catch(console.error);
    }, [countryId]);

    const show = () => {
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'The content was modified',
        });
    };

    const expand = () => setExpanded(!expanded);

    const formik = useFormik({
        initialValues: {
            content: '',
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: async (data) => {
            await saveCountryContent();
            show()
        },
    });

    const saveCountryContent = async () => {
        setLoading(true);
        const payload = {
            countryId: countryId,
            content: editor.getData(),
        };
        const result = isEdit ? await ContentService.editCountryContent(token, contentId, payload) :
            await ContentService.addCountryContent(token, payload)
        setLoading(false);
        if (result?.id) {
            setExpanded(false);
            setIsEdit(true)
            await formik.setFieldValue('content', result.content)
        }
        return !!result?.id;
    };

    return expanded ? (
        <div style={{margin: '20px 0 0 0', color: "black" }}>
            <Toast ref={toast} />
            <div>
                <Button type="button" label="Hide content" onClick={expand} />
            </div>
            <div>
                <CKEditor
                    editor={ ClassicEditor }
                    config={ {
                        plugins: [
                            Paragraph, Bold, Italic, Essentials, SourceEditing, Heading, FontBackgroundColor,
                            FontColor , Image, Link, List, FontSize, FontColor, ImageToolbar, ImageCaption, ImageStyle,
                            ImageResize, LinkImage, ImageUpload, Underline
                        ],
                        toolbar: [
                            'heading', 'bold', 'italic', 'underline', 'undo', 'redo',
                            'style', 'alignment', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'bulletedList',
                            'numberedList', 'link', 'insertImage', 'sourceEditing',
                        ],
                    } }
                    data={formik.values.content}
                    onReady={ editor => {
                        setEditor(editor)
                        console.log( 'Editor is ready to use!', editor );
                    } }
                />
            </div>

            <form onSubmit={formik.handleSubmit}>
                <div className="form">

                    <Button type="submit" label="Save" className="button" />
                </div>
            </form>
        </div>
    ) : (
        <div>
            <div>
                <Button type="button" label={isEdit ? 'Edit content' : 'Add content'} onClick={expand} />
            </div>
        </div>
    );
};
