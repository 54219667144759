import { STATUS } from '../BookingAndRefunds/BookingsAndRefundsTable';
import { PaymentTableProps } from './PaymentTable';

const PAYMENT: PaymentTableProps = {
  data: [
    {
      id: '#3329C1',
      payer: 'Jushawn McDowell',
      paymentDetail: 'Payment for subscription plan for Premium',
      dateAndTime: '11 May 2021  11:15',
      img: 'https://i.pravatar.cc/150?img=3',
      status: STATUS.SUCCESS,
      amount: '120',
    },
    {
      id: '#3329C1',
      payer: 'Jushawn McDowell',
      paymentDetail: 'Payment for subscription plan for Premium',
      dateAndTime: '11 May 2021  11:15',
      img: 'https://i.pravatar.cc/150?img=4',
      bookingFor: 'Song Bao',
      status: STATUS.CANCELED,
      amount: '120',
    },
    {
      id: '#3329C1',
      payer: 'Jushawn McDowell',
      paymentDetail: 'Payment for subscription plan for Premium',
      dateAndTime: '11 May 2021  11:15',
      img: 'https://i.pravatar.cc/150?img=5',
      status: STATUS.PENDING,
      amount: '120',
    },
    {
      id: '#3329C1',
      payer: 'Jushawn McDowell',
      paymentDetail: 'Payment for subscription plan for Premium',
      dateAndTime: '11 May 2021  11:15',
      img: 'https://i.pravatar.cc/150?img=6',
      status: STATUS.SUCCESS,
      bookingFor: 'Song Bao',

      amount: '120',
    },
    {
      id: '#3329C1',
      payer: 'Jushawn McDowell',
      paymentDetail: 'Payment for subscription plan for Premium',
      dateAndTime: '11 May 2021  11:15',
      img: 'https://i.pravatar.cc/150?img=7',
      status: STATUS.SUCCESS,
      amount: '120',
    },
    {
      id: '#3329C1',
      payer: 'Jushawn McDowell',
      paymentDetail: 'Payment for subscription plan for Premium',
      dateAndTime: '11 May 2021  11:15',
      status: STATUS.SUCCESS,
      bookingFor: 'Song Bao',
      img: 'https://i.pravatar.cc/150?img=7',
      amount: '120',
    },
    {
      id: '#3329C1',
      payer: 'Jushawn McDowell',
      paymentDetail: 'Payment for subscription plan for Premium',
      dateAndTime: '11 May 2021  11:15',
      img: 'https://i.pravatar.cc/150?img=8',
      status: STATUS.SUCCESS,
      amount: '120',
    },
    {
      id: '#3329C1',
      payer: 'Jushawn McDowell',
      paymentDetail: 'Payment for subscription plan for Premium',
      dateAndTime: '11 May 2021  11:15',
      img: 'https://i.pravatar.cc/150?img=9',
      status: STATUS.SUCCESS,
      amount: '120',
    },
  ],
};

export { PAYMENT };
