import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './BookingsAndRefundsTable.scss';
import { Avatar } from '@material-ui/core';
import SuccessIcon from '../../../assets/img/icon/done.svg';
import PendingIcon from '../../../assets/img/icon/pending.svg';
import CanceledIcon from '../../../assets/img/icon/inactive.svg';
import OptionsIcon from '../../../assets/img/icon/options.svg';

export enum STATUS {
  SUCCESS = 'Successful',
  CANCELED = 'Canceled',
  PENDING = 'Pending',
}
export interface BookingsAndRefundsTableProps {
  data: BookingAndRefundRow[];
}
export interface BookingAndRefundRow {
  id: string;
  orderedBy: string;
  booked: string;
  dateAndTime: string;
  status: string;
  orderedByImg: string;
  bookedByImg: string;
  amount: {
    ggc: string;
    current: string;
  };
}

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#2c1229',
      color: '#8e818d',
      borderBottom: 'none',
    },
    body: {
      fontSize: 14,
      backgroundColor: '#1e041b',
      padding: 8,
      color: '#ffffff',
      borderBottom: 'none',
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderBottom: '1px solid #2c1229',
  },
});

export const BookingsAndRefundsTable: React.FC<
  BookingsAndRefundsTableProps
> = ({ data }) => {
  const classes = useStyles();

  return (
    <div className="bookingItemContainer">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="center">Ordered by</StyledTableCell>
              <StyledTableCell align="center">Booked</StyledTableCell>
              <StyledTableCell align="center">Date and time</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Amount</StyledTableCell>
              <StyledTableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.id}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div className="avatarContainer">
                    <Avatar className="avatarPosition" src={row.orderedByImg} />
                    {row.orderedBy}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div className="avatarContainer">
                    <Avatar className="avatarPosition" src={row.bookedByImg} />
                    {row.booked}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.dateAndTime}
                </StyledTableCell>
                <StyledTableCell align="left" width={95}>
                  <div className="statusContainer">
                    {(row.status === STATUS.SUCCESS && (
                      <>
                        <SuccessIcon className="success" />
                        {row.status}
                      </>
                    )) ||
                      (row.status === STATUS.CANCELED && (
                        <>
                          <CanceledIcon className="canceled" />
                          {row.status}
                        </>
                      )) || (
                        <>
                          <PendingIcon className="pending" />
                          {row.status}
                        </>
                      )}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  GGC {row.amount.ggc} ${row.amount.current}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <OptionsIcon className="icon" />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
