import { Action } from 'redux';
import produce from 'immer';
import { AddGingrAction } from './gingr.action';

export interface GeneralGingrState {
  id: number;
  avatarThumb?: string;
  slug: string;
  displayname: string;
  gender: number;
  sexuality: number;
  profile_approved: number;
  proUser: boolean;
  created_at: string;
  online: boolean;
  verified: number
}

export type GingrAction = 'ADD_GINGR' | 'CLEAR_GINGR';

export type GingrState = 'INCOMPLETE' | GeneralGingrState[];

export const gingrReducer = (
  state: GingrState = 'INCOMPLETE',
  action: Action<GingrAction>
): GingrState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_GINGR':
        const addGingrAction = action as AddGingrAction;
        const { users } = addGingrAction;
        return {
          users,
        };
      case 'CLEAR_GINGR':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};
