import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Avatar } from '@material-ui/core';
import './RevenueTable.scss';
import { UserType } from '../../Subscriptions/SubscriptionTable';
import EyeIcon from '../../../../assets/img/icon/eye.svg';
import { SimpleButton } from '../SimpleButton/SimpleButton';

export interface RevenueTableProps {
  title: string;
  onClickViewAll?: () => void;
  data: RevenueUserRow[];
}
export interface RevenueUserRow {
  img?: string;
  name: string;
  userType: UserType;
  amount: string;
  isVisible: boolean;
}

const StyledTableCell = withStyles(() =>
  createStyles({
    body: {
      fontSize: 14,
      width: '80%',
      backgroundColor: '#1e041b',
      color: '#ffffff',
      padding: 1,
      borderBottom: 'none',
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const RevenueTable: React.FC<RevenueTableProps> = ({ data, title }) => {
  const classes = useStyles();

  return (
    <div className="revenueTableContainer">
      <div className="titleContainer">
        <p className="title">{title}</p>
        <SimpleButton text="View all" />
      </div>
      <div className="tableRowContainer">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableBody>
              {data.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell align="left">
                    <div className="avatarContainer">
                      <Avatar className="avatarPosition" src={row.img} />
                      <p style={{ margin: 0, padding: 0 }}>{row.name}</p>
                      <p className="text">({row.userType})</p>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <div className="eyeAndAmountContainer">
                      <p className="amount">${row.amount}</p>
                      <EyeIcon className="icon" />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
