import React, { ChangeEvent } from 'react';
import { InputBaseComponentProps, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { alpha as fade } from '@material-ui/core/styles';
import styles from './TextInput.module.scss';
import classNames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import WarningIcon from '@material-ui/icons/Warning';

export interface ErrorMessage {
  error: boolean;
  message: string;
}

type TextInputProps = {
  id?: string;
  label: string;
  value: string;
  hiddenText?: boolean;
  handleOnChange?: (event: string) => void;
  validator?: (val: string) => { error: boolean; message: string };
  error?: ErrorMessage;
  onKeyUp?: Event;
  icon?: React.ReactElement;
  initErrorState?: ErrorMessage;
  frontIcon?: React.ReactElement;
  onKeyDown?: (event: any) => void;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
};
const CssTextField = withStyles({
  root: {
    multilineColor: {
      color: 'red',
    },
    backgroundColor: fade('#ffffff', 0.1),
    borderRadius: 4,
    color: 'transparent',
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff',
      },
    },
  },
})(TextField);

export const TextInput: React.FC<TextInputProps> = ({
  id,
  label,
  validator,
  value,
  onKeyDown,
  hiddenText = false,
  frontIcon,
  handleOnChange,
  error,
  icon,
  initErrorState = {
    error: false,
    message: '',
  },
  inputComponent,
}) => {
  const [validationError, setValidationErrorState] =
    React.useState(initErrorState);

  const hasError = error ? error.error : validationError.error;
  const errorMessage = error ? error.message : validationError.message;
  const adornmentIcon = hasError ? (
    <WarningIcon aria-label={'error-icon'} />
  ) : (
    icon
  );

  const inputIcon = (icon || hasError) && (
    <InputAdornment aria-label={'input-icon'} position="end">
      {adornmentIcon}
    </InputAdornment>
  );

  const frontInputIcon = frontIcon && (
    <InputAdornment position="start">{frontIcon}</InputAdornment>
  );
  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (validator) {
      setValidationErrorState(validator(value));
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const { value } = e.target;
    if (handleOnChange) {
      handleOnChange(value);
    }
  };
  const handleKeyDown = (e): void => {
    if (e.key === 'Enter') {
      if (onKeyDown) {
        onKeyDown();
      }
    }
  };

  return (
    <div className={styles['root']}>
      <div
        className={classNames(styles['text-field-container'], {
          [`${styles['error']}`]: hasError,
        })}
      >
        <CssTextField
          InputProps={{
            style: {
              color: '#ffffff',
            },
            startAdornment: frontInputIcon,
            endAdornment: inputIcon,

            inputComponent,
          }}
          value={value}
          onBlur={onBlurHandler}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChangeHandler(event)
          }
          InputLabelProps={{
            shrink: false,
            style: {
              color: '#8a7c88',
            },
          }}
          placeholder={label}
          onKeyDown={handleKeyDown}
          fullWidth={true}
          variant="outlined"
          type={hiddenText ? 'password' : 'text'}
          id={id}
        />
      </div>
      <div className={styles['error-message-container']}>{errorMessage}</div>
    </div>
  );
};
