import React, {useEffect, useState} from 'react';
import { Header } from '../../../Elements/Header/Header';
import {
  DashboardItemStats,
  DashboardItemStatsType,
} from '../../Dashboard/DashboardItemStats/DashboardItemStats';
import SearchIcon from '@material-ui/icons/Search';
import { Avatar, TextField } from '@material-ui/core';
import CreateNew from '../../../../assets/img/icon/+ plus.svg';
import { makeStyles } from '@material-ui/core/styles';
import {Link, useHistory} from 'react-router-dom';
import Table, { TableColumn } from '../../../GeneralComponents/Table/Table';
import { ChartButton } from '../../Dashboard/ChartButton';
import EyeIcon from '../../../../assets/img/icon/eye.svg';
import DeleteIcon from '../../../../assets/img/icon/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { GeneralUsersState } from '../Clients/clients.reducer';
import { fetchGingrAction } from './gingr.action';
import { Filter, FilterType } from '../../../Elements/ClientsFilter/Filter';
import { useConfirm } from 'material-ui-confirm';
import { GingrService } from './GingrService';
import { UserStatusUtil } from '../../../../utils/Utils';
import {TransactionsFilter} from "../../Transactions/TransactionsFilter";
import {MainButton} from "../../Login/MainButton";
import TableV2, {TableV2Column} from "../../../GeneralComponents/Table/TableV2";
import {PaginatedResultModel} from "../../../../shared/paginated-result.model";
import {TransactionModel} from "../../Transactions/transaction.model";
import {UserModel} from "../user.model";
import {AdminCreditBalanceService} from "../../../../service/AdminCreditBalanceService";
import {fi} from "date-fns/locale";
import {useDebounce} from "use-debounce";
import {AdminUserService} from "../../../../service/AdminUserService";
const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },

  paginationColor: {
    '& .MuiPaginationItem-root': {
      color: '#f39fff',
      marginTop: 20,
      backgroundColorActive: 'red',
    },
    '& .MuiPagination-ul': {
      backgroundColor: 'transparent',
    },
  },
});

export const Gingrs: React.FC = () => {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const usersData = useSelector((state: AppState) => state.data.gingr);
  const [page, setPage] = useState(1);
  const [filterQuery, setFilterQuery] = useState('');

  const [isUsersFilterOpened, openUsersFilter] = React.useState(false);
  const [filterConditions, setFilterConditions] = useState({
    country: null,
    gender: null,
    sexuality: null,
    profileStatus: null,
    birthYear: null
  });
  const [debouncedFilterQuery] = useDebounce(filterQuery, 350);

  const [users, setUsers] = React.useState<GeneralUsersState[]>(
    // @ts-ignore
    usersData.users.users
  );
  // @ts-ignore
  const [filteredUsers, setFilteredUsers] =
    React.useState<GeneralUsersState[]>(users);
  React.useEffect(() => {
    if (usersData && usersData.users !== 'INCOMPLETE') {
      // @ts-ignore
      setUsers(usersData.users.users);
      // @ts-ignore
      setFilteredUsers(usersData.users.users);
    }
  }, [usersData]);
  const history = useHistory();
  const navigateToGingrDetails = (id: number) => {
    history.push(`/users/gingrs/${id}/personal-details`);
  };

  const [usersList, setUsersList] = useState<
      PaginatedResultModel<UserModel>
  >({ results: [], resultCount: 0 });

  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );
  const pageSize = 10;


  const handleDelete = (id: number) => {
    confirm({
      title: 'Are you sure you want to confirm?',
      description: `This will permanently delete the user.`,
    })
      .then(() => {
        deleteUser(id);
        setTimeout(async () => {
          await getUsersList();
        }, 300);
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  const deleteUser = async (id: number) => {
    await AdminUserService.deleteUser(
      token,id
    ).then();
  };

  const filteredData = (data: any) => {
    setFilterConditions({
      country: data.country.id,
      gender: data.gender.id,
      sexuality: data.sexuality.id,
      profileStatus: data.profileStatus.id,
      birthYear: data.birthYear.name,
    });
  };

  const columns: TableV2Column[] = [
    {
      columnName: 'displayName',
      columnLabel: 'Display name',
      textAlign: 'left',
      dataFormat: (value: string, user: UserModel) => {
        return (
            <Link
                className="avatarContainer"
                to={`/users/gingrs/${user.id}/personal-details`}
            >
              <Avatar className="avatarPosition" src={user.avatarThumb} />
              <p style={{ margin: 0, padding: 0 }}>
                {user.displayName}
              </p>
            </Link>
        );
      },
    },
    {
      columnName: 'id',
      columnLabel: 'User id',
      textAlign: 'left',
    },
    {
      columnName: 'profileApproved',
      columnLabel: 'Review',
      textAlign: 'center',
      dataFormat: (value: any) => UserStatusUtil[value].name,
    },
    {
      columnName: 'gender',
      columnLabel: 'Gender',
      textAlign: 'center',
      dataFormat: (value: any) =>
        value === 0
          ? 'Female'
          : value === 1
          ? 'Male'
          : value === 2
          ? 'Trans'
          : '',
    },
    {
      columnName: 'sexuality',
      columnLabel: 'Sexuality',
      textAlign: 'center',
      dataFormat: (value: any) =>
        value === 0
          ? 'Heterosexual'
          : value === 1
          ? 'Homosexual'
          : value === 2
          ? 'Bisexual'
          : '',
    },
    {
      columnName: 'createdAt',
      columnLabel: 'Registered',
      textAlign: 'center',
    },
    {
      columnName: 'id',
      columnLabel: '',
      textAlign: 'center',
      dataFormat: (value: any) => (
        <DeleteIcon
          onClick={() => handleDelete(value)}
          style={{ fill: '#ff3a3a' }}
        />
      ),
    },
  ];

  const getUsersList = async () => {
    const data = await GingrService.getUsers(token,
        { skip: (page - 1) * pageSize,
          take: pageSize, roles: [1,2],
          gender: filterConditions.gender,
          sexuality: filterConditions.sexuality,
          profileStatus: filterConditions.profileStatus,
          query: debouncedFilterQuery,
          birthYear: filterConditions.birthYear
        });
    setUsersList(data);
  };

  const requestSearch = (searchedVal: string) => {
    // @ts-ignore
    let filteredRows = JSON.parse(JSON.stringify(users));
    filteredRows = filteredRows.filter((row) => {
      if (row.displayname === null) {
        row.displayname = '';
      }
      return row.displayname.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setFilteredUsers(filteredRows);
  };
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      await getUsersList();
    })();
  }, [page, debouncedFilterQuery, filterConditions]);

  return (
      <div>
        <Header title={'Gingrs'}/>

        <div className="activityLogContainer">

        <div className="statsContainer">
          <DashboardItemStats number="442" text="Bookings"/>
          <DashboardItemStats
              number="444"
              text="Settings Changes"
              negativeNumber={23}
              type={DashboardItemStatsType.PERCENTAGE}
          />
          <DashboardItemStats
              number="33"
              text="Reports"
              positiveNumber={23}
              type={DashboardItemStatsType.PERCENTAGE}
          />
          <DashboardItemStats
              number="2,394"
              text="Searches"
              type={DashboardItemStatsType.PERCENTAGE}
              negativeNumber={23}
          />
          <DashboardItemStats
              number="444"
              type={DashboardItemStatsType.PERCENTAGE}
              text="Comments of reviews"
              negativeNumber={23}
          />
        </div>
        <div className="searchContainer">
          <div className="search">
            <SearchIcon className="searchIcon"/>
            <TextField
                InputProps={{
                  style: {
                    width: 500,
                    color: '#8e818d',
                    fontSize: 15,
                    marginLeft: 16,
                  },
                  classes,
                }}
                id="standard-basic"
                placeholder="Search by 'Display name'"
                autoComplete={'off'}
                onChange={(searchVal) => {
                  setFilterQuery(searchVal.target.value);
                  setPage(1);
                }}
            />
          </div>
          <div className="filter">
            <Filter
                type={FilterType.GINGR}
                isOpened={isUsersFilterOpened}
                updateData={filteredData}
            />
            <p className="filterText">Filter</p>
            <CreateNew className="filterIcon"/>
            <p className="filterText">Create new</p>
          </div>
        </div>

        <div className="logsContainer">
          <TableV2
              columns={columns}
              data={usersList}
              noDataFoundText="No Data Found!"
              currentPage={page}
              pageSize={pageSize}
              setActivePage={setPage}
          />
        </div>
        </div>
      </div>
  );
};
