import React from 'react';
import { Header } from '../../Elements/Header/Header';
import {
  DashboardItemStats,
  DashboardItemStatsType,
} from '../Dashboard/DashboardItemStats/DashboardItemStats';
import './AcitivityLog.scss';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from '../../../assets/img/icon/filters.svg';
import Pagination from '@material-ui/lab/Pagination';

import { makeStyles } from '@material-ui/core/styles';
import { ActivityLogTable } from './ActivityLogTable';
import { ACTIVITY_LOG } from './DummyData';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});
export const ActivityLog: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <Header title={'Activity logs'} />
      <div className="activityLogContainer">
        <div className="statsContainer">
          <DashboardItemStats number="442" text="Bookings" />
          <DashboardItemStats
            number="444"
            text="Settings Changes"
            negativeNumber={23}
            type={DashboardItemStatsType.PERCENTAGE}
          />
          <DashboardItemStats
            number="33"
            text="Reports"
            positiveNumber={23}
            type={DashboardItemStatsType.PERCENTAGE}
          />
          <DashboardItemStats
            number="2,394"
            text="Searches"
            type={DashboardItemStatsType.PERCENTAGE}
            negativeNumber={23}
          />
          <DashboardItemStats
            number="444"
            type={DashboardItemStatsType.PERCENTAGE}
            text="Comments of reviews"
            negativeNumber={23}
          />
        </div>
        <div className="searchContainer">
          <div className="search">
            <SearchIcon className="searchIcon" />
            <TextField
              InputProps={{
                style: {
                  color: '#8e818d',
                  fontSize: 15,
                  width: 400,
                  marginLeft: 16,
                },
                classes,
              }}
              id="standard-basic"
              placeholder="Search users or changes"
            />
          </div>
          <div className="filter">
            <FilterIcon className="filterIcon" />
            <p className="filterText">Filter</p>
          </div>
        </div>
        <div className="logsContainer">
          <ActivityLogTable data={ACTIVITY_LOG.data} />
        </div>
        <div className="paginationContainer">
          <Pagination count={5} shape="rounded" />
        </div>
      </div>
    </div>
  );
};
