// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!../../../../assets/_typography.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".listHeader{display:flex;justify-content:space-between;flex-direction:row;font-family:Asap-Regular;align-items:center;margin-top:calc(4px * 6);padding-left:calc(4px * 3);padding-right:calc(4px * 3);color:#8e818d;border-radius:10px;font-size:12px;background-color:#260e23}.listHeader .titleParagraph{margin:0;padding:0}", "",{"version":3,"sources":["webpack://./src/components/Elements/Search/DrawerUserList/DrawerUserList.scss"],"names":[],"mappings":"AAA6C,YAAY,YAAY,CAAC,6BAA6B,CAAC,kBAAkB,CAAC,wBAAwB,CAAC,kBAAkB,CAAC,wBAAwB,CAAC,0BAA0B,CAAC,2BAA2B,CAAC,aAAa,CAAC,kBAAkB,CAAC,cAAc,CAAC,wBAAwB,CAAC,4BAA4B,QAAQ,CAAC,SAAS","sourcesContent":["@import\"../../../../assets/_typography.scss\";.listHeader{display:flex;justify-content:space-between;flex-direction:row;font-family:Asap-Regular;align-items:center;margin-top:calc(4px * 6);padding-left:calc(4px * 3);padding-right:calc(4px * 3);color:#8e818d;border-radius:10px;font-size:12px;background-color:#260e23}.listHeader .titleParagraph{margin:0;padding:0}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
