import React from 'react';
import NotificationIcon from '../../../assets/img/icon/notification.svg';
import './header.scss';
import { SearchDrawer } from '../Search/SearchDrawer';
import { ProfileMenu } from './MainMenu/ProfileMenu';
import { NotificationMenu } from './NotificationMenu/NotificationMenu';
import { NotificationsDummyData } from './NotificationsDummyData';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ArrowForwardIos } from '@material-ui/icons';

interface Props {
  title?: string;
  nextTitle?: string;
  backTitle?: string;
  onClickBack?: () => void;
  onClickNext?: () => void;
}

export const Header: React.FC<Props> = ({
  title,
  backTitle,
  nextTitle,
  onClickBack,
  onClickNext,
}) => {
  const [isSearchBarOpened] = React.useState<boolean>(false);
  const [isNotificationMenuOpened, openNotificationMenu] =
    React.useState<boolean>(false);
  const [isMainMenuOpened, openMainMenu] = React.useState<boolean>(false);

  return (
    <>
      <div className="header">
        {title && (
          <div className="header-title">{title && <h1>{title}</h1>}</div>
        )}
        {onClickBack ? (
          <ArrowBackIosIcon className="back-icon" onClick={onClickBack} />
        ) : (
          ' '
        )}
        {backTitle ? (
          <div onClick={onClickBack} className="header-title">
            {backTitle && <h1>{backTitle}</h1>}
          </div>
        ) : (
          ' '
        )}
        {onClickNext ? (
          <div className="next-section">
            <div onClick={onClickNext} className="header-title">
              {nextTitle && <h1>{nextTitle}</h1>}
            </div>
            {onClickNext ? (
              <ArrowForwardIos className="back-icon" onClick={onClickNext} />
            ) : (
              ' '
            )}
          </div>
        ) : (
          ' '
        )}
        <div className="header-icons">
          <ul>
            <li>
              <SearchDrawer isOpened={isSearchBarOpened} />
            </li>
            <li>
              <NotificationIcon
                onClick={() => {
                  openNotificationMenu(!isNotificationMenuOpened);
                  if (isMainMenuOpened) {
                    openMainMenu(!isMainMenuOpened);
                  }
                }}
              />
              {isNotificationMenuOpened && (
                <NotificationMenu data={NotificationsDummyData} />
              )}
            </li>
          </ul>
        </div>
        <div
          className="header-profile"
          onClick={() => {
            openMainMenu(!isMainMenuOpened);
            if (isNotificationMenuOpened) {
              openNotificationMenu(!isNotificationMenuOpened);
            }
          }}
        >
          <span className="header-profile-icon">R</span>
          {isMainMenuOpened && <ProfileMenu />}
        </div>
      </div>
    </>
  );
};
