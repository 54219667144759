import React from 'react';
import './Login.scss';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { UsernameAndPasswordLogin } from './UsernameAndPasswordLogin/UsernameAndPasswordLogin';
import { ResetPassword } from './ResetPassword/ResetPassword';
import { useSelector } from 'react-redux';
import { AppState } from '../../../rootReducer';
import { LoginType } from './login.reducer';

export const Login: React.FC<
  Pick<RouteComponentProps<{ subPage: string }>, 'match' | 'history'>
> = () => {
  const history = useHistory();
  const [loginLoading, setLoading] = React.useState(false);
  const userToken = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );
  const sectionsLoading = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading
  );

  const loginType = useSelector(
    (state: AppState) => state.presentation.login.loginType
  );

  React.useEffect(() => {
    setLoading(sectionsLoading.includes('LOGIN_SUBMISSION'));
  }, [sectionsLoading]);

  React.useEffect(() => {
    if (userToken?.length > 0 && !loginLoading) {
      history.push(localStorage.getItem('redirect') || '/dashboard');
    }
  }, [userToken]);

  const getLoginComponent = (loginType: LoginType) => {
    switch (loginType) {
      case 'RESET_PASSWORD':
        return <ResetPassword />;
      case 'FORGOT_PASSWORD':
        return <ForgotPassword />;
      default:
        return <UsernameAndPasswordLogin />;
    }
  };

  return <div>{getLoginComponent(loginType)}</div>;
};
