import produce from 'immer';
import { Action } from 'redux';
import { GlobalErrorActionType, SetGlobalErrorAction } from './error.action';

export interface ErrorState {
  error: Error | null;
}

const initErrorState = (): ErrorState => ({
  error: null,
});

export const errorReducer = (
  state: ErrorState = initErrorState(),
  action: Action<GlobalErrorActionType>
): ErrorState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'SET_GLOBAL_ERROR':
        const error = (action as SetGlobalErrorAction).error;
        return {
          ...draftState,
          error,
        };
      case 'CLEAR_GLOBAL_ERROR':
        return {
          ...draftState,
          error: null,
        };
      default:
        return state;
    }
  });
};
