import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Badge } from '@mui/material';
interface LinkItemProps {
  isActive: boolean;
  url: string;
  sub: string;
  setRoute: () => void;
  notificationNumber?: number;
}

export const LinkItem: React.FC<LinkItemProps> = ({
  isActive,
  notificationNumber,
  url,
  sub,
  setRoute,
}) => {
  function capitalizeFirstLetter() {
    const capital = sub.charAt(0).toUpperCase() + sub.slice(1);
    return capital.replace('-', ' ');
  }
  return (
    <Link to={`${url}/${sub}`} style={{ textDecoration: 'none' }}>
      <span
        className={`sidebar-menu-item ${isActive ? 'active' : ''}`}
        onClick={setRoute}
      >
        <Badge badgeContent={notificationNumber} color="error">
          <span className="sidebar-menu-label">{capitalizeFirstLetter()}</span>
        </Badge>
      </span>
    </Link>
  );
};
