import React from 'react';
import './NotificationMenu.scss';
import { NOTIFICATION_TYPE, PAID, REPORT } from '../NotificationsDummyData';
import { Avatar } from '@material-ui/core';

interface Props {
  data: Array<PAID | REPORT>;
}

export const NotificationMenu: React.FC<Props> = ({ data }) => {
  return (
    <div className="mainContainer">
      <h4 className="primaryText">
        <span>Notifications</span>
        <span className="text-overlay">view all</span>
      </h4>
      <div className="notificationContent">
        {data.map(
          (notification: PAID | REPORT, key) =>
            (notification.type === NOTIFICATION_TYPE.REPORTED && (
              <div className="notificationItem">
                <Avatar className="avatar" src={notification.img} />
                <p className="notificationText">
                  {notification.name} reported an issue
                </p>
                <p className="time">{notification.time}</p>
              </div>
            )) || (
              <div className="notificationItem">
                <Avatar className="avatar" src={notification.img} />
                <p className="notificationText">
                  {notification.name} paid registration for{' '}
                  {notification.type === NOTIFICATION_TYPE.PAID_REGISTRATION
                    ? notification.amount
                    : null}{' '}
                  CHF
                </p>
                <span className="time">{notification.time}</span>
              </div>
            )
        )}
      </div>
    </div>
  );
};
