import React, { Props, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { ContentService } from '../../../../service/ContentService';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import './AddCity.scss';

interface Props {
  countryId: number;
  successCallback: any;
}

export const AddCity: React.FC<Props> = ({ countryId, successCallback }) => {
  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const toast = useRef(null);

  const show = () => {
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'The city is added',
    });
  };

  const expand = () => setExpanded(!expanded);

  const formik = useFormik({
    initialValues: {
      name: '',
      slug: '',
    },
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = 'City name is required.';
      }

      if (!data.slug) {
        errors.slug = 'Slug is required.';
      }

      return errors;
    },
    onSubmit: async (data) => {
      const result = await saveCity();
      if (result) {
        show();
        successCallback();
      }
      formik.resetForm();
    },
  });

  const saveCity = async () => {
    setLoading(true);
    const result = await ContentService.addCity(token, {
      city: formik.values.name,
      countryId,
      slug: formik.values.slug,
    });
    setLoading(false);
    if (result?.id) {
      setExpanded(false);
    }
    return !!result?.id;
  };

  return expanded ? (
    <div>
      <div>
        <Button type="button" label="Hide" onClick={expand} />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form">
          <Toast ref={toast} />
          <label htmlFor="name">City name</label>
          <InputText
            id="name"
            name="name"
            className="input"
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
          />

          <label htmlFor="slug">City slug</label>
          <InputText
            id="slug"
            name="slug"
            value={formik.values.slug}
            onChange={(e) => formik.setFieldValue('slug', e.target.value)}
          />
          <Button type="submit" label="Save" className="button" />
        </div>
      </form>
    </div>
  ) : (
    <div>
      <div>
        <Button type="button" label="Add city" onClick={expand} />
      </div>
    </div>
  );
};
