import { createSelector } from 'reselect';
import { AppState } from '../../rootReducer';

const getCredentialsState = (state: AppState) => state.data.user.credentials;

export const getCredentials = createSelector(
  [getCredentialsState],
  (credentials) => {
    return {
      token: credentials.token,
    };
  }
);
