import React from 'react';
import './SettingsHeader.scss';

interface SettingsHeader {
  title: string;
  description?: string;
}

export const SettingsHeader: React.FC<SettingsHeader> = ({
  title,
  description,
}) => {
  return (
    <div className="controllersSettingsHeader">
      <span className="primaryText">{title}</span>
      <p className="subText">{description}</p>
    </div>
  );
};
