import React from 'react';
import './ProfileMenu.scss';
import IconUsers from '../../../../assets/img/icon/users.svg';
import NotificationIcon from '../../../../assets/img/icon/notification.svg';
import SettingsIcon from '../../../../assets/img/icon/settings.svg';
import LogoutIcon from '../../../../assets/img/icon/logout.svg';
import { useDispatch } from 'react-redux';
import { logout } from '../../../Pages/Login/login.action';

export const ProfileMenu: React.FC = () => {
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className="profileMenuContainer">
        <div className="notificationItem">
          <IconUsers className="headerMenuIcon" />
          Profile
        </div>
        <div className="notificationItem">
          <NotificationIcon className="headerMenuIcon" />
          Notifications
        </div>
        <div className="notificationItem">
          <SettingsIcon className="headerMenuIcon" />
          Settings
        </div>

        <div className="logoutItem" onClick={logOut}>
          <LogoutIcon className="logoutIcon" style={{ fill: '#FF3A3A' }} />
          Logout
        </div>
      </div>
    </>
  );
};
