import React from 'react';
import { Button, makeStyles, withStyles } from '@material-ui/core';
import { CircularProgress } from '@mui/material';

type MainButtonProps = {
  text: string;
  fullWidth?: boolean;
  onClick?: () => void;
  allCaps?: boolean;
  loading?: boolean;
};

const ColorButton = withStyles(() => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#b914ac',
    '&:hover': {
      backgroundColor: '#b3289d',
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export const MainButton: React.FC<MainButtonProps> = ({
  text,
  allCaps,
  fullWidth,
  onClick,
  loading,
}) => {
  const classes = useStyles();
  return (
    <ColorButton
      variant="contained"
      style={{ textTransform: allCaps ? 'uppercase' : 'none' }}
      className={classes.margin}
      fullWidth={fullWidth}
      onClick={onClick}
    >
      {text}
      {loading && (
        <CircularProgress
          size={14}
          style={{ color: '#FFFFFF', marginLeft: 5 }}
        />
      )}
    </ColorButton>
  );
};
