import { REPORT_TYPE, ReportsTableProps } from './ReportsTable';

const REPORTS: ReportsTableProps = {
  data: [
    {
      id: '#3329C1',
      displayName: 'Jushawn McDowell',
      reports: 'Reported an issue',
      type: REPORT_TYPE.CONTENT,
      date: '22 May 2021',
      img: 'https://i.pravatar.cc/150?img=3',
      isVisibleReport: true,
    },
    {
      id: '#3329C1',
      displayName: 'Anne-Marije Markink',
      reports: 'Reported an issue',
      type: REPORT_TYPE.SYSTEM,
      date: '22 May 2021',
      img: 'https://i.pravatar.cc/150?img=4',
      isVisibleReport: false,
    },
    {
      id: '#3329C1',
      displayName: 'Abayo Stevens',
      reports: 'Reported an issue',
      type: REPORT_TYPE.GINGR,
      date: '22 May 2021',
      img: 'https://i.pravatar.cc/150?img=5',
      isVisibleReport: false,
    },
    {
      id: '#3329C1',
      displayName: 'JUche Ogbonna',
      reports: 'Reported an issue',
      type: REPORT_TYPE.SYSTEM,
      date: '22 May 2021',
      img: 'https://i.pravatar.cc/150?img=7',
      isVisibleReport: true,
    },
    {
      id: '#3329C1',
      displayName: 'Jushawn McDowell',
      reports: 'Reported an issue',
      type: REPORT_TYPE.CONTENT,
      date: '22 May 2021',
      img: 'https://i.pravatar.cc/150?img=8',
      isVisibleReport: false,
    },
    {
      id: '#3329C1',
      displayName: 'Geovanni Ahrens',
      reports: 'Reported an issue',
      type: REPORT_TYPE.SYSTEM,
      date: '22 May 2021',
      img: 'https://i.pravatar.cc/150?img=9',
      isVisibleReport: true,
    },
    {
      id: '#3329C1',
      displayName: 'Skyler Castaneda',
      reports: 'Reported an issue',
      type: REPORT_TYPE.GINGR,
      date: '22 May 2021',
      img: 'https://i.pravatar.cc/150?img=10',
      isVisibleReport: false,
    },
    {
      id: '#3329C1',
      displayName: 'JUche Ogbonna',
      reports: 'Reported an issue',
      type: REPORT_TYPE.CONTENT,
      date: '22 May 2021',
      img: 'https://i.pravatar.cc/150?img=13',
      isVisibleReport: true,
    },
  ],
};

export { REPORTS };
