import React from 'react';
import { Header } from '../../Elements/Header/Header';

import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from '../../../assets/img/icon/filters.svg';
import Pagination from '@material-ui/lab/Pagination';
import './Reports.scss';

import { makeStyles } from '@material-ui/core/styles';
import { ReportsTable } from './ReportsTable';
import { REPORTS } from './ReportsDummyData';
import {
  DashboardItemStats,
  DashboardItemStatsType,
} from '../Dashboard/DashboardItemStats/DashboardItemStats';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});
export const Reports: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <Header title={'Reports'} />
      <div>
        <div className="bookingAndRefundsContainer">
          <div className="statsContainer">
            <DashboardItemStats number="442" text="Bookings per day" />
            <DashboardItemStats
              number="442"
              text="Total Reports"
              type={DashboardItemStatsType.PERCENTAGE}
            />
            <DashboardItemStats
              number="33"
              text="Gingr Reports"
              positiveNumber={23}
              type={DashboardItemStatsType.PERCENTAGE}
            />
            <DashboardItemStats
              number="33"
              text="Users Reports"
              type={DashboardItemStatsType.PERCENTAGE}
              positiveNumber={23}
            />
            <DashboardItemStats
              number="44"
              type={DashboardItemStatsType.PERCENTAGE}
              text="System Reports"
              negativeNumber={23}
            />
            <DashboardItemStats
              number="142"
              type={DashboardItemStatsType.PERCENTAGE}
              text="Content Reports"
              negativeNumber={23}
            />
          </div>
          <div className="searchContainer">
            <div className="search">
              <SearchIcon className="searchIcon" />
              <TextField
                InputProps={{
                  style: {
                    color: '#8e818d',
                    fontSize: 15,
                    width: 400,
                    marginLeft: 16,
                  },
                  classes,
                }}
                id="standard-basic"
                placeholder="Search users by display name"
              />
            </div>
            <div className="filter">
              <FilterIcon className="filterIcon" />
              <p className="filterText">Filter</p>
            </div>
          </div>
          <div className="logsContainer">
            <ReportsTable data={REPORTS.data} />
          </div>
          <div className="paginationContainer">
            <Pagination count={5} shape="rounded" />
          </div>
        </div>
      </div>
    </div>
  );
};
