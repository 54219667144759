import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { useHistory, useParams } from 'react-router-dom';
import { CountryModel } from '../Model/CountryModel';
import { CityModel } from '../Model/CityModel';
import Table from '../../../GeneralComponents/Table';
import { ContentService } from '../../../../service/ContentService';
import { TableColumn } from '../../../GeneralComponents/Table/Table';
import { AddCity } from '../City/AddCity';
import {CountryContent} from "./CountryContent";
import {AddEditCountry} from "./AddEditCountry";

export const CountryDetails: React.FC = () => {
  const history = useHistory();
  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const params: any = useParams();
  const [country, setCountry] = React.useState<CountryModel>(
    {} as CountryModel
  );
  const [cities, setCities] = React.useState<CityModel[]>([]);

  React.useEffect(() => {
    if (!params.id) {
      return;
    }
    const fetchData = async () => {
      const country = await ContentService.getCountry(token, params.id);
      setCountry(country);
    };

    fetchData().catch(console.error);
  }, [params]);

  React.useEffect(() => {
    if (!params.id) {
      return;
    }
    const fetchData = async () => {
      const cities = await ContentService.getCities(token, params.id);
      setCities(cities);
    };

    fetchData().catch(console.error);
  }, [params]);

  const navigateToCity = (id) => {
    history.push(`/content/cities/${id}`);
  };

  const navigateToCountry = () => {
    history.push(`/content/countries/${country.id}`);
  };

  const refreshData = async () => {
    const cities = await ContentService.getCities(token, params.id);
    setCities(cities);
  };

  const refreshCountry = async () => {
    const country = await ContentService.getCountry(token, params.id);
    setCountry(country);
  };

  const columns: TableColumn[] = [
    {
      columnName: 'id',
      columnLabel: 'Id',
      sortable: true,
      textAlign: 'center',
      dataFormat: (value: any) => (
        <span
          className="cursor-pointer"
          onClick={() => {
            navigateToCity(value);
          }}
        >
          {value}
        </span>
      ),
    },
    {
      columnName: 'name',
      columnLabel: 'Name',
      sortable: true,
      textAlign: 'center',
    },
    {
      columnName: 'slug',
      columnLabel: 'Slug',
      sortable: true,
      textAlign: 'center',
    },
  ];

  return (
    <div>
      <div className="content">
        <h1 className="title">{country.country}</h1>
        <AddEditCountry successCallback={refreshCountry} countryId={country.id}></AddEditCountry>
        <CountryContent countryId={country.id}></CountryContent>
        <AddCity countryId={country.id} successCallback={refreshData}></AddCity>
        <Table
          columns={columns}
          data={cities}
          noDataFoundText="No Data Found!"
          rowsPerPage={100}
          defaultSort={{ orderBy: 'name', order: 'asc' }}
        />
      </div>
    </div>
  );
};
