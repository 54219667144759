import produce from 'immer';
import { Action } from 'redux';
import {
  CognitoUserActionType,
  SetCognitoUserAction,
} from './cognito-user.action';

export interface CognitoUserState {
  user: any;
}

const initCognitoUserState = (): CognitoUserState => {
  return {} as CognitoUserState;
};

export const cognitoUserReducer = (
  state: CognitoUserState = initCognitoUserState(),
  action: Action<CognitoUserActionType>
): CognitoUserState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'SET_COGNITO_USER':
        draftState.user = (action as SetCognitoUserAction)?.user;
        return draftState;
      default:
        return state;
    }
  });
};
