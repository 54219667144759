import React, { Dispatch, SetStateAction } from 'react';
import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
} from '@material-ui/icons';
import './_Pagination.scss';
interface PaginationProps {
  activePage: number;
  rowsPerPage: number;
  totalPages: number;
  setActivePage: Dispatch<SetStateAction<number>>;
}

interface PaginationButtonProps {
  children: React.ReactNode | React.ReactNode[];
  setActivePage: Dispatch<SetStateAction<number>>;
  page: number;
  disabled?: boolean;
  className?: string;
}

const pagination_icon_class = 'pagination__pagination-icon';

const Pagination = ({
  activePage,
  totalPages,
  setActivePage,
}: PaginationProps) => {
  const isFirstPageActive = activePage === 1;
  const isLastPageActive = activePage === totalPages;
  return (
    <div className="pagination" data-testid="Pagination">
      <PaginationButton
        disabled={isFirstPageActive}
        setActivePage={setActivePage}
        page={1}
        className="first"
      >
        <FirstPage className={pagination_icon_class} />
      </PaginationButton>
      <PaginationButton
        disabled={isFirstPageActive}
        setActivePage={setActivePage}
        page={activePage - 1}
      >
        <ChevronLeft className={pagination_icon_class} />
      </PaginationButton>
      {getPageButtons(totalPages, activePage, setActivePage)}
      <PaginationButton
        disabled={isLastPageActive}
        setActivePage={setActivePage}
        page={activePage + 1}
      >
        <ChevronRight className={pagination_icon_class} />
      </PaginationButton>
      <PaginationButton
        disabled={isLastPageActive}
        setActivePage={setActivePage}
        page={totalPages}
        className="last"
      >
        <LastPage className={pagination_icon_class} />
      </PaginationButton>
    </div>
  );
};

const getPageButtons = (
    totalPages: number,
    activePage: number,
    setActivePage: Dispatch<SetStateAction<number>>
) => {
    const items = [];

    for (let index = 0; index < totalPages; index++) {
        const page = index + 1;
        if (activePage - 3 < page && activePage + 3 > page) {
            items.push(
                <PaginationButton
                    className={`${activePage === page ? 'active' : ''}`}
                    key={page}
                    setActivePage={setActivePage}
                    page={page}
                >
                    {page}
                </PaginationButton>
            );
        }
    }
    if (activePage + 2 < totalPages) {

        items.push(<div className="pagination__dots">...</div>)
        items.push(<PaginationButton
            className={`${activePage === totalPages ? 'active' : ''}`}
            key={totalPages}
            setActivePage={setActivePage}
            page={totalPages}
        >
            {totalPages}
        </PaginationButton>)
    }

    return items;
};

const PaginationButton = ({
  setActivePage,
  page,
  children,
  className,
  disabled = false,
}: PaginationButtonProps) => {
  return (
    <button
      className={`pagination__pagination-button${
        className ? ` ${className}` : ''
      }`}
      disabled={disabled}
      onClick={() => setActivePage(page)}
    >
      <div className="pagination__button-content">{children}</div>
    </button>
  );
};

export default Pagination;
