import React from 'react';
import './Dashboard.scss';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface GingrChart {
  data: Array<{
    name: string;
    uv: number;
    pv: number;
    amt: number;
  }>;
}

export const GingrChart: React.FC<GingrChart> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="90%">
      <AreaChart
        width={1200}
        height={300}
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#f39fff" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          style={{
            fontSize: '10px',
            color: '#ffffff',
            fontFamily: 'Asap-Regular',
          }}
        />
        <YAxis
          style={{
            fontSize: '10px',
            color: '#ffffff',
            fontFamily: 'Asap-Regular',
          }}
        />
        <CartesianGrid horizontal={false} strokeDasharray="10 19" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="pv"
          stroke="#f39fff"
          dot={true}
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
