import {
  GoToLoginSequenceAction,
  LoginActionType,
  LoginError,
} from './login.action';
import produce from 'immer';
import { Action } from 'redux';

export type LoginType =
  | 'USERNAME_AND_PASSWORD'
  | 'FORGOT_PASSWORD'
  | 'RESET_PASSWORD'
  | 'SUCCESSFUL_LOGIN'
  | 'CONFIRM_FORGOT_PASSWORD_SUCCESS'
  | 'UNSUCCESSFUL_LOGIN'
  | 'SET_PASSWORD'
  | 'LOGOUT';

export interface LoginInitState {
  loginType: LoginType;
}

export type LoginErrorState = {
  error: true;
  errorMessage?: LoginType;
  accountLocked: boolean;
  username: string;
  password: string;
} & LoginInitState;

export type LoginState = LoginInitState | LoginErrorState;

export const loginReducer = (
  state: LoginState = { loginType: 'USERNAME_AND_PASSWORD' },
  action: Action<LoginActionType>
): LoginState => {
  return produce(state, (draftState: LoginState) => {
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        draftState = {
          ...draftState,
          loginType: 'SUCCESSFUL_LOGIN',
          error: false,
        };
        return draftState;
      case 'LOGIN_FAILURE':
        draftState = {
          ...draftState,
          loginType: 'UNSUCCESSFUL_LOGIN',
          error: true,
          accountLocked: (action as LoginError).accountLocked,
          username: (action as LoginError).username,
          password: (action as LoginError).password,
        };
        return draftState;
      case 'GO_TO_LOGIN_SEQUENCE':
        draftState.loginType = (action as GoToLoginSequenceAction).sequence;
        return draftState;
      case 'LOGOUT':
        draftState = {
          ...draftState,
          loginType: 'LOGOUT',
          error: true,
          accountLocked: (action as LoginError).accountLocked,
          username: (action as LoginError).username,
          password: (action as LoginError).password,
        };
        location.assign('/login');
        return draftState;
      default:
        return state;
    }
  });
};
