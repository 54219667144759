// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FBj9IQovjQqUg3gRAeWAgA\\=\\={display:inline-flex;flex-direction:column;height:70px;margin-bottom:calc(4px * 4)}.FBj9IQovjQqUg3gRAeWAgA\\=\\= .sTBgwrAir-0Ixsv\\+0j3fgw\\=\\={text-align:left}.FBj9IQovjQqUg3gRAeWAgA\\=\\= .XhfMndtv43kUcW53aDsd-w\\=\\={display:inline-flex;flex-direction:column;flex:.5;align-items:center}.FBj9IQovjQqUg3gRAeWAgA\\=\\= .sTBgwrAir-0Ixsv\\+0j3fgw\\=\\={color:#ff3a3a;font-size:14px;margin-top:calc(4px * 2);font-family:Asap-Regular}.FBj9IQovjQqUg3gRAeWAgA\\=\\= .wyKH-ZGbwnOtX81XRxgo9A\\=\\= path{color:#3e0037}.FBj9IQovjQqUg3gRAeWAgA\\=\\= [aria-label=error-icon] path{color:#ff3a3a}.FBj9IQovjQqUg3gRAeWAgA\\=\\= path{color:#ff3a3a}.FBj9IQovjQqUg3gRAeWAgA\\=\\= .VQbLdwq30rIpoIq\\+yApefQ\\=\\=.XhfMndtv43kUcW53aDsd-w\\=\\= label{color:#ff3a3a}.FBj9IQovjQqUg3gRAeWAgA\\=\\= .VQbLdwq30rIpoIq\\+yApefQ\\=\\=.XhfMndtv43kUcW53aDsd-w\\=\\= fieldset{border-color:#ff3a3a}", "",{"version":3,"sources":["webpack://./src/components/Pages/Login/TextInput/TextInput.module.scss"],"names":[],"mappings":"AAAA,4BAAM,mBAAmB,CAAC,qBAAqB,CAAC,WAAW,CAAC,2BAA2B,CAAC,yDAA+B,eAAe,CAAC,wDAA4B,mBAAmB,CAAC,qBAAqB,CAAC,OAAO,CAAC,kBAAkB,CAAC,yDAA+B,aAAa,CAAC,cAAc,CAAC,wBAAwB,CAAC,wBAAwB,CAAC,6DAAqB,aAAa,CAAC,yDAAmC,aAAa,CAAC,iCAAW,aAAa,CAAC,0FAAwC,aAAa,CAAC,6FAA2C,oBAAoB","sourcesContent":[".root{display:inline-flex;flex-direction:column;height:70px;margin-bottom:calc(4px * 4)}.root .error-message-container{text-align:left}.root .text-field-container{display:inline-flex;flex-direction:column;flex:.5;align-items:center}.root .error-message-container{color:#ff3a3a;font-size:14px;margin-top:calc(4px * 2);font-family:Asap-Regular}.root .tool-tip path{color:#3e0037}.root [aria-label=error-icon] path{color:#ff3a3a}.root path{color:#ff3a3a}.root .error.text-field-container label{color:#ff3a3a}.root .error.text-field-container fieldset{border-color:#ff3a3a}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "FBj9IQovjQqUg3gRAeWAgA==",
	"error-message-container": "sTBgwrAir-0Ixsv+0j3fgw==",
	"text-field-container": "XhfMndtv43kUcW53aDsd-w==",
	"tool-tip": "wyKH-ZGbwnOtX81XRxgo9A==",
	"error": "VQbLdwq30rIpoIq+yApefQ=="
};
export default ___CSS_LOADER_EXPORT___;
