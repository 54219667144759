import React from 'react';
import FilterIcon from '../../../assets/img/icon/filters.svg';
import Drawer from '@material-ui/core/Drawer';
import XIcon from '../../../assets/img/icon/x.svg';
import './TransactionsFilter.scss';
import {PaymentMethod, TransactionStatus, TransactionType} from './transaction.model';
import { Box } from '@mui/material';
import { SimpleSelect } from '../../Elements/ClientsFilter/SimpleSelect/SimpleSelect';
import { SimpleActionButton } from '../../GeneralComponents/SimpleActionButton';
import { MainButton } from '../Login/MainButton';

interface TransactionsFilter {
  openDrawer?: () => void;
  isOpened: boolean;
  filteredData?: (data: any) => void;
}

export const TransactionsFilter: React.FC<TransactionsFilter> = ({
  isOpened,
  filteredData,
}) => {
  const [state, setState] = React.useState<boolean>(isOpened);
  const [filter, setFilter] = React.useState<{
    status;
    type;
    paymentMethod;
  }>({
    status: undefined,
    type: undefined,
    paymentMethod: undefined
  });

  const transactionStatuses = [
    { id: TransactionStatus.Confirmed, name: TransactionStatus.Confirmed },
    { id: TransactionStatus.Pending, name: TransactionStatus.Pending },
    { id: TransactionStatus.Rejected, name: TransactionStatus.Rejected },
  ];
  const transactionTypes = [
    {
      id: TransactionType.CancellationFee,
      name: TransactionType.CancellationFee,
    },
    {
      id: TransactionType.CompensationRefund,
      name: TransactionType.CompensationRefund,
    },
    { id: TransactionType.Referral, name: TransactionType.Referral },
    { id: TransactionType.ServiceFee, name: TransactionType.ServiceFee },
    {
      id: TransactionType.ServiceFeeRefund,
      name: TransactionType.ServiceFeeRefund,
    },
    { id: TransactionType.Support, name: TransactionType.Support },
    { id: TransactionType.TopUp, name: TransactionType.TopUp },
    { id: TransactionType.Welcome, name: TransactionType.Welcome },
    { id: TransactionType.Payout, name: TransactionType.Payout },
    { id: TransactionType.BalanceSettlement, name: TransactionType.BalanceSettlement },
  ];
  const paymentMethods = [
    { id: PaymentMethod.BankTransfer, name: PaymentMethod.BankTransfer },
    { id: PaymentMethod.PaymentSlip, name: PaymentMethod.PaymentSlip },
    { id: PaymentMethod.SwissPost, name: PaymentMethod.SwissPost },
    { id: PaymentMethod.GingrCard, name: PaymentMethod.GingrCard },
    { id: PaymentMethod.BankAccount, name: PaymentMethod.BankAccount },
    { id: PaymentMethod.GingrOffice, name: PaymentMethod.GingrOffice },

  ];

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState(open);
      isOpened = open;
    };

  const handleSelected = (key: string, val: any) => {
    setFilter({
      ...filter,
      [key]: val,
    });
  };

  const handleClearIcon = (key: string) => {
    setFilter({
      ...filter,
      [key]: undefined,
    });
  };

  const handleResetFieldsClick = () => {
    setFilter({
      status: undefined,
      type: undefined,
      paymentMethod: undefined
    });
    if (filteredData) {
      filteredData({
        status: undefined,
        type: undefined,
        paymentMethod: undefined
      });
    }
  };

  return (
    <div>
      <FilterIcon className="filterIcon" onClick={toggleDrawer(true)} />
      <Drawer
        anchor="right"
        PaperProps={{ style: { height: '100%', backgroundColor: '#2c1229' } }}
        open={state}
        onClose={toggleDrawer(false)}
        className="drawerContainer"
      >
        <div className="drawer" role="presentation">
          <div className="filterHeader">
            <h2>Filters</h2>
            <XIcon
              onClick={toggleDrawer(false)}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <Box sx={{minWidth: 450}}>
            <div className="filterItem">
              <label>Status</label>
              <SimpleSelect
                  selectedOption={filter.status}
                  options={transactionStatuses}
                  label={'Select status'}
                  onChange={(value) => handleSelected('status', value)}
                  clearIcon={() => handleClearIcon('status')}
              />
            </div>
            <div className="filterItem">
              <label>TX Type</label>
              <SimpleSelect
                  selectedOption={filter.type}
                  options={transactionTypes}
                  label={'Select type'}
                  onChange={(value) => handleSelected('type', value)}
                  clearIcon={() => handleClearIcon('type')}
              />
            </div>
            <div className="filterItem">
              <label>Payment method</label>
              <SimpleSelect
                  selectedOption={filter.paymentMethod}
                  options={paymentMethods}
                  label={'Select payment method'}
                  onChange={(value) => handleSelected('paymentMethod', value)}
                  clearIcon={() => handleClearIcon('paymentMethod')}
              />
            </div>

            <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  padding: 13,
                }}
            >
              <SimpleActionButton
                  text="Clear filters"
                  fullWidth={true}
                  onClick={handleResetFieldsClick}
              />
              <MainButton
                  text="Save"
                  fullWidth={true}
                  onClick={() =>
                      filteredData ? filteredData(filter) : undefined
                  }
              />
            </div>
          </Box>
        </div>
      </Drawer>
    </div>
  );
};
