import React from 'react';
import './UserPricing.scss';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PhysicalDetails } from '../../gingrProfileDetails.reducer';
import { OneColumnTable } from '../../OneColumnTable/OneColumnTable';

interface GingrPhysicalDetailsProps {
  data?: PhysicalDetails | any;
}

export const UserPricing = ({ data }: GingrPhysicalDetailsProps) => {
  const [t] = useTranslation('translation');
  const pricing = data.pricing;
  const locations: string[] = [];
  const travelTo: string[] = [];

  if (pricing.outcall.hotel ) {
    locations.push(t('WORK_LOCATIONS.CLIENT_HOTEL'));
  }
  if (pricing.outcall.home ) {
    locations.push(t('WORK_LOCATIONS.CLIENT_HOME'));
  }
  if (pricing.outcall.event ) {
    locations.push(t('WORK_LOCATIONS.EVENTS'));
  }

  return (
    <div className="userPricing">
      <div className="left">
        <div className="tableContainer">
          <h2>Incall</h2>
          {pricing?.incall?.intervals?.length > 0 && (
            <table>
              <tr>
                <th>Time</th>
                <th>Price</th>
              </tr>
              <tbody>
                {pricing.incall.intervals &&
                  pricing.incall.intervals.map((item) => (
                    <tr>
                      <td>
                        {item.time}{' '}
                        {item.timeHM === 'M'
                          ? t('COMMON.MIN')
                          : t('COMMON.HOUR')}
                      </td>
                      <td>
                        {item.amount} {pricing.incall.symbol}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Divider
        variant="middle"
        style={{ backgroundColor: '#3a1b37', borderWidth: 1 }}
      />
      <div className="left">
        <div className="tableContainer">
          <h2>Outcall</h2>
          {pricing?.outcall?.intervals?.length > 0 && (
            <table>
              <tr>
                <th>Time</th>
                <th>Price</th>
              </tr>
              <tbody>
                {pricing.outcall.intervals &&
                  pricing.outcall.intervals.map((item) => (
                    <tr>
                      <td>
                        {item.time}{' '}
                        {item.timeHM === 'M'
                          ? t('COMMON.MIN')
                          : t('COMMON.HOUR')}
                      </td>
                      <td>
                        {item.amount} {pricing.outcall.symbol}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <Divider
          variant="middle"
          style={{ backgroundColor: '#3a1b37', borderWidth: 1, margin: 10 }}
        />
        <div className="secondTableContainer">
          <OneColumnTable head="Location" data={locations} />
        </div>
      </div>
    </div>
  );
};
