import { GingrClient3 } from '../clients/GingrClient3';
import { BaseService } from './BaseService';
import {
  PaymentMethod,
  TransactionModel,
  TransactionStatus,
  TransactionType,
} from '../components/Pages/Transactions/transaction.model';
import { PaginatedResultModel } from '../shared/paginated-result.model';

export class AdminCreditBalanceService {
  static async getTransactionList(
    skip: number,
    take: number,
    token: string,
    query?: string,
    status?: TransactionStatus,
    type?: TransactionType,
    paymentMethod?: PaymentMethod,
  ): Promise<PaginatedResultModel<TransactionModel>> {
    try {
      return await GingrClient3.post<PaginatedResultModel<TransactionModel>>(
        `admin/credit-balance/list`,
        {
          skip,
          take,
          query: query || undefined,
          status,
          type,
          paymentMethod
        },
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return { resultCount: 0, results: [] };
    }
  }

  static async changeTransactionStatus(
    id: number,
    confirm: boolean,
    token: string
  ): Promise<any> {
    return await GingrClient3.post(
      `admin/credit-balance/status/${id}/${confirm}`,
      {},
      BaseService.getBaseHeaders(token)
    );
  }

  static async addSupportTransaction(
    userId: number,
    amount: number,
    description: string,
    deducted: boolean,
    token: string
  ): Promise<any> {
    return await GingrClient3.post(
      `admin/credit-balance/add`,
      { userId, amount, description, deducted },
      BaseService.getBaseHeaders(token)
    );
  }
}
