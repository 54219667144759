import './CreateTransaction.scss';
import React from 'react';
import { Header } from '../../../Elements/Header/Header';
import { SimpleUserModel } from '../../Users/simple-user.model';
import { AdminUserService } from '../../../../service/AdminUserService';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { useDebounce } from 'use-debounce';
import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import TextField from '@material-ui/core/TextField';
import { MainButton } from '../../Login/MainButton';
import { SimpleActionButton } from '../../../GeneralComponents/SimpleActionButton';
import { useHistory } from 'react-router-dom';
import { AdminCreditBalanceService } from '../../../../service/AdminCreditBalanceService';
import { TransactionStatus } from '../transaction.model';
import { useConfirm } from 'material-ui-confirm';

export const CreateTransaction: React.FC = () => {
  const [userSearchOpen, setUserSearchOpen] = React.useState(false);
  const [userSearchValue, setUserSearchValue] = React.useState('');
  const [debouncedUserSearchValue] = useDebounce(userSearchValue, 350);
  const [users, setUsers] = React.useState<readonly SimpleUserModel[]>([]);
  const [userId, setUserId] = React.useState<number | undefined>(undefined);
  const [amount, setAmount] = React.useState(100);
  const [description, setDescription] = React.useState('');
  const [deducted, setDeducted] = React.useState(false);
  const [requesting, setRequesting] = React.useState(false);

  const confirm = useConfirm();
  const history = useHistory();
  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const addTransaction = () => {
    if (requesting || !userId || !amount) {
      return;
    }
    setRequesting(true);

    confirm({
      title: 'Add transaction',
      description: `Are you sure you want to add the transaction?`,
    })
      .then(async () => {
        await AdminCreditBalanceService.addSupportTransaction(
          userId || 0,
          amount,
          description,
          deducted,
          token
        )
          .then(() => {
            setRequesting(false);
            history.push('/transactions');
          })
          .catch(() => {
            alert('Error confirming transaction');
            setRequesting(false);
          });
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      const data = await AdminUserService.searchUsers(
        debouncedUserSearchValue,
        token
      );

      if (active) {
        setUsers([...data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [debouncedUserSearchValue]);

  React.useEffect(() => {
    if (!userSearchOpen) {
      setUsers([]);
    }
  }, [userSearchOpen]);

  return (
    <div>
      <Header title={'Create support transaction'} />

      <div className="transaction-container flex">
        <div className="subcontainer flex flex-column">
          <div className="label-input-group">
            <span className="label">User</span>
            <Autocomplete
              open={userSearchOpen}
              onOpen={() => {
                setUserSearchOpen(true);
              }}
              onClose={() => {
                setUserSearchOpen(false);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                `${option.displayName} (${option.id})`
              }
              options={users}
              autoComplete
              includeInputInList
              filterSelectedOptions
              noOptionsText="No users"
              filterOptions={(x) => x}
              onChange={(event, newValue: SimpleUserModel | null) => {
                setUserId(newValue ? newValue.id : undefined);
              }}
              onInputChange={(event, newInputValue) => {
                setUserSearchValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>

          <div className="label-input-group">
            <span className="label">Description</span>
            <TextField
              multiline
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="subcontainer flex flex-column">
          <div className="label-input-group">
            <span className="label">Amount</span>
            <TextField
              value={amount}
              onChange={(e) => setAmount(+e.target.value)}
            />
          </div>

          <div className="label-input-group radio-group">
            <span className="label">Transaction type</span>
            <RadioGroup
              row
              name="deducted-group"
              defaultValue={0}
              onChange={(event, value) => setDeducted(!!+value)}
            >
              <FormControlLabel
                control={<Radio value={0} />}
                label="Credit (+)"
              />
              <FormControlLabel
                control={<Radio value={1} />}
                label="Debit (-)"
              />
            </RadioGroup>
          </div>
        </div>
      </div>

      <div className="separator"></div>

      <div className="flex">
        <SimpleActionButton
          text="Cancel"
          onClick={() => history.push('/transactions')}
        />
        <MainButton text="Create transaction" onClick={addTransaction} />
      </div>
    </div>
  );
};
