import React from 'react';
import { Header } from '../../Elements/Header/Header';
import {
  DashboardItemStats,
  DashboardItemStatsType,
} from './DashboardItemStats/DashboardItemStats';
import './Dashboard.scss';
import { dashboardChartData as data } from './dummyData';
import { ChartButton } from './ChartButton';
import { ActivityItem } from './ActivityItem/ActivityItem';
import { GingrChart } from './GingrChart';
import { Calendar } from './Calendar/Calendar';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../rootReducer';
import { DashboardStatistics } from './dashboard.reducer';
import { fetchStatistics } from './dashboard.action';

const initStateItems: {
  client: boolean;
  gingrs: boolean;
  establishments: boolean;
  agencies: boolean;
  timeFrames: {
    today: number;
    thisMonth: number;
    thisYear: number;
  };
} = {
  client: false,
  gingrs: false,
  establishments: false,
  agencies: false,
  timeFrames: {
    today: 0,
    thisMonth: 0,
    thisYear: 0,
  },
};

export const Dashboard: React.FC = () => {
  const [selectedChartItem, setSelectedChartItem] =
    React.useState(initStateItems);
  const dispatch = useDispatch();

  const [statisticsInfo, setStatisticsInfo] =
    React.useState<DashboardStatistics>({
      clientsPendingApproval: 0,
      escortsPendingApproval: 0,
      totalLiveClients: 0,
      totalLiveEscorts: 0,
      totalRegisteredClients: 0,
      totalRegisteredEscorts: 0,
      totalRegisteredToday: 0,
      totalRegisteredThisMonth: 0,
      totalRegisteredThisYear: 0,
      percentageToday: 0,
      percentageThisMonth: 0,
      percentageThisYear: 0,
    });

  React.useEffect(() => {
    dispatch(fetchStatistics());
  }, []);

  const dashboardData = useSelector((state: AppState) => state.data.dashboard);
  const dashboardLiveUsersData = useSelector(
    (state: AppState) => state.data.dashboard
  );

  React.useEffect(() => {
    if (
      dashboardData &&
      dashboardData.statistics &&
      dashboardData.statistics.information !== 'INCOMPLETE'
    ) {
      setStatisticsInfo(dashboardData.statistics.information);
    }
  }, [dashboardData]);

  return (
    <div>
      <Header title={'Dashboard'} />
      <div className="dashboardStatsContainer">
        <DashboardItemStats
          number={`${
            statisticsInfo.totalLiveClients + statisticsInfo.totalLiveEscorts
          }`}
          text="Total Live Users"
        />
        <DashboardItemStats
          number={`${statisticsInfo.totalLiveClients}`}
          text="Total Live Clients"
        />
        <DashboardItemStats
          number={`${statisticsInfo.totalLiveEscorts}`}
          text="Total Live Gingrs"
        />
        <DashboardItemStats number="2,394" text="Total bookings" />
        <DashboardItemStats
          className="warning-number"
          number={`${
            statisticsInfo.clientsPendingApproval +
            statisticsInfo.escortsPendingApproval
          }`}
          text="Profiles Pending Approval"
        />
      </div>
      <div className="tabsAndFilterContainer">
        <div className="divider">
          <h2 className="title">Registrations: </h2>
          <ChartButton
            text="Client"
            isSelected={selectedChartItem.client}
            onClick={() =>
              setSelectedChartItem((prevState) => {
                return {
                  ...prevState,
                  client: !prevState.client,
                };
              })
            }
          />
          <ChartButton
            text="Gingrs"
            isSelected={selectedChartItem.gingrs}
            onClick={() =>
              setSelectedChartItem((prevState) => {
                return {
                  ...prevState,
                  gingrs: !prevState.gingrs,
                };
              })
            }
          />
          <ChartButton
            text="Establishments"
            isSelected={selectedChartItem.establishments}
            onClick={() =>
              setSelectedChartItem((prevState) => {
                return {
                  ...prevState,
                  establishments: !prevState.establishments,
                };
              })
            }
          />
          <ChartButton
            text="Agencies"
            isSelected={selectedChartItem.agencies}
            onClick={() =>
              setSelectedChartItem((prevState) => {
                return {
                  ...prevState,
                  agencies: !prevState.agencies,
                };
              })
            }
          />
        </div>
        <div className="divider">
          <Calendar />
        </div>
      </div>
      <div className="headerChartContainer">
        <GingrChart data={data} />
      </div>
      <div className="dashboardStatsContainer">
        <DashboardItemStats
          number={statisticsInfo.totalRegisteredClients.toString()}
          text="Total Registered Clients"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={statisticsInfo.totalRegisteredEscorts.toString()}
          text="Total Registered Gingrs"
          negativeNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={'0'}
          text="Total Registered Establishments"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={'0'}
          text="Total Registered Agencies"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
      </div>
      <div className="dashboardStatsContainer">
        <DashboardItemStats
          number={`${
            statisticsInfo.totalRegisteredClients +
            statisticsInfo.totalRegisteredEscorts
          }`}
          text="Total registration"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={statisticsInfo.totalRegisteredThisYear.toString()}
          text="This year’s registration"
          percentage={statisticsInfo.percentageThisYear}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={statisticsInfo.totalRegisteredThisMonth.toString()}
          text="This month’s registration"
          percentage={statisticsInfo.percentageThisMonth}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number={statisticsInfo.totalRegisteredToday.toString()}
          text="Today's registration"
          percentage={statisticsInfo.percentageToday}
          type={DashboardItemStatsType.PERCENTAGE}
        />
      </div>
      <p className="universalTitle">Activity</p>
      <div className="activityContainer">
        <div className="itemsDivider">
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
        </div>
        <div className="itemsDivider">
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
          <ActivityItem
            status="booked"
            firstUser="Melissa Morillo"
            secondUser="Udom Paowsong"
            minAgo="12"
          />
        </div>
      </div>
      <p className="universalTitle">Revenue</p>
      <div className="headerChartContainer">
        <GingrChart data={data} />
      </div>
      <div className="dashboardStatsContainer">
        <DashboardItemStats
          number="442"
          text="Total revenue"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number="1,492"
          text="This year’s revenue"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number="444"
          text="This month’s revenue"
          negativeNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
        <DashboardItemStats
          number="33"
          text="Today's revenue"
          positiveNumber={23}
          type={DashboardItemStatsType.PERCENTAGE}
        />
      </div>
    </div>
  );
};
