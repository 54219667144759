import React from 'react';
import { ContentService } from '../../../../service/ContentService';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { useHistory, useParams } from 'react-router-dom';
import { CityModel } from '../Model/CityModel';
import Table from '../../../GeneralComponents/Table';
import { TableColumn } from '../../../GeneralComponents/Table/Table';
import { CityDetailsModel } from '../Model/CityDetailsModel';
import { AddCityContent } from './AddCityContent';

export const CityDetails: React.FC = () => {
  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );
  const params: any = useParams();
  const history = useHistory();
  const [city, setCity] = React.useState<CityDetailsModel>(
    {} as CityDetailsModel
  );
  const [contents, setContents] = React.useState<CityModel[]>([]);

  React.useEffect(() => {
    if (!params.id) {
      return;
    }
    const fetchData = async () => {
      const cityDetails = await ContentService.getCity(token, params.id);
      setCity(cityDetails);
    };

    fetchData().catch(console.error);
  }, [params]);

  React.useEffect(() => {
    if (!params.id) {
      return;
    }
    const fetchData = async () => {
      const contents = await ContentService.getCityContents(token, params.id);
      setContents(contents);
    };

    fetchData().catch(console.error);
  }, [params]);

  const navigateToCityContent = (content) => {
    history.push(`/content/cities/${city.id}/${content}`);
  };

  const navigateToCountry = () => {
    history.push(`/content/countries/${city.countryId}`);
  };

  const refreshData = async () => {
    const contents = await ContentService.getCityContents(token, params.id);
    setContents(contents);
  };

  const columns: TableColumn[] = [
    {
      columnName: 'id',
      columnLabel: 'Id',
      sortable: true,
      textAlign: 'center',
      dataFormat: (value: any, data) => (
        <span
          className="cursor-pointer"
          onClick={() => {
            navigateToCityContent(data.contentType);
          }}
        >
          {value}
        </span>
      ),
    },
    {
      columnName: 'contentType',
      columnLabel: 'Content type',
      sortable: true,
      textAlign: 'center',
    },
    {
      columnName: 'updatedAt',
      columnLabel: 'Updated at',
      sortable: true,
      textAlign: 'center',
    },
  ];

  return (
    <div>
      <div className="content">
        <h1 className="city title" onClick={() => navigateToCountry()}>
          {city.countryName}
        </h1>
        <h2 className="title">{city.name}</h2>
        <AddCityContent
          cityId={city.id}
          successCallback={refreshData}
        ></AddCityContent>
        <Table
          columns={columns}
          data={contents}
          noDataFoundText="No Data Found!"
          rowsPerPage={100}
          defaultSort={{ orderBy: 'contentType', order: 'asc' }}
        />
      </div>
    </div>
  );
};
