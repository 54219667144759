import { BaseService } from '../../../../service/BaseService';
import { GingrClient3 } from '../../../../clients/GingrClient3';
import { PaginatedResultModel } from '../../../../shared/paginated-result.model';
import { UserModel } from '../user.model';
import { GingrProfileDetails } from '../GingrDetails/gingrProfileDetails';

export class GingrService {
  static async getUsers(
    token: string,
    filters?: any
  ): Promise<PaginatedResultModel<UserModel>> {
    try {
      return await GingrClient3.post<PaginatedResultModel<UserModel>>(
        `admin/user/list`,
        filters,
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return { resultCount: 0, results: [] };
    }
  }

  static async getUser(
    token: string,
    userId: string
  ): Promise<GingrProfileDetails | null> {
    try {
      return await GingrClient3.get<GingrProfileDetails>(
        `admin/user/${userId}`,
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return null;
    }
  }
}
