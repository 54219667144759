import React from 'react';
import { Header } from '../../Elements/Header/Header';
import {
  DashboardItemStats,
  DashboardItemStatsType,
} from '../Dashboard/DashboardItemStats/DashboardItemStats';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from '../../../assets/img/icon/filters.svg';
import Pagination from '@material-ui/lab/Pagination';
import './BookingAndRefunds.scss';

import { makeStyles } from '@material-ui/core/styles';
import { BookingsAndRefundsTable } from './BookingsAndRefundsTable';
import { BOOKING_AND_REFUNDS } from './DummyData';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});
export const BookingAndRefunds: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <Header title={'Booking & Refunds'} />
      <div>
        <div className="bookingAndRefundsContainer">
          <div className="statsContainer">
            <DashboardItemStats number="442" text="Bookings per day" />
            <DashboardItemStats
              number="444"
              text="Bookings per week"
              negativeNumber={23}
              type={DashboardItemStatsType.PERCENTAGE}
            />
            <DashboardItemStats
              number="33"
              text="Bookings per month"
              positiveNumber={23}
              type={DashboardItemStatsType.PERCENTAGE}
            />
            <DashboardItemStats
              number="2,394"
              text="Bookings per year"
              type={DashboardItemStatsType.PERCENTAGE}
              negativeNumber={23}
            />
            <DashboardItemStats
              number="444"
              type={DashboardItemStatsType.PERCENTAGE}
              text="Today bookings"
              negativeNumber={23}
            />
          </div>
          <div className="searchContainer">
            <div className="search">
              <SearchIcon className="searchIcon" />
              <TextField
                InputProps={{
                  style: {
                    color: '#8e818d',
                    fontSize: 15,
                    width: 400,
                    marginLeft: 16,
                  },
                  classes,
                }}
                id="standard-basic"
                placeholder="Search users by display name"
              />
            </div>
            <div className="filter">
              <FilterIcon className="filterIcon" />
              <p className="filterText">Filter</p>
            </div>
          </div>
          <div className="logsContainer">
            <BookingsAndRefundsTable data={BOOKING_AND_REFUNDS.data} />
          </div>
          <div className="paginationContainer">
            <Pagination count={5} shape="rounded" />
          </div>
        </div>
      </div>
    </div>
  );
};
