import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './ReportsTable.scss';
import { Avatar } from '@material-ui/core';
import EyeIcon from '../../../assets/img/icon/eye.svg';

export enum REPORT_TYPE {
  SYSTEM = 'System',
  USER = 'User Report',
  GINGR = 'Gingr Report',
  CONTENT = 'Content Report',
}
export interface ReportsTableProps {
  data: ReportsTableRow[];
}
export interface ReportsTableRow {
  id: string;
  displayName: string;
  img: string;
  isVisibleReport?: boolean;
  reports: string;
  type: REPORT_TYPE;
  date: string;
}

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#2c1229',
      color: '#8e818d',
      borderBottom: 'none',
    },
    body: {
      fontSize: 14,
      backgroundColor: '#1e041b',
      padding: 8,
      color: '#ffffff',
      borderBottom: 'none',
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderBottom: '1px solid #2c1229',
  },
});

export const ReportsTable: React.FC<ReportsTableProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <div className="reportsItemContainer">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="left">Display name</StyledTableCell>
              <StyledTableCell align="left">Reports</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Date</StyledTableCell>
              <StyledTableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.id}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <div className="avatarContainer">
                    <Avatar className="avatarPosition" src={row.img} />
                    {row.displayName}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="left">{row.reports}</StyledTableCell>
                <StyledTableCell align="left">{row.type}</StyledTableCell>
                <StyledTableCell align="left">{row.date}</StyledTableCell>
                <StyledTableCell align="center">
                  {(row.isVisibleReport && <EyeIcon className="eyeIcon" />) || (
                    <EyeIcon className="eyeIcon" style={{ opacity: 0.3 }} />
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
