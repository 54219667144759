import React, { useEffect, useState } from 'react';
import { Header } from '../../Elements/Header/Header';
import { AdminCreditBalanceService } from '../../../service/AdminCreditBalanceService';
import { useSelector } from 'react-redux';
import { AppState } from '../../../rootReducer';
import {
  PaymentMethod,
  TransactionModel,
  TransactionStatus,
  TransactionType,
} from './transaction.model';
import TableV2, { TableV2Column } from '../../GeneralComponents/Table/TableV2';
import { PaginatedResultModel } from '../../../shared/paginated-result.model';
import { useConfirm } from 'material-ui-confirm';
import './Transactions.scss';
import { DateTimeFormatter } from '../../../utils/Utils';
import { TransactionsFilter } from './TransactionsFilter';
import SearchIcon from '@material-ui/icons/Search';
import { Avatar, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDebounce } from 'use-debounce';
import { Link, useHistory } from 'react-router-dom';
import { MainButton } from '../Login/MainButton';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

export const Transactions: React.FC = () => {
  const [isFilterOpened, openFilter] = React.useState(false);
  const [page, setPage] = useState(1);
  const [filterQuery, setFilterQuery] = useState('');
  const [filter, setFilter] = React.useState<{
    status?: TransactionStatus;
    type?: TransactionType;
    paymentMethod?: PaymentMethod
  }>({
    status: undefined,
    type: undefined,
    paymentMethod: undefined
  });
  const [debouncedFilterQuery] = useDebounce(filterQuery, 350);
  const [transactionList, setTransactionList] = useState<
    PaginatedResultModel<TransactionModel>
  >({ results: [], resultCount: 0 });

  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const history = useHistory();
  const classes = useStyles();
  const pageSize = 10;
  const columns: TableV2Column[] = [
    {
      columnName: 'id',
      columnLabel: 'Display name',
      textAlign: 'left',
      dataFormat: (value: number, transaction: TransactionModel) => {
        return (
          <Link
            className="avatarContainer"
            to={`/users/${
              transaction.user.role === 1 || transaction.user.role === 2
                ? 'gingrs'
                : transaction.user.role === 3 || transaction.user.role === 4
                ? 'clients'
                : transaction.user.role === 5 || transaction.user.role === 6
                ? 'establishments'
                : 'agencies'
            }/${transaction.user.id}`}
          >
            <Avatar className="avatarPosition" src={transaction.user.avatar} />
            <p style={{ margin: 0, padding: 0 }}>
              {transaction.user.displayName}
            </p>
          </Link>
        );
      },
    },
    {
      columnName: 'id',
      columnLabel: 'Transaction ID',
      textAlign: 'left',
    },
    {
      columnName: 'bookingId',
      columnLabel: 'Booking ID',
      textAlign: 'left',
    },
    {
      columnName: 'amount',
      columnLabel: 'Amount',
      textAlign: 'left',
    },
    {
      columnName: 'transactionType',
      columnLabel: 'Tx type',
      textAlign: 'left',
    },
    {
      columnName: 'paymentMethod',
      columnLabel: 'Payment method',
      textAlign: 'left',
      dataFormat: (value: string, transaction: TransactionModel) => {
        return value || transaction.payoutType;
      },
    },
    {
      columnName: 'transactionStatus',
      columnLabel: 'Status',
      textAlign: 'left',
    },
    {
      columnName: 'dateCreated',
      columnLabel: 'Date & time',
      textAlign: 'left',
      dataFormat: (value: string) => {
        return DateTimeFormatter(value);
      },
    },
    {
      columnName: 'id',
      columnLabel: '',
      textAlign: 'center',
      dataFormat: (value: number, transaction: TransactionModel) => {
        return (
          <TransactionActions
            value={value}
            transaction={transaction}
            updateTransactionStatus={updateTransactionStatus}
          />
        );
      },
    },
  ];

  const getTransactionList = async () => {
    const data = await AdminCreditBalanceService.getTransactionList(
      (page - 1) * pageSize,
      pageSize,
      token,
      debouncedFilterQuery,
      filter.status,
      filter.type,
        filter.paymentMethod
    );
    setTransactionList(data);
  };

  const updateTransactionStatus = (id: number, status: TransactionStatus) => {
    const transaction = transactionList.results.find((e) => e.id === id);
    if (transaction) {
      transaction.transactionStatus = status;
    }
    setTransactionList({
      resultCount: transactionList.resultCount,
      results: transactionList.results,
    });
  };

  const filteredData = (data: any) => {
    setFilter({
      status: data?.status?.id,
      type: data?.type?.id,
      paymentMethod: data?.paymentMethod?.id
    });
  };

  useEffect(() => {
    (async () => {
      await getTransactionList();
    })();
  }, [page, debouncedFilterQuery, filter]);

  return (
    <div>
      <Header title={'Transactions'} />

      <div className="searchContainer">
        <div className="search">
          <SearchIcon className="searchIcon" />
          <TextField
            InputProps={{
              style: {
                width: 500,
                color: '#8e818d',
                fontSize: 15,
                marginLeft: 16,
              },
              classes,
            }}
            id="standard-basic"
            placeholder="Search by 'Display name' or 'Transaction ID'"
            autoComplete={'off'}
            onChange={(searchVal) => {
              setFilterQuery(searchVal.target.value);
              setPage(1);
            }}
          />
        </div>
        <div className="filter">
          <TransactionsFilter
            isOpened={isFilterOpened}
            filteredData={filteredData}
          />
          <p className="filterText">Filter</p>
        </div>
        <div className="cta">
          <MainButton
            text="+ Create support tx"
            onClick={() => history.push('/transactions/create')}
          />
        </div>
      </div>

      <div className="logsContainer">
        <TableV2
          columns={columns}
          data={transactionList}
          noDataFoundText="No Data Found!"
          currentPage={page}
          pageSize={pageSize}
          setActivePage={setPage}
        />
      </div>
    </div>
  );
};

const TransactionActions = ({
  value,
  transaction,
  updateTransactionStatus,
}: {
  value: number;
  transaction: TransactionModel;
  updateTransactionStatus: (id: number, status: TransactionStatus) => void;
}) => {
  const confirm = useConfirm();
  const [open, setOpen] = React.useState(false);
  const switchOpen = () => {
    setOpen(!open);
  };
  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const confirmTransaction = () => {
    confirm({
      title: 'Confirm transaction',
      description: `Are you sure you want to confirm the transaction?`,
    })
      .then(async () => {
        setOpen(false);
        await AdminCreditBalanceService.changeTransactionStatus(
          value,
          true,
          token
        )
          .then(() => {
            updateTransactionStatus(
              transaction.id,
              TransactionStatus.Confirmed
            );
          })
          .catch(() => alert('Error confirming transaction'));
      })
      .catch(() => setOpen(false));
  };

  const declineTransaction = () => {
    confirm({
      title: 'Decline transaction',
      description: `Are you sure you want to decline the transaction?`,
    })
      .then(async () => {
        setOpen(false);
        await AdminCreditBalanceService.changeTransactionStatus(
          value,
          false,
          token
        )
          .then(() => {
            updateTransactionStatus(transaction.id, TransactionStatus.Rejected);
          })
          .catch(() => alert('Error declining transaction'));
      })
      .catch(() => setOpen(false));
  };

  return (
    <div>
      {transaction.transactionStatus === TransactionStatus.Pending ? (
        <div className="dropdown">
          <button className="cursor-pointer" onClick={switchOpen}>
            <b>...</b>
          </button>
          {open ? (
            <ul className="menu">
              <li className="menu-item">
                <button className="success" onClick={confirmTransaction}>
                  Confirm
                </button>
              </li>
              <li className="menu-item">
                <button className="danger" onClick={declineTransaction}>
                  Decline
                </button>
              </li>
            </ul>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
