import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../rootReducer';
import { loadComplete, loadStarted } from '../../../shared/loading.action';
import { DashboardService } from './DashboardService';
import { getCredentials } from '../../../shared/selectors/getCredentials';
import {
  GeneralLiveUsersCountState,
  GeneralUsersCountState,
  IncreasePercentage,
  InformationAction,
  TimeFrames,
} from './dashboard.reducer';

export type DashboardActionType = 'USERS_COUNT';

export type UsersCountAction = 'ADD_USERS_COUNT' | 'CLEAR_USERS_COUNT';

export type DashboardLiveUsersActionType = 'LIVE_USERS_COUNT';

export type LiveUsersCountAction =
  | 'ADD_LIVE_USERS_COUNT'
  | 'CLEAR_LIVE_USERS_COUNT';

export interface AddUsersCountAction extends Action<UsersCountAction> {
  type: 'ADD_USERS_COUNT';
  total: number;
  client: number;
  gingr: number;
  establishment: number;
  agency: number;
  timeFrames: TimeFrames;
  increasePercentage: IncreasePercentage;
}

export interface ClearUsersCountAction extends Action<UsersCountAction> {
  type: 'CLEAR_USERS_COUNT';
}

export interface AddLiveUsersCountAction extends Action<LiveUsersCountAction> {
  type: 'ADD_LIVE_USERS_COUNT';
  total: number;
  client: number;
  gingr: number;
  establishment: number;
  agency: number;
  pendingForApproval: number;
}

export interface ClearLiveUsersCountAction
  extends Action<LiveUsersCountAction> {
  type: 'CLEAR_LIVE_USERS_COUNT';
}

export const addUsersCount = (
  total: number,
  client: number,
  gingr: number,
  establishment: number,
  agency: number,
  timeFrames: TimeFrames,
  increasePercentage: IncreasePercentage
): AddUsersCountAction => ({
  type: 'ADD_USERS_COUNT',
  total,
  client,
  gingr,
  establishment,
  agency,
  timeFrames,
  increasePercentage,
});

export const clearAgentInformation = (): ClearUsersCountAction => ({
  type: 'CLEAR_USERS_COUNT',
});

export const addLiveUsersCount = (
  total: number,
  client: number,
  gingr: number,
  establishment: number,
  agency: number,
  pendingForApproval: number
): AddLiveUsersCountAction => ({
  type: 'ADD_LIVE_USERS_COUNT',
  total,
  client,
  gingr,
  establishment,
  agency,
  pendingForApproval,
});

export interface AddInformationAction extends Action<InformationAction> {
  type: 'ADD_INFORMATION';
  clientsPendingApproval: number;
  escortsPendingApproval: number;
  totalLiveClients: number;
  totalLiveEscorts: number;
  totalRegisteredClients: number;
  totalRegisteredEscorts: number;
  totalRegisteredToday: number;
  totalRegisteredThisMonth: number;
  totalRegisteredThisYear: number;
  percentageToday: number;
  percentageThisMonth: number;
  percentageThisYear: number;
}

export const addInformation = (
  clientsPendingApproval: number,
  escortsPendingApproval: number,
  totalLiveClients: number,
  totalLiveEscorts: number,
  totalRegisteredClients: number,
  totalRegisteredEscorts: number,
  totalRegisteredToday: number,
  totalRegisteredThisMonth: number,
  totalRegisteredThisYear: number,
  percentageToday: number,
  percentageThisMonth: number,
  percentageThisYear: number
): AddInformationAction => ({
  type: 'ADD_INFORMATION',
  clientsPendingApproval,
  escortsPendingApproval,
  totalLiveClients,
  totalLiveEscorts,
  totalRegisteredClients,
  totalRegisteredEscorts,
  totalRegisteredToday,
  totalRegisteredThisMonth,
  totalRegisteredThisYear,
  percentageToday,
  percentageThisMonth,
  percentageThisYear,
});

export const fetchStatistics =
  (): ThunkAction<void, AppState, any, any> =>
  async (dispatch, getState: () => AppState) => {
    dispatch(loadStarted('DASHBOARD_STATISTICS'));
    const credentials = getCredentials(getState());
    try {
      let response = await DashboardService.getStatistics(credentials.token);
      const {
        clientsPendingApproval,
        escortsPendingApproval,
        totalLiveClients,
        totalLiveEscorts,
        totalRegisteredClients,
        totalRegisteredEscorts,
        totalRegisteredToday,
        totalRegisteredThisMonth,
        totalRegisteredThisYear,
        percentageToday,
        percentageThisMonth,
        percentageThisYear,
      } = response;
      dispatch(
        addInformation(
          clientsPendingApproval,
          escortsPendingApproval,
          totalLiveClients,
          totalLiveEscorts,
          totalRegisteredClients,
          totalRegisteredEscorts,
          totalRegisteredToday,
          totalRegisteredThisMonth,
          totalRegisteredThisYear,
          percentageToday,
          percentageThisMonth,
          percentageThisYear
        )
      );
    } catch (error) {
      console.log('Error during add live users count action: ', error);
    }
    dispatch(loadComplete('DASHBOARD_STATISTICS'));
  };
