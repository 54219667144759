export interface TransactionModel {
  id: number;
  amount: number;
  bookingId?: number;
  deducted: boolean;
  dateCreated: string;
  userId: number;
  user: TransactionUserModel;
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  paymentMethod?: PaymentMethod;
  payoutType: PayoutType;
}

export interface TransactionUserModel {
  avatar?: string;
  displayName?: string;
  id: number;
  role: number;
}

export enum TransactionType {
  Welcome = 'Welcome',
  Support = 'Support',
  TopUp = 'TopUp',
  ServiceFee = 'ServiceFee',
  ServiceFeeRefund = 'ServiceFeeRefund',
  CompensationRefund = 'CompensationRefund',
  CancellationFee = 'CancellationFee',
  Referral = 'Referral',
  Payout = 'Payout',
  BalanceSettlement = 'BalanceSettlement'
}

export enum PaymentMethod {
  BankTransfer = 'BankTransfer',
  PaymentSlip = 'PaymentSlip',
  SwissPost = 'SwissPost',
  GingrCard = 'GingrCard',
  BankAccount = 'BankAccount',
  GingrOffice = 'GingrOffice'
}

export enum PayoutType {
  SwissPost = 'SwissPost',
  GingrOffice = 'GingrOffice',
  GingrCard = 'GingrCard',
  BankAccount = 'BankAccount',
}

export enum TransactionStatus {
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Rejected = 'Rejected',
}
