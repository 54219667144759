import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './PaymentsTable.scss';
import SuccessIcon from '../../../assets/img/icon/done.svg';
import PendingIcon from '../../../assets/img/icon/pending.svg';
import CanceledIcon from '../../../assets/img/icon/inactive.svg';
import { STATUS } from '../BookingAndRefunds/BookingsAndRefundsTable';
import EyeIcon from '../../../assets/img/icon/eye.svg';
import DownloadIcon from '../../../assets/img/icon/download.svg';
import { Avatar } from '@material-ui/core';

export enum PaymentType {
  BOOKING,
  SUBSCRIPTION,
}

export interface PaymentTableProps {
  data: PaymentRow[];
}
export interface PaymentRow {
  id: string;
  payer: string;
  img: string;
  bookingFor?: string;
  paymentDetail: string;
  dateAndTime: string;
  status: string;
  amount: string;
}

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#2c1229',
      color: '#8e818d',
      borderBottom: 'none',
    },
    body: {
      fontSize: 14,
      backgroundColor: '#1e041b',
      padding: 8,
      color: '#ffffff',
      borderBottom: 'none',
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const PaymentsTable: React.FC<PaymentTableProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <div className="paymentTableContainer">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="center">Payer</StyledTableCell>
              <StyledTableCell align="center">Payment Detail</StyledTableCell>
              <StyledTableCell align="center">Date and time</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Amount</StyledTableCell>
              <StyledTableCell align="center" />
              <StyledTableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="left" scope="row">
                  {row.id}
                </StyledTableCell>
                <StyledTableCell align="center" scope="row">
                  <div className="avatarContainer">
                    <Avatar className="avatarPosition" src={row.img} />
                    {row.payer}
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  scope="row"
                  className="paymentDetailText"
                >
                  {(row.bookingFor && (
                    <>
                      Payment for booking for
                      <p className="highlighted"> {row.bookingFor}</p>
                    </>
                  )) || <>{row.paymentDetail}</>}
                </StyledTableCell>

                <StyledTableCell align="center" scope="row">
                  {row.dateAndTime}
                </StyledTableCell>

                <StyledTableCell align="left" width={95}>
                  <div className="statusContainer">
                    {(row.status === STATUS.SUCCESS && (
                      <>
                        <SuccessIcon className="success" />
                        {row.status}
                      </>
                    )) ||
                      (row.status === STATUS.CANCELED && (
                        <>
                          <CanceledIcon className="canceled" />
                          {row.status}
                        </>
                      )) || (
                        <>
                          <PendingIcon className="pending" />
                          {row.status}
                        </>
                      )}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center" scope="row">
                  $ {row.amount}
                </StyledTableCell>
                <StyledTableCell align="center" scope="row">
                  <EyeIcon className="icon" />
                </StyledTableCell>
                <StyledTableCell align="center" scope="row">
                  <DownloadIcon className="icon" />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
