import { Action } from 'redux';
import produce from 'immer';
import { AddUserProfileDetailsAction } from './userProfileDetails.action';

export interface GeneralUserProfileDetailsState {
  id: number;
  slug: string;
  email: string;
  proUser: boolean;
  displayname: string;
  firstName: string;
  lastName: string;
  createdAt: Date;
  dateOfBirth: string;
  birthYear: number;
  gender: number;
  nationality: string;
  sexuality: number;
  phonePrefix: string;
  phoneNumber: string;
  profileStatus: number;
  description: string;
  height: number;
  weight: number;
  penisSize: number;
  genitaliaArea: string;
  hear: string;
  hearText: string;
  avatar: Avatar;
}
export interface Avatar {
  id: number;
  type: string;
  media: string;
  thumb: string;
  thumbblur: string;
}

export type UserProfileDetailsAction =
  | 'ADD_USER_PROFILE_DETAILS'
  | 'CLEAR_USER_PROFILE_DETAILS';

export type UserProfileDetailsState =
  | 'INCOMPLETE'
  | GeneralUserProfileDetailsState;

export const userProfileDetailsReducer = (
  state: UserProfileDetailsState = 'INCOMPLETE',
  action: Action<UserProfileDetailsAction>
): UserProfileDetailsState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_USER_PROFILE_DETAILS':
        const addUserProfileDetailsAction =
          action as AddUserProfileDetailsAction;
        const {
          id,
          slug,
          email,
          proUser,
          displayname,
          firstName,
          lastName,
          createdAt,
          dateOfBirth,
          birthYear,
          gender,
          nationality,
          sexuality,
          phonePrefix,
          phoneNumber,
          profileStatus,
          description,
          height,
          weight,
          penisSize,
          genitaliaArea,
          hear,
          hearText,
          avatar,
        } = addUserProfileDetailsAction;
        return {
          id,
          slug,
          email,
          proUser,
          displayname,
          firstName,
          lastName,
          createdAt,
          dateOfBirth,
          birthYear,
          gender,
          nationality,
          hear,
          sexuality,
          phonePrefix,
          phoneNumber,
          profileStatus,
          description,
          height,
          weight,
          penisSize,
          genitaliaArea,
          hearText,
          avatar,
        };
      case 'CLEAR_USER_PROFILE_DETAILS':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};
