import React from 'react';
import '../Login.scss';
import { MainButton } from '../MainButton';
import logo from '../../../../assets/img/logo/logo1.svg';
import { LoginHeader } from '../LoginHeader/LoginHeader';
import { setPasswordResult } from '../login.action';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { Divider, IconButton, InputAdornment } from '@mui/material';
import {
  CancelOutlined,
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { alpha as fade } from '@material-ui/core/styles/colorManipulator';
import { CredentialsService } from '../../../../service/CredentialsService';
export const RESET_PASSWORD_ROUTE = '/resetpassword';

const CssTextField = withStyles({
  root: {
    multilineColor: {
      color: 'red',
    },
    backgroundColor: fade('#ffffff', 0.1),
    borderRadius: 4,
    color: 'transparent',
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff',
      },
    },
  },
})(TextField);

export const ResetPassword: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [error, setError] = React.useState({
    validCurrentPassword: false,
    validNewPassword: false,
    passwordDoNotMatch: true,
  });
  const dispatch = useDispatch();

  React.useEffect(() => {
    const validPassword = new RegExp(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
    );
    const validNewPassword = validPassword.test(newPassword);
    setError({
      ...error,
      validNewPassword,
      passwordDoNotMatch: newPassword !== confirmPassword,
    });
  }, [newPassword, confirmPassword]);

  const resetPassword = async () => {
    if (error.validNewPassword && !error.passwordDoNotMatch) {
      setLoading(true);
      const result = await CredentialsService.completeNewPassword(
        newPassword,
        cognitoUser
      );
      setLoading(false);
      dispatch(setPasswordResult(result));
    }
  };

  const isPasswordValid = (password: string): boolean => {
    return new RegExp(/^([a-zA-Z0-9_!$%^*./#&@+-]){8,}$/).test(password);
  };
  const loginStatus = useSelector(
    (state: AppState) => state.presentation.login.loginType
  );

  const cognitoUser = useSelector(
    (state: AppState) => state.data.user.cognitoUser.user
  );

  return (
    <div className="container">
      <img src={logo} alt="logo" />
      <div className="login-container">
        <LoginHeader title="Reset password" />
        <div className="input-container">
          <label className="input-label" htmlFor="new-password-input">
            New password
          </label>
          <CssTextField
            id="outlined-search"
            variant="outlined"
            placeholder="Type your new password"
            inputProps={{
              style: {
                color: '#ffffff',
              },
            }}
            fullWidth={true}
            type={showPassword ? 'text' : 'password'}
            onChange={(event) => setNewPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility className="icon" />
                      ) : (
                        <VisibilityOff className="icon" />
                      )}
                    </IconButton>
                  </InputAdornment>
                  <Divider
                    orientation="vertical"
                    style={{ backgroundColor: '#4f2c4b' }}
                  />
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {error.validNewPassword ? (
                        <CheckCircleOutline className="iconSuccess" />
                      ) : (
                        <CancelOutlined className="iconError" />
                      )}
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
          {!error.validNewPassword && (
            <div className="errorConfirmPasswordMessage">
              <IconButton
                style={{ padding: 0, margin: 0 }}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                <CheckCircleOutline
                  className="icon"
                  style={{ color: '#f39fff' }}
                />
              </IconButton>
              {(!error.validNewPassword && (
                <p id="error-message">
                  Must contain 8 characters, one lower case, one upper case, one
                  number and one special character
                </p>
              )) ||
                (error.passwordDoNotMatch && (
                  <p id="error-message">Password do not match</p>
                ))}
            </div>
          )}
          <label className="input-label" htmlFor="confirm-password-input">
            Confirm password
          </label>
          <CssTextField
            id="outlined-search"
            variant="outlined"
            placeholder="Confirm password"
            inputProps={{
              style: {
                color: '#ffffff',
              },
            }}
            fullWidth={true}
            type={showPassword ? 'text' : 'password'}
            onChange={(event) => setConfirmPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility className="icon" />
                      ) : (
                        <VisibilityOff className="icon" />
                      )}
                    </IconButton>
                  </InputAdornment>
                  <Divider
                    orientation="vertical"
                    style={{ backgroundColor: '#4f2c4b' }}
                  />
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {!error.passwordDoNotMatch ? (
                        <CheckCircleOutline className="iconSuccess" />
                      ) : (
                        <CancelOutlined className="iconError" />
                      )}
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </div>

        <div className="button-container">
          <MainButton
            loading={loading}
            text="Reset password"
            fullWidth
            onClick={resetPassword}
          />
        </div>
      </div>
    </div>
  );
};
