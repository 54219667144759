import React from 'react';
import { Header } from '../../Elements/Header/Header';
import { GingrChart } from '../Dashboard/GingrChart';
import './Revenue.scss';
import { dashboardChartData as data } from '../Dashboard/dummyData';
import {
  DashboardItemStats,
  DashboardItemStatsType,
} from '../Dashboard/DashboardItemStats/DashboardItemStats';
import { RevenueTable } from './RevenueUserTable/RevenueTable';
import { REVENUE_DATA } from './RevenueDummyData';

export const Revenue: React.FC = () => {
  return (
    <div>
      <Header title={'Revenue'} />
      <div className="headerChartContainer">
        <GingrChart data={data} />
      </div>
      <div className="revenueStatsContainer">
        <DashboardItemStats
          number="1,442"
          text="From Endusers subscriptions"
          type={DashboardItemStatsType.PERCENTAGE}
          positiveNumber={23}
        />
        <DashboardItemStats
          number="444"
          type={DashboardItemStatsType.PERCENTAGE}
          text="From Gingrs subscriptions"
          positiveNumber={23}
        />
        <DashboardItemStats
          number="133"
          text="From Agencies subscriptions"
          type={DashboardItemStatsType.PERCENTAGE}
          negativeNumber={23}
        />
        <DashboardItemStats
          number="52,222"
          text="From bookings"
          type={DashboardItemStatsType.PERCENTAGE}
          positiveNumber={23}
        />
      </div>
      <div className="tableContainer">
        <RevenueTable
          title="Most spending Users in total"
          data={REVENUE_DATA.data}
        />
        <RevenueTable
          title="Most profitable Gingrs/Agencies in total"
          data={REVENUE_DATA.data}
        />
      </div>
    </div>
  );
};
