import produce from 'immer';
import { Action } from 'redux';
import {
  PendingApprovalCountActionType,
  SetPendingApprovalCountAction,
} from './sidebar.action';

export interface PendingApprovalCountState {
  clientpendingApproval: number;
  gingrpendingApproval: number;
  establishmentpendingApproval: number;
  agencypendingApproval: number;
}

const pendingApprovalInitState = {
  clientpendingApproval: 0,
  gingrpendingApproval: 0,
  establishmentpendingApproval: 0,
  agencypendingApproval: 0,
};

export const usersPendingApprovalCountReducer = (
  state: PendingApprovalCountState = pendingApprovalInitState,
  action: Action<PendingApprovalCountActionType>
): PendingApprovalCountState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'FETCH_PENDING_APPROVAL_USERS':
        const {
          clientpendingApproval,
          gingrpendingApproval,
          establishmentpendingApproval,
          agencypendingApproval,
        } = action as SetPendingApprovalCountAction;
        draftState = {
          clientpendingApproval,
          gingrpendingApproval,
          establishmentpendingApproval,
          agencypendingApproval,
        };
        return draftState;
      case 'REMOVE_PENDING_APPROVAL_USERS':
        return pendingApprovalInitState;
      default:
        return state;
    }
  });
};
