import { useMemo, useState } from 'react';
import './_Table.scss';
import SortIcon from '@material-ui/icons/Sort';
import {
  getHeaderAlign,
  getTextAlign,
  OrderBy,
  OrderType,
  paginateRows,
  sortRows,
} from './helpers';
import Pagination from './Pagination/Pagination';

interface TableProperties {
  columns: TableColumn[];
  noDataFoundText: string;
  data?: any;
  rowsPerPage?: number;
  defaultSort?: OrderBy;
}

interface ColumnProps {
  columnName: string;
  columnLabel: string;
}

export interface TableColumn extends ColumnProps {
  sortable: boolean;
  textAlign?: 'center' | 'left' | 'right';

  dataFormat?(
    input: string | boolean | number,
    data?: any
  ): React.ReactNode | string;

  headerFormat?(input: ColumnProps): React.ReactNode | string;
}

const Table = ({
  columns,
  data,
  noDataFoundText,
  defaultSort = { orderBy: '', order: 'none' },
  rowsPerPage = 0,
}: TableProperties) => {
  const [activePage, setActivePage] = useState(1);
  const [sort, setSort] = useState<OrderBy>(defaultSort);

  const handleSort = (accessor: string) => {
    setActivePage(1);

    setSort((prevSort) => {
      let order = 'asc';
      if (prevSort.orderBy !== accessor) {
        order = 'desc';
      } else {
        order = prevSort.order === 'asc' ? 'desc' : 'asc';
      }

      return {
        order: order as OrderType,
        orderBy: accessor,
      };
    });
  };

  const hasPagination = rowsPerPage && rowsPerPage > 0;
  const sortedRows = useMemo(() => sortRows(data, sort), [data, sort]);
  const count = sortedRows.length;
  const hasData = count > 0;

  const calculatedRows = hasPagination
    ? paginateRows(sortedRows, activePage, rowsPerPage)
    : sortedRows;
  const totalPages = hasPagination ? Math.ceil(count / rowsPerPage) : 0;

  const getSortIconClass = (columnName: string): string => {
    return columnName === sort.orderBy ? `${sort.order} sorted` : '';
  };

  const isSortIconVisible = (sortable: boolean): boolean => {
    return sortable;
  };

  return (
    <>
      <table className="table" data-testid="Table">
        <thead>
          <tr>
            {columns.map((column, idx) => {
              const { columnName, columnLabel, sortable, headerFormat } =
                column;
              return (
                <th
                  key={idx}
                  onClick={() => {
                    sortable && handleSort(columnName);
                  }}
                >
                  <div
                    className={`${getHeaderAlign(
                      column
                    )} table__header-content`}
                  >
                    <div className="table__column-name-container">
                      <span className="table__column-name">
                        {headerFormat
                          ? headerFormat({ columnName, columnLabel })
                          : columnLabel}
                      </span>
                      {isSortIconVisible(sortable) ? (
                        <SortIcon
                          className={`table__sort-icon ${getSortIconClass(
                            columnName
                          )}`}
                          name="sort"
                        />
                      ) : undefined}
                    </div>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {hasData ? (
            calculatedRows.map((row, index) => (
              <tr key={index}>
                {columns.map((column, columnKey) => {
                  const { columnName } = column;
                  return (
                    <td className={getTextAlign(column)} key={columnKey}>
                      {column.dataFormat
                        ? column.dataFormat(row[columnName], row)
                        : row[columnName]}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td className="table__no-data-found" colSpan={columns.length}>
                {noDataFoundText}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {hasData && rowsPerPage && rowsPerPage > 0 && totalPages > 1 ? (
        <div className="table__pagination-container">
          <Pagination
            activePage={activePage}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Table;
