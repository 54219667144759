import React from 'react';
import { Header } from '../../Elements/Header/Header';
import './Settings.scss';
import { SettingsHeader } from './SettingsHeader/SettingsHeader';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
export enum SettingsRoutes {
  SYSTEM_LANGUAGE = 'System Language',
  CHANGE_PASSWORD = 'Change Password',
}

import LogoutIcon from '../../../assets/img/icon/logout.svg';
import { Divider, IconButton, InputAdornment } from '@mui/material';
import {
  CheckCircleOutline,
  CancelOutlined,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../Login/login.action';
import { CredentialsService } from '../../../service/CredentialsService';
import { AppState } from '../../../rootReducer';
import { useDebounce } from 'use-debounce';
import { Auth } from '@aws-amplify/auth';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#4f2c4b',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4f2c4b',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4f2c4b',
      },
      '&:hover fieldset': {
        borderColor: '#4f2c4b',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4f2c4b',
      },
    },
  },
})(TextField);

export const Settings: React.FC = () => {
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
  };
  const [currentRoute, setCurrentRoute] = React.useState<SettingsRoutes>(
    SettingsRoutes.SYSTEM_LANGUAGE
  );
  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const [currentPassword, setCurrentPassword] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = React.useState({
    validCurrentPassword: false,
    validNewPassword: false,
    passwordDoNotMatch: true,
  });
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [search, setSearch] = React.useState<string>('');

  const [debounceCurrentPassword] = useDebounce(currentPassword, 500);

  React.useEffect(() => {
    const validPassword = new RegExp(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
    );
    const validNewPassword = validPassword.test(newPassword);
    setError({
      ...error,
      validNewPassword,
      passwordDoNotMatch: newPassword !== confirmPassword,
    });
  }, [newPassword, confirmPassword]);

  React.useEffect(() => {
    (async () => {
      const validCurrentPassword = !!currentPassword;
      setError({
        ...error,
        validCurrentPassword,
      });
    })();
  }, [debounceCurrentPassword]);

  const handleOnChangePassword = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const response = await CredentialsService.changePassword(
      currentPassword,
      confirmPassword,
      user
    );
    if (response) {
      logOut();
    }
  };

  return (
    <div>
      <Header title={'Settings'} />
      <div className="settingsContainer">
        <div className="controllers">
          <div className="routeContainer">
            <span
              className="item"
              onClick={() => setCurrentRoute(SettingsRoutes.SYSTEM_LANGUAGE)}
            >
              System Language
            </span>
            <span
              className="item"
              onClick={() => setCurrentRoute(SettingsRoutes.CHANGE_PASSWORD)}
            >
              Change Password
            </span>
          </div>
          <div className="logoutAndVersionContainer">
            <p className="version">Gingr v1.0 (2021)</p>
            <div className="logoutButton" onClick={logOut}>
              <LogoutIcon className="logoutIcon" />
              <p className="logoutButtonText">Logout</p>
            </div>
          </div>
        </div>
        {(currentRoute === SettingsRoutes.SYSTEM_LANGUAGE && (
          <>
            <div className="inputs">
              <SettingsHeader title={SettingsRoutes.SYSTEM_LANGUAGE} />

              <div className="selectLanguage">
                <p className="languageLabel">Language</p>
                <CssTextField
                  id="outlined-search"
                  variant="outlined"
                  placeholder="English"
                  onChange={(event) => setSearch(event.target.value)}
                  inputProps={{
                    style: {
                      color: '#ffffff',
                    },
                  }}
                  fullWidth={true}
                />
              </div>
            </div>
          </>
        )) || (
          <>
            <div className="inputs">
              <SettingsHeader title={SettingsRoutes.CHANGE_PASSWORD} />
              <div className="confirmPasswordSection">
                <p className="languageLabel">Current Password</p>
                <CssTextField
                  id="outlined-search"
                  variant="outlined"
                  placeholder="Type your current password"
                  inputProps={{
                    style: {
                      color: '#ffffff',
                    },
                  }}
                  fullWidth={true}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(event) => setCurrentPassword(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <Visibility className="icon" />
                            ) : (
                              <VisibilityOff className="icon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                        <Divider
                          orientation="vertical"
                          style={{ backgroundColor: '#4f2c4b' }}
                        />
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {error.validCurrentPassword ? (
                              <CheckCircleOutline className="iconSuccess" />
                            ) : (
                              <CancelOutlined className="iconError" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
                <Divider
                  orientation="horizontal"
                  style={{ backgroundColor: '#4f2c4b', marginTop: 60 }}
                />
              </div>
              <div
                className="confirmPasswordSection"
                style={
                  error.validCurrentPassword
                    ? {}
                    : {
                        pointerEvents: 'none',
                        opacity: 0.4,
                      }
                }
              >
                <p className="languageLabel">New Password</p>
                <CssTextField
                  id="outlined-search"
                  variant="outlined"
                  placeholder="Type your new password"
                  inputProps={{
                    style: {
                      color: '#ffffff',
                    },
                  }}
                  fullWidth={true}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(event) => setNewPassword(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <Visibility className="icon" />
                            ) : (
                              <VisibilityOff className="icon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                        <Divider
                          orientation="vertical"
                          style={{ backgroundColor: '#4f2c4b' }}
                        />
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {error.validNewPassword ? (
                              <CheckCircleOutline className="iconSuccess" />
                            ) : (
                              <CancelOutlined className="iconError" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
                {error.validCurrentPassword && (
                  <div className="errorConfirmPasswordMessage">
                    <IconButton
                      style={{ padding: 0, margin: 0 }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      <CheckCircleOutline
                        className="icon"
                        style={{ color: '#f39fff' }}
                      />
                    </IconButton>
                    {(error.validCurrentPassword && (
                      <p id="error-message">
                        Must contain 8 characters, one lower case, one upper
                        case, one number and one special character
                      </p>
                    )) ||
                      (error.passwordDoNotMatch && (
                        <p id="error-message">Password do not match</p>
                      ))}
                  </div>
                )}
                <p className="languageLabel">Confirm Password</p>
                <CssTextField
                  id="outlined-search"
                  variant="outlined"
                  placeholder="Confirm password"
                  inputProps={{
                    style: {
                      color: '#ffffff',
                    },
                  }}
                  fullWidth={true}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <Visibility className="icon" />
                            ) : (
                              <VisibilityOff className="icon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                        <Divider
                          orientation="vertical"
                          style={{ backgroundColor: '#4f2c4b' }}
                        />
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {!error.passwordDoNotMatch ? (
                              <CheckCircleOutline className="iconSuccess" />
                            ) : (
                              <CancelOutlined className="iconError" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className="confirmButtonContainer">
          <p className="text">Last changed: 22 May 2021 22:00</p>
          <div className="cancelButtonContainer">
            <p>Cancel</p>
          </div>
          <div className="saveButtonContainer" onClick={handleOnChangePassword}>
            <p>Save</p>
          </div>
        </div>
      </div>
    </div>
  );
};
