import { Action } from 'redux';
import produce from 'immer';
import { AddUsersAction } from './clients.action';

export interface GeneralUsersState {
  id: number;
  avatarThumb?: string;
  slug: string;
  displayname: string;
  gender: number;
  sexuality: number;
  profile_approved: number;
  proUser: boolean;
  created_at: string;
  online: boolean;
}

export type UsersAction = 'ADD_USERS' | 'CLEAR_USERS';

export type UsersState = 'INCOMPLETE' | GeneralUsersState[];

export const clientsReducer = (
  state: UsersState = 'INCOMPLETE',
  action: Action<UsersAction>
): UsersState => {
  return produce(state, () => {
    switch (action.type) {
      case 'ADD_USERS':
        const addUsersAction = action as AddUsersAction;
        const { users } = addUsersAction;
        return {
          users,
        };
      case 'CLEAR_USERS':
        return 'INCOMPLETE';
      default:
        return state;
    }
  });
};
