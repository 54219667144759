import { ActivityLogTableProps } from './ActivityLogTable';

const ACTIVITY_LOG: ActivityLogTableProps = {
  data: [
    {
      name: 'Jushawn McDowell',
      activity: 'Changed password',
      imageSrc: 'https://i.pravatar.cc/150?img=3',
      date: '22 May 2021',
      isVisible: true,
    },
    {
      name: 'Anne-Marije Markink',
      imageSrc: 'https://i.pravatar.cc/150?img=4',
      date: '22 May 2021',
      commentedOnUser: 'Nguyễn Diệp Chi',
      isVisible: true,
    },
    {
      name: 'Abayo Stevens',
      commentedOnUser: 'Nguyễn Diệp Chi',
      imageSrc: 'https://i.pravatar.cc/150?img=5',
      date: '22 May 2021',
    },
    {
      name: 'JUche Ogbonna',
      activity: 'Signed up',
      date: '22 May 2021',
      imageSrc: 'https://i.pravatar.cc/150?img=6',
      isVisible: true,
    },
    {
      name: 'Jushawn McDowell',
      activity: 'Reported an issue',
      imageSrc: 'https://i.pravatar.cc/150?img=10',
      date: '22 May 2021',
      isVisible: true,
    },
    {
      name: 'Geovanni Ahrens',
      bookedOnUser: 'Mariano Rasgado',
      imageSrc: 'https://i.pravatar.cc/150?img=11',
      date: '22 May 2021',
    },

    {
      name: 'Skyler Castaneda',
      bookedOnUser: 'Mariano Rasgado',
      imageSrc: 'https://i.pravatar.cc/150?img=141',
      date: '22 May 2021',
    },
  ],
};

export { ACTIVITY_LOG };
