import React from 'react';
import '../Revenue.scss';
import { CircularProgress } from '@mui/material';

interface SimpleButton {
  onClick?: () => void;
  text: string;
  marginTop?: number;
  backgroundColor?: string;
  fullWidth?: boolean;
  onKeyDownActive?: boolean;
  disabled?: boolean;
  loading?: boolean;
  noOpacity?: boolean;
}

export const SimpleButton: React.FC<SimpleButton> = ({
  text,
  disabled,
  loading,
  backgroundColor,
  onClick,
  onKeyDownActive,
  marginTop, noOpacity
}) => {
  let style: any = {
    backgroundColor,
    marginTop,
  };

  if (disabled) {
    style = {
      marginTop,
      backgroundColor,
      color: '#ffffff',
      opacity: 0.4,
      cursor: 'none',
      pointerEvents: 'none',
    };
  }

  if (noOpacity) {
    style = {
      opacity: 1,
      backgroundColor,
      cursor: 'none',
      pointerEvents: 'none',
      color: '#ffffff'
    }
  }

  return (
    <div
      role="button"
      onKeyDown={onKeyDownActive ? onClick : undefined}
      onClick={onClick}
      tabIndex={0}
      style={style}
      aria-disabled={disabled}
      className="buttonContainer"
    >
      <p className="simpleButtonText">{text}</p>
      {loading && (
        <CircularProgress
          size={14}
          style={{ color: '#FFFFFF', marginLeft: 5 }}
        />
      )}
    </div>
  );
};
