import React from 'react';
import './DashboardItemStats.scss';
import UpIcon from '../../../../assets/img/icon/up.svg';
import DownIcon from '../../../../assets/img/icon/down.svg';
import { isNumber } from '../../../GeneralComponents/Table/helpers';

export enum DashboardItemStatsType {
  PERCENTAGE,
  DEFAULT,
}

interface DashboardItemStatsProps {
  number: string;
  text: string;
  type?: DashboardItemStatsType;
  positiveNumber?: number;
  negativeNumber?: number;
  percentage?: number;
  className?: string;
}

export const DashboardItemStats: React.FC<DashboardItemStatsProps> = ({
  number,
  text,
  positiveNumber,
  negativeNumber,
  percentage,
  type,
  className,
}) => {
  return (
    <div className={`itemContainer ${className}`}>
      <div className="verticalLine" />
      {(type === DashboardItemStatsType.PERCENTAGE && (
        <div className="withPercentageContainer">
          <h2 className="number">{number}</h2>
          {positiveNumber && isNumber(positiveNumber) && (
            <>
              <UpIcon className="upIcon" />
              <p className="positiveNumber">{positiveNumber}%</p>
            </>
          )}{' '}
          {negativeNumber && isNumber(negativeNumber) && (
            <>
              <DownIcon className="downIcon" />
              <p className="negativeNumber">{negativeNumber}%</p>
            </>
          )}
          {percentage && isNumber(percentage) && percentage > 0 && (
            <>
              <UpIcon className="upIcon" />
              <p className="positiveNumber">{Math.abs(percentage)}%</p>
            </>
          )}{' '}
          {percentage && isNumber(percentage) && percentage < 0 && (
            <>
              <DownIcon className="downIcon" />
              <p className="negativeNumber">{Math.abs(percentage)}%</p>
            </>
          )}
        </div>
      )) || <h2 className="number">{number}</h2>}
      <p className="text">{text}</p>
    </div>
  );
};
