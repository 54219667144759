import './_TableV2.scss';
import { getHeaderAlign, getTextAlign } from './helpers';
import Pagination from './Pagination/Pagination';
import { Dispatch, SetStateAction } from 'react';
import { PaginatedResultModel } from '../../../shared/paginated-result.model';

interface TableV2Properties {
  columns: TableV2Column[];
  noDataFoundText: string;
  data: PaginatedResultModel<any>;
  currentPage: number;
  pageSize: number;
  setActivePage: Dispatch<SetStateAction<number>>;
}

interface ColumnV2Props {
  columnName: string;
  columnLabel: string;
}

export interface TableV2Column extends ColumnV2Props {
  textAlign?: 'center' | 'left' | 'right';

  dataFormat?(
    input: string | boolean | number,
    data?: any
  ): React.ReactNode | string;

  headerFormat?(input: ColumnV2Props): React.ReactNode | string;
}

const TableV2 = ({
  columns,
  data,
  noDataFoundText,
  currentPage = 0,
  pageSize = 0,
  setActivePage,
}: TableV2Properties) => {
  return (
    <>
      <table className="table" data-testid="Table">
        <thead>
          <tr>
            {columns.map((column, idx) => {
              const { columnName, columnLabel, headerFormat } = column;
              return (
                <th key={idx}>
                  <div
                    className={`${getHeaderAlign(
                      column
                    )} table__header-content`}
                  >
                    <div className="table__column-name-container">
                      <span className="table__column-name">
                        {headerFormat
                          ? headerFormat({ columnName, columnLabel })
                          : columnLabel}
                      </span>
                    </div>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.results?.length ? (
            data.results.map((row, index) => (
              <tr key={index}>
                {columns.map((column, columnKey) => {
                  const { columnName } = column;
                  return (
                    <td className={getTextAlign(column)} key={columnKey}>
                      {column.dataFormat
                        ? column.dataFormat(row[columnName], row)
                        : row[columnName]}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td className="table__no-data-found" colSpan={columns.length}>
                {noDataFoundText}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data?.resultCount > pageSize ? (
        <div className="table__pagination-container">
          <Pagination
            activePage={currentPage}
            rowsPerPage={pageSize}
            totalPages={Math.ceil(data.resultCount / pageSize)}
            setActivePage={setActivePage}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableV2;
