// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../../assets/_typography.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".drawerContainer{background-color:#2c1229;width:0}.drawerContainer .searchDrawerIcon{color:#fff}.drawerContainer .drawer{padding:40px;background-color:#2c1229;height:100%}.drawerContainer .drawer .iconContainer{display:flex;flex-direction:row;align-items:center;justify-content:space-between;font-family:Asap-Regular;color:#8e818d;margin-top:30px;font-size:12px}.drawerContainer .drawer .searchTips{margin-left:4px;margin-right:calc(4px * 2);padding:0}.drawerContainer .drawer .iconRoundedBackground{margin-right:calc(4px * 2);padding:5px;width:16px;height:16px;background-color:#3a1b37;border-radius:5px}.drawerContainer .drawer .iconRoundedBackground *{fill:#8e818d;width:16px;height:16px}", "",{"version":3,"sources":["webpack://./src/components/Elements/Search/SearchDrawer.scss"],"names":[],"mappings":"AAA0C,iBAAiB,wBAAwB,CAAC,OAAO,CAAC,mCAAmC,UAAU,CAAC,yBAAyB,YAAY,CAAC,wBAAwB,CAAC,WAAW,CAAC,wCAAwC,YAAY,CAAC,kBAAkB,CAAC,kBAAkB,CAAC,6BAA6B,CAAC,wBAAwB,CAAC,aAAa,CAAC,eAAe,CAAC,cAAc,CAAC,qCAAqC,eAAe,CAAC,0BAA0B,CAAC,SAAS,CAAC,gDAAgD,0BAA0B,CAAC,WAAW,CAAC,UAAU,CAAC,WAAW,CAAC,wBAAwB,CAAC,iBAAiB,CAAC,kDAAkD,YAAY,CAAC,UAAU,CAAC,WAAW","sourcesContent":["@import\"../../../assets/_typography.scss\";.drawerContainer{background-color:#2c1229;width:0}.drawerContainer .searchDrawerIcon{color:#fff}.drawerContainer .drawer{padding:40px;background-color:#2c1229;height:100%}.drawerContainer .drawer .iconContainer{display:flex;flex-direction:row;align-items:center;justify-content:space-between;font-family:Asap-Regular;color:#8e818d;margin-top:30px;font-size:12px}.drawerContainer .drawer .searchTips{margin-left:4px;margin-right:calc(4px * 2);padding:0}.drawerContainer .drawer .iconRoundedBackground{margin-right:calc(4px * 2);padding:5px;width:16px;height:16px;background-color:#3a1b37;border-radius:5px}.drawerContainer .drawer .iconRoundedBackground *{fill:#8e818d;width:16px;height:16px}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
