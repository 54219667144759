import { Action } from 'redux';
import { LoginType } from './login.reducer';
import { AppState } from '../../../rootReducer';
import { ThunkAction } from 'redux-thunk';
import { CredentialsService } from '../../../service/CredentialsService';
import { Credentials } from '../../../service/service.types';
import {
  removeCredentials,
  setCredentials,
} from '../../../shared/credentials.action';
import { loadComplete } from '../../../shared/loading.action';
import { setUserCognito } from '../../../shared/cognito-user.action';

export type LoginActionType =
  | 'FORGOT_PASSWORD'
  | 'FORGOT_PASSWORD_SUCCESS'
  | 'FORGOT_PASSWORD_FAILURE'
  | 'LOGIN_SUCCESS'
  | 'LOGIN_FAILURE'
  | 'RESET_PASSWORD'
  | 'RESET_PASSWORD_SUCCESS'
  | 'RESET_PASSWORD_FAILURE'
  | 'GO_TO_LOGIN_SEQUENCE'
  | 'LOGOUT';

export const loginSuccess = (): Action<LoginActionType> => ({
  type: 'LOGIN_SUCCESS',
});

export const logoutSuccess = (): Action<LoginActionType> => ({
  type: 'LOGOUT',
});

export const loginFailure = (): Action<LoginActionType> => ({
  type: 'LOGIN_FAILURE',
});

export const forgotPasswordSuccess = (): Action<LoginActionType> => ({
  type: 'FORGOT_PASSWORD_SUCCESS',
});

export const forgotPasswordFailure = (): Action<LoginActionType> => ({
  type: 'FORGOT_PASSWORD_FAILURE',
});

export const resetPasswordSuccess = (): Action<LoginActionType> => ({
  type: 'RESET_PASSWORD_SUCCESS',
});

export const resetPasswordFailure = (): Action<LoginActionType> => ({
  type: 'RESET_PASSWORD_FAILURE',
});

export interface GoToLoginSequenceAction extends Action<LoginActionType> {
  sequence: LoginType;
}

export interface LoginError extends Action<LoginActionType> {
  errorMessage?: string;
  accountLocked: boolean;
  password: string;
  username: string;
}

export const goToLoginSequence = (
  sequence: LoginType
): GoToLoginSequenceAction => ({
  type: 'GO_TO_LOGIN_SEQUENCE',
  sequence,
});

export const login =
  (creds: Credentials): ThunkAction<void, AppState, any, any> =>
  async (dispatch) => {
    const loginData = await CredentialsService.login({
      email: creds.email,
      password: creds.password,
    });
    if (loginData) {
      const token = await CredentialsService.saveUserSessionInfo();
      if (loginData.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(setUserCognito(loginData));
        dispatch(goToLoginSequence('RESET_PASSWORD'));
        return;
      }
      if (token) {
        dispatch(loadComplete('LOGIN_STARTED'));
        dispatch(loginSuccess());
        dispatch(setCredentials(token));
        dispatch(setUserCognito(loginData));
        dispatch(
          loadComplete(
            loginData.challengeName === 'NEW_PASSWORD_REQUIRED'
              ? 'SET_PASSWORD'
              : 'LOGIN_COMPLETED'
          )
        );
        return;
      }
    }
    dispatch(loadComplete('LOGIN_FAILED'));
    dispatch(loginFailure());
  };

export const logout = () => async (dispatch) => {
  dispatch(removeCredentials());
  dispatch(loadComplete('LOGOUT'));
  await CredentialsService.logout();
};

export const setPasswordResult = (result) => async (dispatch) => {
  if (!result) {
    dispatch(loadComplete('SET_PASSWORD_FAILED'));
    return;
  }
  if (result) {
    dispatch(goToLoginSequence('USERNAME_AND_PASSWORD'));
  }
};
