import React from 'react';
import { Grid } from '@material-ui/core';
import './UserPhysicalDetails.scss';
import {PhysicalDetails} from "../../gingrProfileDetails";

interface GingrPhysicalDetailsProps {
  data?: PhysicalDetails | any;
  bodyTypes: string[]
}

export const UserPhysicalDetails = ({ data }: GingrPhysicalDetailsProps) => {
  const physicalDetails = data.physicalDetails;
  const bodyTypes = data.bodyTypes;
  return (
    <div className="userPersonalDetailsContainer">
      <div className="description">
        <p>{data.description}</p>
        <span>Description</span>
      </div>
      <div className="personalDetailsContent">
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <p>{physicalDetails.ethnicity}</p>
            <span>Ethnicity</span>
          </Grid>
          <Grid item xs={4}>
            <p>{physicalDetails.hairColor}</p>
            <span>Hair Colour</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {physicalDetails.height}
              {physicalDetails.height && 'CM'}
            </p>
            <span>Height</span>
          </Grid>

          <Grid item xs={4}>
            <p>
              {physicalDetails.weight}
              {physicalDetails.weight && 'KG'}
            </p>
            <span>Weight</span>
          </Grid>
          <Grid item xs={4}>
            <p>{physicalDetails.eyeColor}</p>
            <span>Eye colour</span>
          </Grid>
          <Grid item xs={4}>
            <p>{physicalDetails.genitaliaArea}</p>
            <span>Genitalia Area</span>
          </Grid>

          <Grid item xs={4}>
            <p>
              {physicalDetails.brest}
              {physicalDetails.brest && '-'}
              {physicalDetails.waist}
              {physicalDetails.waist && '-'}
              {physicalDetails.stomach}
            </p>
            <span>Chest-Waist-Hips</span>
          </Grid>
          <Grid item xs={4}>
            <p>{physicalDetails.cupSize}</p>
            <span>Cup size</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {physicalDetails.breastImplant === 0
                ? 'NO'
                : physicalDetails.breastImplant === 1
                ? 'Yes'
                : ''}
            </p>
            <span>Breast Implants</span>
          </Grid>

          <Grid item xs={4}>
            <p>
              {bodyTypes?.map((value, index) => {
                if (index < bodyTypes.length - 1) {
                  return value + ', ';
                } else {
                  return value;
                }
              })}
            </p>
            <span>Body Type</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {physicalDetails.piercingFace === 1 && 'Face piercing'}{' '}
              {physicalDetails.piercingFace === 1 &&
                (physicalDetails.piercingIntimate === 1 ||
                  physicalDetails.tattoo === 1) &&
                ', '}{' '}
              {physicalDetails.piercingIntimate === 1 && 'Intimate piercing'}{' '}
              {physicalDetails.piercingIntimate &&
                physicalDetails.tattoo === 1 &&
                ', '}{' '}
              {physicalDetails.tattoo === 1 && 'Tattoo'}
            </p>
            <span>Body Art</span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
