import { Action } from 'redux';

export type CognitoUserActionType = 'SET_COGNITO_USER';

export interface SetCognitoUserAction extends Action<CognitoUserActionType> {
  user: any;
}

export const setUserCognito = (user: any): SetCognitoUserAction => ({
  type: 'SET_COGNITO_USER',
  user,
});
