import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import { useFormik } from 'formik';
import { ContentService } from '../../../../service/ContentService';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import './AddCityContent.scss';
import { Editor } from 'primereact/editor';

interface Props {
  cityId: number;
  successCallback: any;
}
export const AddCityContent: React.FC<Props> = ({
  cityId,
  successCallback,
}) => {
  const toast = useRef<Toast>(null);

  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);


  const show = () => {
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'The city is added',
    });
  };

  const expand = () => setExpanded(!expanded);

  const formik = useFormik({
    initialValues: {
      content: '',
      contentType: '',
    },
    validate: (data) => {
      let errors = {};

      if (!data.content) {
        errors.name = 'City name is required.';
      }

      if (!data.contentType) {
        errors.slug = 'Slug is required.';
      }

      return errors;
    },
    onSubmit: async (data) => {
      const result = await saveCityContent();
      if (result) {
        successCallback();
      }
      formik.resetForm();
    },
  });

  const saveCityContent = async () => {
    setLoading(true);
    const result = await ContentService.addCityContent(token, {
      cityId: cityId,
      content: formik.values.content,
      contentType: formik.values.contentType,
    });
    setLoading(false);
    if (result?.id) {
      setExpanded(false);
    }
    return !!result?.id;
  };

  return expanded ? (
    <div>
      <Toast ref={toast} />
      <div>
        <Button type="button" label="Hide" onClick={expand} />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form">

          <label htmlFor="contentType">Content type</label>
          <InputText
            id="contentType"
            name="contentType"
            className="input"
            value={formik.values.contentType}
            onChange={(e) =>
              formik.setFieldValue('contentType', e.target.value)
            }
          />

          <label htmlFor="content">Content</label>
          <Editor
            className="editor"
            value={formik.values.content}
            onTextChange={(e) => formik.setFieldValue('content', e.htmlValue)}
            style={{ height: '320px' }}
          />
          <Button type="submit" label="Save" className="button" />
        </div>
      </form>
    </div>
  ) : (
    <div>
      <div>
        <Button type="button" label="Add content" onClick={expand} />
      </div>
    </div>
  );
};
