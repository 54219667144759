import { RevenueTableProps } from './RevenueUserTable/RevenueTable';
import { UserType } from '../Subscriptions/SubscriptionTable';

const REVENUE_DATA: RevenueTableProps = {
  title: 'Most spending users in total',
  data: [
    {
      name: 'Camryn Pennington',
      userType: UserType.AGENCY,
      img: 'https://i.pravatar.cc/150?img=4',
      amount: '34',
      isVisible: false,
    },
    {
      name: 'Nghiêm Thế Quyền',
      userType: UserType.CLIENT,
      img: 'https://i.pravatar.cc/150?img=5',
      amount: '34',
      isVisible: false,
    },
    {
      name: 'Anne-Marije Markink',
      userType: UserType.AGENCY,
      img: 'https://i.pravatar.cc/150?img=6',
      amount: '34',
      isVisible: false,
    },
    {
      name: 'Abayo Stevens',
      userType: UserType.CLIENT,
      img: 'https://i.pravatar.cc/150?img=7',
      amount: '34',
      isVisible: false,
    },
    {
      name: 'Jushawn McDowell',
      userType: UserType.AGENCY,
      img: 'https://i.pravatar.cc/150?img=8',
      amount: '34',
      isVisible: false,
    },
    {
      name: 'Nghiêm Thế Quyền',
      userType: UserType.AGENCY,
      img: 'https://i.pravatar.cc/150?img=9',
      amount: '34',
      isVisible: false,
    },
  ],
};
export { REVENUE_DATA };
