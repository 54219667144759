import { BaseService } from './BaseService';
import { CityModel } from '../components/Pages/Content/Model/CityModel';
import {
  CityDetailsModel,
} from '../components/Pages/Content/Model/CityDetailsModel';
import { GingrClient3 } from '../clients/GingrClient3';
import { CountryModel } from '../components/Pages/Content/Model/CountryModel';
import { CountryContentModel } from '../components/Pages/Content/Model/CountryContentModel';

export class ContentService {

  static async getCountries(token): Promise<CountryModel[]> {
    try {
      return await GingrClient3.get(
        'admin/content/countries',
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return [];
    }
  }

  static async getCountry(token, countryId: number): Promise<CountryModel> {
    try {
      return await GingrClient3.get(
        'admin/content/country/' + countryId,
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return {} as CountryModel;
    }
  }

  static async getCountryContent(token, countryId: number) {
    try {
      return await GingrClient3.get(
        'admin/content/country/record/' + countryId,
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return null;
    }
  }

  static async getCities(token, countryId: number): Promise<CityModel[]> {
    try {
      return await GingrClient3.get(
        'admin/content/cities/' + countryId,
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return [];
    }
  }

  static async getCity(token, cityId: number): Promise<CityDetailsModel> {
    try {
      return await GingrClient3.get(
        'admin/content/city/' + cityId,
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return {} as CityDetailsModel;
    }
  }

  static async getCityContents(token, cityId: number): Promise<CityModel[]> {
    try {
      return await GingrClient3.get(
        'admin/content/cities/record/' + cityId,
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return [];
    }
  }

  static async getCityContent(
    token,
    cityId: number,
    contentType: string
  ): Promise<CityModel> {
    try {
      return await GingrClient3.get(
        'admin/content/city/record/' + cityId + '/' + contentType,
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return {} as CityModel;
    }
  }

  static async editCityContent(
    token,
    recordId: number,
    body
  ): Promise<CityModel> {
    try {
      return await GingrClient3.put(
        'admin/content/city/record/' + recordId,
        body,
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return {} as CityModel;
    }
  }

  static async addCityContent(token, body): Promise<CityModel> {
    try {
      return await GingrClient3.post(
        'admin/content/city/record/',
        body,
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return {} as CityModel;
    }
  }

  static async addCity(token, body): Promise<CityDetailsModel> {
    try {
      return await GingrClient3.post(
        'admin/content/city/',
        body,
        BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return {} as CityDetailsModel;
    }
  }

  static async addCountryContent(token, body): Promise<CountryContentModel> {
    try {
      return await GingrClient3.post(
          'admin/content/country/record',
          body,
          BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return {} as CountryContentModel;
    }
  }

  static async editCountryContent(token, contentId, body): Promise<CountryContentModel> {
    try {
      return await GingrClient3.put(
          `admin/content/country/record/${contentId}`,
          body,
          BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return {} as CountryContentModel;
    }
  }

  static async addCountry(token, body): Promise<CountryModel> {
    try {
      return await GingrClient3.post(
          'admin/content/country',
          body,
          BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return {} as CountryModel;
    }
  }

  static async editCountry(token, countryId, body): Promise<CountryModel> {
    try {
      return await GingrClient3.put(
          `admin/content/country/${countryId}`,
          body,
          BaseService.getBaseHeaders(token)
      );
    } catch (e) {
      return {} as CountryModel;
    }
  }
}
