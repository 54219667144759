import ReactDOM from 'react-dom';
import { App } from './App';
import React from 'react';
import { Provider } from 'react-redux';
import { getStore } from './rootReducer';
export const store = getStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
