import React from 'react';
import { MainButton } from '../../Login/MainButton';
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Editor} from "primereact/editor";
import {useFormik} from "formik";
import {ContentService} from "../../../../service/ContentService";
import {useSelector} from "react-redux";
import {AppState} from "../../../../rootReducer";
import {CountryModel} from "../Model/CountryModel";
import * as path from "path";

interface Props {
    successCallback: any;
    countryId?: number
}

export const AddEditCountry: React.FC<Props> = ({
    successCallback,
    countryId
}) => {
    const token = useSelector(
        (state: AppState) => state.data.user.credentials.token
    );
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [country, setCountry] = React.useState<CountryModel>({} as CountryModel);
    const [edit, setIsEdit] = React.useState<boolean>(false);

    const expand = () => setExpanded(!expanded);

    React.useEffect(() => {
        if (!countryId) {
            return
        }
        const fetchData = async () => {
            const country = await ContentService.getCountry(token, countryId);
            if (country) {
                setIsEdit(true)
                await formik.setFieldValue('countryName', country.country)
                await formik.setFieldValue('countryCode', country.countryCode)
            }
            setCountry(country);
        };

        fetchData().catch(console.error);
    }, [countryId]);

    const formik = useFormik({
        initialValues: {
            countryName: '',
            countryCode: '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.countryName) {
                errors.countryName = 'Country name is required.';
            }

            if (!data.countryCode) {
                errors.countryCode = 'Country code is required.';
            }

            return errors;
        },
        onSubmit: async (data) => {
            const result = await saveCountry();
            if (result) {
                successCallback();
            }
            if (!edit) {
                formik.resetForm()
            }
        },
    });

    const saveCountry = async () => {
        setLoading(true);
        const payload = {
            country: formik.values.countryName,
            countryCode: formik.values.countryCode,
        }
        const result = edit ? await ContentService.editCountry(token, countryId, payload) :
            await ContentService.addCountry(token, payload);
        setLoading(false);
        if (result?.id) {
            setExpanded(false);
            await formik.setFieldValue('countryName', result.country)
            await formik.setFieldValue('countryCode', result.countryCode)
        }
        return !!result?.id;
    };

  return expanded ? (
      <div>
          <div>
              <Button type="button" label="Hide" onClick={expand} />
          </div>
          <form onSubmit={formik.handleSubmit}>
              <div className="form">

                  <label htmlFor="countryName">Country name</label>
                  <InputText
                      id="countryName"
                      name="countryName"
                      className="input"
                      value={formik.values.countryName}
                      onChange={(e) =>
                          formik.setFieldValue('countryName', e.target.value)
                      }
                  />

                  <label htmlFor="countryCode">Country code</label>
                  <InputText
                      id="countryCode"
                      name="countryCode"
                      className="input"
                      value={formik.values.countryCode}
                      onChange={(e) =>
                          formik.setFieldValue('countryCode', e.target.value)
                      }
                  />

                  <Button type="submit" label="Save" className="button" />
              </div>
          </form>
      </div>
  ) : (
    <div>
        <div>
            <Button type="button" label={expanded ? 'Hide' : edit ? 'Edit country' : 'Add country'} onClick={expand} />
        </div>
    </div>
  );
};
