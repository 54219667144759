import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContentService } from '../../../../service/ContentService';
import { CityModel } from '../Model/CityModel';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../rootReducer';
import './CityContentDetails.scss';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { Editor } from 'primereact/editor';
import { MainButton } from '../../Login/MainButton';
import { CityDetailsModel } from '../Model/CityDetailsModel';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "../Country/Editor";
import {Paragraph} from "@ckeditor/ckeditor5-paragraph";
import {Bold, Italic, Underline} from "@ckeditor/ckeditor5-basic-styles";
import {Essentials} from "@ckeditor/ckeditor5-essentials";
import {SourceEditing} from "@ckeditor/ckeditor5-source-editing";
import {Heading} from "@ckeditor/ckeditor5-heading";
import {FontBackgroundColor, FontColor, FontSize} from "@ckeditor/ckeditor5-font";
import {Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload} from "@ckeditor/ckeditor5-image";
import {Link, LinkImage} from "@ckeditor/ckeditor5-link";
import {List} from "@ckeditor/ckeditor5-list";

export const CityContentDetails: React.FC = () => {
  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const params: any = useParams();
  const [editor, setEditor] = React.useState<any>(null);
  const [city, setCity] = React.useState<CityDetailsModel>(
    {} as CityDetailsModel
  );
  const [contentDetails, setContentDetails] = React.useState<CityModel>(
    {} as CityModel
  );
  const [content, setContent] = React.useState<string>('');
  const [success, setSuccess] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const history = useHistory();

  const saveContent = async () => {
    if (loading) {
      return;
    }
    const body = {
      content: editor.getData(),
      contentType: contentDetails.contentType,
      cityId: contentDetails.cityId,
    };
    setLoading(true);
    const result = await ContentService.editCityContent(
      token,
      contentDetails.id,
      body
    );
    setSuccess(result.id ? 1 : -1);
    setLoading(false);
  };

  const navigateToCity = () => {
    history.push(`/content/cities/${city.id}`);
  };

  React.useEffect(() => {
    if (!params.id) {
      return;
    }
    const fetchData = async () => {
      const cityDetails = await ContentService.getCity(token, params.id);
      setCity(cityDetails);
    };

    fetchData().catch(console.error);
  }, [params]);

  React.useEffect(() => {
    if (!params.id) {
      return;
    }
    const fetchData = async () => {
      const contentDetails = await ContentService.getCityContent(
        token,
        params.id,
        params.contentType
      );
      setContentDetails(contentDetails);
    };

    fetchData().catch(console.error);
  }, [params]);

  return (
    <div className="content">
      <h1 className="city title" onClick={() => navigateToCity()}>
        {city.name}
      </h1>
      <h2 className="title">{contentDetails.contentType}</h2>

      <div style={{ color: "black" }}>
        <CKEditor
            editor={ ClassicEditor }
            config={ {
              plugins: [
                Paragraph, Bold, Italic, Essentials, SourceEditing, Heading, FontBackgroundColor,
                FontColor , Image, Link, List, FontSize, FontColor, ImageToolbar, ImageCaption, ImageStyle,
                ImageResize, LinkImage, ImageUpload, Underline
              ],
              toolbar: [
                'heading', 'bold', 'italic', 'underline', 'undo', 'redo',
                'style', 'alignment', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'bulletedList',
                'numberedList', 'link', 'insertImage', 'sourceEditing',
              ],
            } }
            data={contentDetails.content}
            onReady={ editor => {
              setEditor(editor)
              console.log( 'Editor is ready to use!', editor );
            } }
        />
      </div>
      <div className="button-container">
        <MainButton text="Save" onClick={saveContent} />
      </div>
    </div>
  );
};
