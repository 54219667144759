import Cookies from 'universal-cookie';
import { SuccessfulAuthResponse } from './client.types';

export enum CookieParams {
  GINGER_ACCESS_TOKEN = 'GINGER_ACCESS_TOKEN',
}

const getCookieClient: () => Cookies = (() => {
  const cookies = new Cookies();
  return (): Cookies => {
    return cookies;
  };
})();

const getTokens = (): SuccessfulAuthResponse => {
  const cookies = getCookieClient();
  const tokenCookie = cookies.get(CookieParams.GINGER_ACCESS_TOKEN);
  cookies.getAll();
  return !tokenCookie
    ? {
        token: '',
      }
    : tokenCookie;
};

const setTokens = (token: string): void => {
  const cookies = getCookieClient();
  cookies.set(
    CookieParams.GINGER_ACCESS_TOKEN,
    JSON.stringify({
      token,
    })
  );
};
const removeTokens = (): void => {
  const cookies = getCookieClient();
  cookies.remove(CookieParams.GINGER_ACCESS_TOKEN);
};

const getAllCookies = () => {
  const cookies = getCookieClient();
  return cookies.getAll();
};

export const CookieMonster = {
  getTokens,
  setTokens,
  getAllCookies,
  removeTokens,
};
