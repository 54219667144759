import React from 'react';
interface OneColumnTableProps {
  head?: string;
  data: Array<string>;
}

export const OneColumnTable: React.FC<OneColumnTableProps> = ({
  data,
  head,
}) => {
  return (
    <div className="secondTable">
      <table>
        {head && (
          <tr>
            <th>{head}</th>
          </tr>
        )}
        {data.map((value) => (
          <tbody>
            <tr>
              <td>{value}</td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
};
