import React, { useEffect } from 'react';
import './globalStyles.scss';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { MainLayout } from './components/Pages/Layouts/MainLayout/MainLayout';
import { LoginLayout } from './components/Pages/Layouts/LoginLayout/LoginLayout';
import ProtectedRoute from './components/Pages/ProtectedRoute/ProtectedRoute';
import { ConfirmProvider } from 'material-ui-confirm';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { ResetPassword } from './components/Pages/Login/ResetPassword/ResetPassword';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import translation_en from './translations/en/translation.json';
import { Amplify } from '@aws-amplify/core';
import { CredentialsService } from './service/CredentialsService';
import { useDispatch } from 'react-redux';
import { setCredentials } from './shared/credentials.action';
import { logout } from './components/Pages/Login/login.action';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      translation: translation_en,
    },
  },
});

export const App: React.FC = () => {
  const config = window.app_config || {};
  const [loading, setLoading] = React.useState<boolean>(true);
  Sentry.init({
    dsn: 'https://5fa073206d3e435fa09fba78e09923c1@o1263014.ingest.sentry.io/4504010723229696',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.01,
    environment: config.sentryEnvironmentId,
    release: `${config.sentryProjectName}@${config.releaseId}`,
    ignoreErrors: ['Non-Error exception captured'],
  });

  Amplify.configure({
    aws_project_region: config.aws_project_region,
    aws_cognito_region: config.aws_cognito_region,
    aws_user_pools_id: config.aws_user_pools_id,
    aws_user_pools_web_client_id: config.aws_user_pools_web_client_id,
    oauth: {},
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const data = await CredentialsService.saveUserSessionInfo();
      if (!data) {
        dispatch(logout());
      }
      dispatch(setCredentials(data));
      setLoading(false);
    };

    fetchData().catch((e) => console.error(e));
  }, []);

  return loading ? (
    ''
  ) : (
    <I18nextProvider i18n={i18next}>
      <ConfirmProvider>
        <BrowserRouter>
          <Switch>
            <ProtectedRoute exact path="/dashboard" component={MainLayout} />
            <ProtectedRoute path="/users/clients" component={MainLayout} />
            <ProtectedRoute path="/users/gingrs" component={MainLayout} />

            <ProtectedRoute path="/transactions" component={MainLayout} />
            <ProtectedRoute
              path="/transactions/create"
              component={MainLayout}
            />
            <Route exact path={'/login'} component={LoginLayout} />
            <Route exact path={'/reset-password'} component={ResetPassword} />
            <Route path="*">
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </BrowserRouter>
      </ConfirmProvider>
    </I18nextProvider>
  );
};
