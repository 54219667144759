import React from 'react';
import './DrawerUserList.scss';

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { UserType } from '../../../Pages/Subscriptions/SubscriptionTable';

type UserItem = {
  name: string;
  img: string;
};
export interface DrawerUserListProps {
  title: UserType;
  parameter?: string;
  data: Array<UserItem>;
  viewAll?: () => void;
}

export const DrawerUserList: React.FC<DrawerUserListProps> = ({
  title,
  data,
  parameter,
}) => {
  const [dense] = React.useState<boolean>(false);
  const [secondary] = React.useState(false);

  const userListComponent = (data: Array<UserItem>) => {
    return data.map((user, idx) => {
      return (
        <ListItem key={idx}>
          <ListItemAvatar key={idx}>
            <Avatar src={user.img} />
          </ListItemAvatar>
          <ListItemText
            key={idx}
            primary={
              <Typography
                variant="h6"
                style={{ color: '#8e818d', fontSize: 12 }}
              >
                {user.name}
              </Typography>
            }
            secondary={secondary ? 'Secondary text' : null}
          />
        </ListItem>
      );
    });
  };

  const renderUserList = (parameter: string | undefined) => {
    if (parameter) {
      const dataFilteredByParameter: Array<UserItem> = data.filter(
        (user) =>
          user.name
            .toString()
            .toLowerCase()
            .indexOf(parameter.toString().toLowerCase()) > -1
      );
      return userListComponent(dataFilteredByParameter);
    }
    return userListComponent(data);
  };

  return (
    <>
      <div className="listHeader">
        {(title === UserType.GINGRS && (
          <p>
            Popular {title} ({parameter && renderUserList(parameter).length})
          </p>
        )) ||
          (title === UserType.AGENCY && (
            <p>
              Popular {title} ({parameter && renderUserList(parameter).length})
            </p>
          )) || (
            <p className="titleParagraph">
              Latest bookings ({parameter && renderUserList(parameter).length})
            </p>
          )}
        <p>View all</p>
      </div>
      <List dense={dense}>{renderUserList(parameter)}</List>
    </>
  );
};
