import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import { ErrorMessage, TextInput } from '../TextInput/TextInput';
import '../Login.scss';
import { MainButton } from '../MainButton';
import logo from '../../../../assets/img/logo/logo1.svg';
import { useDispatch, useSelector } from 'react-redux';
import { goToLoginSequence, login } from '../login.action';
import { Link } from '../Link/Link';
import { LoginHeader } from '../LoginHeader/LoginHeader';
import { AppState } from '../../../../rootReducer';

export const UsernameAndPasswordLogin: React.FC = () => {
  const dispatch = useDispatch();
  const sectionsLoading = useSelector(
    (state: AppState) => state.presentation.loading.sectionsLoading
  );
  const loginStatus = useSelector(
    (state: AppState) => state.presentation.login.loginType
  );

  const [isChecked, setChecked] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<ErrorMessage>({
    error: false,
    message: '',
  });
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const CheckBox = withStyles({
    root: {
      color: '#c27ebb',
      '&$checked': {
        color: '#c27ebb',
      },
      labelFontSize: 14,
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  React.useEffect(() => {
    const checkIfEmailIsValid = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(
      email
    );
    if (checkIfEmailIsValid) {
      setError({
        error: false,
        message: '',
      });
    }
  }, [password]);

  const validation = (value: string) => {
    const checkIfEmailIsValid = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(
      value
    );

    if (!checkIfEmailIsValid) {
      setError({
        error: true,
        message:
          'Invalid email address, please type already existing email address',
      });
    } else {
      setError({
        error: false,
        message: '',
      });
    }
  };

  React.useEffect(() => {
    setLoading(sectionsLoading.includes('LOGIN_SUBMISSION'));
  }, [sectionsLoading]);

  React.useEffect(() => {
    if (loginStatus && loginStatus === 'UNSUCCESSFUL_LOGIN') {
      setError({
        error: true,
        message: 'The email address or password you entered is invalid',
      });
    } else if (loginStatus && loginStatus === 'SUCCESSFUL_LOGIN') {
      setError({
        error: false,
        message: '',
      });
    }
  }, [loginStatus, loading]);

  const handleLogin = () => {
    setLoading(true);
    dispatch(login({ email, password }));
    setTimeout(() => {
      if (loginStatus) {
        if (loginStatus === 'SET_PASSWORD') {
          gotToSetPasswordScreen();
        }
        const error = loginStatus !== 'SUCCESSFUL_LOGIN';
        const message =
          loginStatus === 'SUCCESSFUL_LOGIN'
            ? ''
            : 'The email address or password you entered is invalid';
        setError({
          error,
          message,
        });
      }
    }, 400);
  };
  const gotToForgotPasswordScreen = () => {
    dispatch(goToLoginSequence('FORGOT_PASSWORD'));
  };
  const gotToSetPasswordScreen = () => {
    dispatch(goToLoginSequence('RESET_PASSWORD'));
  };

  return (
    <div className="container">
      <img src={logo} alt="logo" />
      <div className="login-container">
        <LoginHeader title="Login" />
        <div className="input-container">
          <label className="input-label" htmlFor="email">
            E-mail
          </label>
          <TextInput
            id="email"
            value={email}
            label="Type your email"
            error={error}
            handleOnChange={(value) => {
              setEmail(value);
              validation(value);
            }}
          />
          <label className="input-label" htmlFor="password">
            Password
          </label>
          <TextInput
            id="password"
            value={password}
            hiddenText={true}
            onKeyDown={() => handleLogin()}
            label="Type your password"
            error={error.message}
            handleOnChange={(value) => {
              setPassword(value);
            }}
          />
          <div className="checkbox-container">
            <div className="checkbox-and-link">
              <CheckBox
                checked={isChecked}
                onChange={(event) => handleChange(event)}
                name="checked"
              />
              <Link
                arialLabel={'forgot-password-link'}
                text={'Remember my login'}
                onClick={() => setChecked(!isChecked)}
              />
            </div>
            <Link
              arialLabel={'forgot-password-link'}
              text={'Forgot your password?'}
              onClick={gotToForgotPasswordScreen}
            />
          </div>
        </div>
        <div className="button-container">
          <MainButton
            text="Login"
            loading={loading}
            fullWidth
            onClick={() => handleLogin()}
          />
        </div>
      </div>
    </div>
  );
};
