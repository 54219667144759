import React from 'react';
import { Grid } from '@material-ui/core';
import './UserPersonalDetails.scss';
import { GeneralUserProfileDetailsState } from './userProfileDetails.reducer';
import {GingrProfileDetails} from "../../GingrDetails/gingrProfileDetails";

interface UserPersonalDetailsProps {
  data?: GingrProfileDetails | any;
}

export const UserPersonalDetails = ({ data }: UserPersonalDetailsProps) => {
  const userProfileDetails = data;

  return (
    <div className="userPersonalDetailsContainer">
      <div className="description">
        <p>{userProfileDetails.bookingNotes}</p>
        <span>Description</span>
      </div>
      <div className="personalDetailsContent">
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <p>
              {userProfileDetails.gender === 0
                ? 'Female'
                : userProfileDetails.gender === 1
                ? 'Male'
                : userProfileDetails.gender === 2
                ? 'Trans'
                : ''}
            </p>
            <span>Gender</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {userProfileDetails.sexuality === 0
                ? 'Heterosexual'
                : userProfileDetails.sexuality === 1
                ? 'Homosexual'
                : userProfileDetails.sexuality === 2
                ? 'Bisexual'
                : ''}
            </p>
            <span>Sexuality</span>
          </Grid>
          <Grid item xs={4}>
            <p>{userProfileDetails.birthYear}</p>
            <span>Birth Year</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {userProfileDetails.physicalDetails?.height}
              {userProfileDetails.physicalDetails?.height && 'CM'}
            </p>
            <span>Height</span>
          </Grid>
          <Grid item xs={4}>
            <p>
              {userProfileDetails.physicalDetails?.weight}
              {userProfileDetails.physicalDetails?.weight && 'KG'}
            </p>
            <span>Weight</span>
          </Grid>
          <Grid item xs={4}>
            <p>{userProfileDetails.physicalDetails?.genitaliaArea}</p>
            <span>Genital area</span>
          </Grid>
          <Grid item xs={4}>
            {
              <>
                <p>{userProfileDetails.hear}</p>
                <span>How did you hear about us?</span>
              </>
            }
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
