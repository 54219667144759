import { applyMiddleware, createStore, combineReducers, Store } from 'redux';
import {
  credentialsReducer,
  CredentialsState,
} from './shared/credentials.reducer';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadingReducer, LoadingState } from './shared/loading.reducer';
import { errorReducer } from './shared/error.reducer';
import {
  loginReducer,
  LoginState,
} from './components/Pages/Login/login.reducer';
import {
  dashboardLiveUsersReducer,
  dashboardReducer,
  InformationCountState,
  informationReducer,
  LiveUsersCountState,
  UsersCountState,
} from './components/Pages/Dashboard/dashboard.reducer';
import {
  clientsReducer,
  UsersState,
} from './components/Pages/Users/Clients/clients.reducer';
import {
  gingrReducer,
  GingrState,
} from './components/Pages/Users/Gingrs/gingr.reducer';
import {
  userProfileDetailsReducer,
  UserProfileDetailsState,
} from './components/Pages/Users/ClientsDetails/UserPersonalDetails/userProfileDetails.reducer';
import {
  gingrProfileDetailsReducer,
  GingrProfileDetailsState,
} from './components/Pages/Users/GingrDetails/gingrProfileDetails.reducer';

import {
  PendingApprovalCountState,
  usersPendingApprovalCountReducer,
} from './components/Pages/Sidebar/sidebar.reducer';
import {
  UsersAssetsNotificationState,
  usersAssetsToBeApprovedReducer,
} from './components/Pages/Users/usersAssetsToBeApproved.reducer';
import {
  cognitoUserReducer,
  CognitoUserState,
} from './shared/cognito-user.reducer';

export interface AppState {
  data: {
    user: {
      credentials: CredentialsState;
      assetsToBeApproved: UsersAssetsNotificationState;
      cognitoUser: CognitoUserState;
    };
    dashboard: {
      statistics: {
        usersCount: UsersCountState;
        liveUsersCount: LiveUsersCountState;
        pendingApprovalCount: PendingApprovalCountState;
        information: InformationCountState;
      };
    };
    clients: {
      users: UsersState;
      profileDetails: UserProfileDetailsState;
    };
    gingr: {
      users: GingrState;
      profileDetails: GingrProfileDetailsState;
    };
  };
  presentation: {
    login: LoginState;
    loading: LoadingState;
  };
}

export const rootReducer = combineReducers({
  data: combineReducers({
    user: combineReducers({
      credentials: credentialsReducer,
      assetsToBeApproved: usersAssetsToBeApprovedReducer,
      cognitoUser: cognitoUserReducer,
    }),
    dashboard: combineReducers({
      statistics: combineReducers({
        usersCount: dashboardReducer,
        liveUsersCount: dashboardLiveUsersReducer,
        pendingApprovalCount: usersPendingApprovalCountReducer,
        information: informationReducer,
      }),
    }),
    clients: combineReducers({
      users: clientsReducer,
      profileDetails: userProfileDetailsReducer,
    }),
    gingr: combineReducers({
      users: gingrReducer,
      profileDetails: gingrProfileDetailsReducer,
    }),
  }),
  presentation: combineReducers({
    login: loginReducer,
    loading: loadingReducer,
    error: errorReducer,
  }),
});

export const getStore = (preloadedState?: Partial<AppState>): Store => {
  const middlewareEnhancer = applyMiddleware(thunkMiddleware);
  const composedEnhancers = composeWithDevTools(middlewareEnhancer);

  return createStore(rootReducer, preloadedState, composedEnhancers);
};
