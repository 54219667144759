import { BOOKING_AND_REFUNDS } from '../../Pages/BookingAndRefunds/DummyData';

export enum NOTIFICATION_TYPE {
  PAID_REGISTRATION = 'PAID',
  REPORTED = 'REPORTED',
}

export interface REPORT {
  name: string;
  type: NOTIFICATION_TYPE.REPORTED;
  time: string;
  img: string;
}

export interface PAID {
  name: string;
  type: NOTIFICATION_TYPE.PAID_REGISTRATION;
  amount: string;
  img: string;
  time: string;
}

export const NotificationsDummyData: Array<PAID | REPORT> = [
  {
    name: 'Melissa Morillo',
    type: NOTIFICATION_TYPE.PAID_REGISTRATION,
    amount: '40',
    img: 'https://i.pravatar.cc/150?img=3',
    time: '3h',
  },
  {
    name: 'Anne-Marije Markink',
    type: NOTIFICATION_TYPE.REPORTED,
    time: '2h',
    img: 'https://i.pravatar.cc/150?img=4',
  },
  {
    name: 'Melissa Morillo',
    type: NOTIFICATION_TYPE.PAID_REGISTRATION,
    amount: '40',
    time: '10 m',
    img: 'https://i.pravatar.cc/150?img=5',
  },
  {
    name: 'Anne-Marije Markink',
    type: NOTIFICATION_TYPE.REPORTED,
    time: '40 m',
    img: 'https://i.pravatar.cc/150?img=6',
  },
  {
    name: 'Anne-Marije Markink',
    type: NOTIFICATION_TYPE.REPORTED,
    time: '2h',
    img: 'https://i.pravatar.cc/150?img=7',
  },
  {
    name: 'Melissa Morillo',
    type: NOTIFICATION_TYPE.PAID_REGISTRATION,
    amount: '40',
    time: '1 m',
    img: 'https://i.pravatar.cc/150?img=9',
  },
];
